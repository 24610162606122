import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import './PaymentMethodForm.css';

const stripeElementStyles = { base: { color: '#385b84', '::placeholder': { color: '#CCCCCC' } } };

const PaymentMethodForm = ({ onCardholderNameChange, onCardNumberChange, onCardExpiryChange, onCardCvcChange }) => (
  <div className="PaymentMethodForm">
    <label>
      Cardholder name
      <input data-cy="cardHolderInput" type="text" onChange={onCardholderNameChange} />
    </label>
    <label>
      Card number
      <CardNumberElement
        id="cardNumberElement"
        options={{
          style: stripeElementStyles,
        }}
        onChange={onCardNumberChange}
      />
    </label>
    <label>
      Expiration date
      <CardExpiryElement
        id="cardExpiryElement"
        options={{
          style: stripeElementStyles,
        }}
        onChange={onCardExpiryChange}
      />
    </label>
    <label>
      CVC
      <CardCvcElement
        id="cardCvcElement"
        options={{
          style: stripeElementStyles,
        }}
        onChange={onCardCvcChange}
      />
    </label>
    <img className={'StripeLogo'} src="/images/powered_by_stripe.svg" alt="Powered by Stripe" />
  </div>
);

export default PaymentMethodForm;
