import * as R from 'ramda';
import * as U from 'karet.util';
import * as React from 'karet';
import { object } from 'underscore';
import { Ingredients, Ingredient } from './IngredientMeta';
import SubstanceSelector from '../../MainView/CaseDetails/CaseDataForm/SubstanceSelector';
import { flattenSubstances } from '../../../utils/toxin';
import './IngredientEditor.css';
import { ConfirmButton } from '../../Components/ConfirmButton';

const valueToPercentage = (conversionFactor, value) => (value * 100) / conversionFactor;
const percentageToValue = (conversionFactor, percentage) => (percentage * conversionFactor) / 100;
const toFixed = (decimalPlaces) => (value) => parseFloat(value.toFixed(decimalPlaces));

const IngredientControl = ({ index, ingredient, conversionFactor = 1 }) => {
  const ingredientAmount = Ingredient.ingredientAmount(ingredient);
  const percentageAmount = U.atom();

  percentageAmount
    .map(parseFloat)
    .filter(R.complement(isNaN))
    .observe((v) => ingredientAmount.set(toFixed(10)(percentageToValue(conversionFactor, v))));

  ingredientAmount
    .map(parseFloat)
    .filter(R.complement(isNaN))
    .observe((v) => percentageAmount.set(toFixed(10)(valueToPercentage(conversionFactor, v))));

  return [
    <ConfirmButton
      key={`${index}remove`}
      className={'RemoveButton RoundButton'}
      onConfirm={() => ingredient.remove()}
    />,
    <span key={`${index}name`}>{Ingredient.name(ingredient)}</span>,
    <input
      key={`${index}amount`}
      value={ingredientAmount}
      onChange={(evt) => ingredientAmount.set(evt.target.value)}
    />,
    <span key={`${index}unit`}>{Ingredient.unit(ingredient)}</span>,
    <input
      key={`${index}amountPercentage`}
      value={percentageAmount}
      onChange={(evt) => {
        percentageAmount.set(evt.target.value);
      }}
    />,
    <span key={`${index}unitPercentage`}>%</span>,
  ];
};

const toSubstanceIngredient = (substance) => ({
  id: substance.id,
  name: substance.name,
  unit: substance.unit,
  ingredientAmount: 0,
});

export const IngredientEditor = ({ ingredients, substanceUnit, substances }) => {
  const conversionFactor = (parentUnit, childUnit) => {
    return parentUnit === 'g' && childUnit === 'mg' ? 1000 : 1;
  };
  const substanceMap = object(
    flattenSubstances(substances).map((substance) => [substance.id, toSubstanceIngredient(substance)]),
  );
  const ingredientsView = Ingredients.ingredients(ingredients);
  const selectedItem = U.atom(substances[0]);
  return (
    <div className="IngredientEditor">
      {U.mapElems(
        (item, index) => (
          <IngredientControl
            key={index}
            index={index}
            ingredient={item}
            conversionFactor={conversionFactor(substanceUnit.get(), Ingredient.unit(item).get())}
          />
        ),
        ingredientsView,
      )}
      <button
        key="Add"
        className="AddButton RoundButton"
        onClick={() => ingredientsView.modify(R.append(substanceMap[U.view('value', selectedItem).get()]))}
      >
        +
      </button>
      <SubstanceSelector
        className="SubstanceSelector"
        value={selectedItem}
        showHidden={true}
        feedback={false}
        onChange={(option) => {
          selectedItem.set(option);
        }}
      />
    </div>
  );
};
