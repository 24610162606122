import * as React from 'karet';
import * as U from 'karet.util';
import { toxinService } from '../../services/toxin';
import { ToxicityLevelEditor } from './toxicitylevels/ToxicityLevels';
import SubstanceSelector from '../MainView/CaseDetails/CaseDataForm/SubstanceSelector';
import { IngredientEditor } from './ingredients/IngredientEditor';
import ClinicalSignGroupingEditor from './signgroupingeditor/ClinicalSignGroupingEditor';
import { SuccessInfo } from '../../utils/ui';
import { Substance } from './SubstanceMeta';
import { Modal } from '../Components/Modal';
import './SubstanceEditor.css';
import { EditorYesNoToggle } from './EditorComponents/EditorYesNoToggle';
import { EditorSelect } from './EditorComponents/EditorSelect';
import { EditorTextArea } from './EditorComponents/EditorTextArea';
import { EditorEditableList } from './EditorComponents/EditorEditableList';
import { equals, pick, isNil, clone } from 'ramda';

const mapSubstanceLevels = (levels) => {
  return (levels || []).map((level) => {
    return Object.assign({}, level, {
      conditions: level.conditions
        .filter((condition) => condition)
        .map((condition) => {
          if (typeof condition === 'object') {
            return condition.name;
          }
          return condition;
        }),
    });
  });
};

const mapSubstanceToEditable = (substance) => {
  return Object.assign({}, substance, {
    toxicityLevels: mapSubstanceLevels(substance.toxicityLevels),
    inheritedToxicityLevels: mapSubstanceLevels(substance.inheritedToxicityLevels),
  });
};

const update = ({ currentVersion, substance, saved, failed, conflict, substanceName, version }) => {
  const time = new Date().toTimeString();

  const substanceId = Substance.id(substance);
  const substanceToSend = clone(substance.get());
  // Old toxins might have description set to null
  if (isNil(substanceToSend.description)) {
    substanceToSend.description = '';
  }
  toxinService
    .updateToxin(substanceId.get(), substanceToSend)
    .then((toxinInfo) => {
      if (currentVersion === version.get()) {
        substanceName.set(toxinInfo.name);
        substance.set(mapSubstanceToEditable(toxinInfo));
        saved.set(`Saved ${time}`);
        failed.set(null);
        conflict.set(null);
      }
    })
    .catch((err) => {
      if (err === 409) {
        conflict.set('Toxin with given name already exists');
      }
      if (err.status && err.body) {
        conflict.set(err.body.message);
      }
      failed.set(`Failed ${time}`);
      saved.set(null);
    });
};

const toxinEquals = (oldVersion, newVersion) => {
  const oldPicked = oldVersion
    ? pick(
        [
          'id',
          'unit',
          'description',
          'toxicityLevels',
          'parent',
          'synonyms',
          'parentDescription',
          'additionalInfo',
          'ingredients',
          'visible',
          'children',
          'name',
        ],
        oldVersion,
      )
    : {};
  const newPicked = newVersion
    ? pick(
        [
          'id',
          'unit',
          'description',
          'toxicityLevels',
          'parent',
          'synonyms',
          'parentDescription',
          'additionalInfo',
          'ingredients',
          'visible',
          'children',
          'name',
        ],
        newVersion,
      )
    : {};
  if (newPicked.toxicityLevels) {
    newPicked.toxicityLevels = newPicked.toxicityLevels.map((level) => {
      return pick(['category', 'conditions', 'description', 'threshold'], level);
    });
  }
  if (oldPicked.toxicityLevels) {
    oldPicked.toxicityLevels = oldPicked.toxicityLevels.map((level) => {
      return pick(['category', 'conditions', 'description', 'threshold'], level);
    });
  }
  return equals(oldPicked, newPicked);
};

export const SubstanceEditor = ({ selectedSubstance, substances, onClose, conditions, clinicalSigns }) => {
  const open = U.atom(false);
  const substance = U.atom(null);
  const substanceName = U.atom(null);
  const saved = U.atom(null);
  const failed = U.atom(null);
  const conflict = U.atom(null);
  const version = U.atom(1);

  selectedSubstance.skipDuplicates(toxinEquals).observe((selected) => {
    if (selected) {
      substanceName.set(selected.name);
      substance.set(mapSubstanceToEditable(selected));
    }
  });

  const id = Substance.id(substance);
  const parent = Substance.parent(substance);
  const parentDescription = Substance.parentDescription(substance);
  const unit = Substance.unit(substance);
  const description = Substance.description(substance);
  const ingredients = Substance.ingredients(substance);
  const toxicityLevels = Substance.toxicityLevels(substance);
  const synonyms = Substance.synonyms(substance);
  const name = Substance.name(substance);
  const visible = Substance.visible(substance);
  const clinicalSignGrouping = Substance.clinicalSignGrouping(substance);

  const inheritedMeta = Substance.inheritedMeta(substance);
  const inheritedClinicalSignsCategories = Substance.inheritedClinicalSignsCategories(substance);
  const inheritedClinicalSignsCategoriesMeta = inheritedMeta.view('inheritedClinicalSignsCategories');
  const inheritedClinicalSignsDescription = Substance.inheritedClinicalSignsDescription(substance);
  const inheritedClinicalSignsDescriptionMeta = inheritedMeta.view('inheritedClinicalSignsDescription');
  const inheritedDescription = Substance.inheritedDescription(substance);
  const inheritedDescriptionMeta = inheritedMeta.view('inheritedDescription');
  const inheritedToxicityLevels = Substance.inheritedToxicityLevels(substance);
  const inheritedToxicityLevelsMeta = inheritedMeta.view('inheritedToxicityLevels');
  const inheritedSynonyms = Substance.inheritedSynonyms(substance);
  const inheritedSynonymsMeta = inheritedMeta.view('inheritedSynonyms');

  const substanceClinicalSigns = U.atom([]);
  clinicalSigns.observe((signs) =>
    substanceClinicalSigns.set(signs ? signs.sort((a, b) => a.name.localeCompare(b.name)).map((s) => s.name) : []),
  );

  const editingDisabled = U.atom(false);

  substance
    .skipDuplicates(toxinEquals)
    .debounce(1000)
    .observe(() => {
      if (open.get()) {
        version.set(version.get() + 1);
        update({
          currentVersion: version.get(),
          substance: substance,
          saved: saved,
          failed: failed,
          conflict: conflict,
          substanceName: substanceName,
          version: version,
        });
      }
    });

  open.observe(() => {
    saved.set(null);
    failed.set(null);
    conflict.set(null);
  });

  const editableContent = U.mapValue(
    (descriptionParent) =>
      !isNil(descriptionParent)
        ? ''
        : [
            <h2 key={'toxicity-levels-title'}>Toxicity levels</h2>,
            <ToxicityLevelEditor
              key={'toxicity-levels-content'}
              toxicityLevels={toxicityLevels}
              allConditions={conditions}
              allClinicalSigns={clinicalSigns}
              disabled={editingDisabled}
              inheritedToxicityLevels={inheritedToxicityLevels}
              inheritedFrom={inheritedToxicityLevelsMeta}
            />,
          ],
    parentDescription,
  );

  return (
    <div>
      <button type="open" onClick={() => open.set(true)}>
        Edit
      </button>
      {U.mapValue(
        (modalOpen) =>
          modalOpen ? (
            <Modal open={true}>
              <div className="SubstanceEditor">
                <div className="Content">
                  <h1>{name}</h1>
                  <h2>Name</h2>
                  <input
                    data-cy="substanceNameInput"
                    className="SubstanceName"
                    onChange={(evt) => name.set(evt.target.value)}
                    value={name}
                  />
                  <SuccessInfo failed={conflict} classNameFailed="Conflict" />
                  <h2>Visible in search</h2>
                  {U.mapValue(
                    (isVisible) => (
                      <EditorYesNoToggle
                        isOn={isVisible}
                        onToggle={(changedVisibility) => visible.set(changedVisibility)}
                        className={'VisibleInSearchWrapper'}
                      />
                    ),
                    visible,
                  )}
                  <h2>Unit</h2>
                  <EditorSelect
                    currentValue={unit}
                    name={'unit'}
                    className={'UnitSelect'}
                    onSelect={(selected) => unit.set(selected)}
                    options={[
                      { value: 'mg', label: 'Milligrams (mg)' },
                      { value: 'g', label: 'Grams (g)' },
                      { value: 'ml', label: 'Millilitres (ml)' },
                      { value: 'pcs', label: 'Pieces (pcs)' },
                    ]}
                  />
                  <h2>Group parent</h2>
                  <SubstanceSelector
                    value={parent.map((v) => (v ? { value: v.id, label: v.name } : null))}
                    onChange={(option) => parent.set(option ? { id: option.value, name: option.label } : null)}
                    allowEmpty={true}
                    showHidden={true}
                  />
                  <h2>Description parent</h2>
                  <SubstanceSelector
                    value={parentDescription.map((v) => (v ? { value: v.id, label: v.name } : null))}
                    onChange={(option) =>
                      parentDescription.set(option ? { id: option.value, name: option.label } : null)
                    }
                    allowEmpty={true}
                    showHidden={true}
                  />
                  {U.mapValue(
                    (descriptionParent) =>
                      !isNil(descriptionParent) ? (
                        <div className={'DescriptionParentNote'}>
                          With description parent set, ingredients are sources of the description parent and the
                          toxicity calculation is the sum of ingredients. Also toxicity levels are defined in
                          description parent.
                        </div>
                      ) : (
                        ''
                      ),
                    parentDescription,
                  )}
                  <h2>Ingredients</h2>
                  <IngredientEditor ingredients={ingredients} substanceUnit={unit} substances={substances || []} />
                  {editableContent}
                  <ClinicalSignGroupingEditor
                    key={'clinical-signs-grouping'}
                    grouping={clinicalSignGrouping}
                    allClinicalSigns={substanceClinicalSigns}
                    disabled={editingDisabled}
                    inheritedGrouping={inheritedClinicalSignsCategories}
                    inheritedDescription={inheritedClinicalSignsDescription}
                    inheritedGroupingFrom={inheritedClinicalSignsCategoriesMeta}
                    inheritedDescriptionFrom={inheritedClinicalSignsDescriptionMeta}
                  />
                  <h2 key={'synonyms-title'}>Synonyms</h2>
                  <EditorEditableList
                    key={'synonyms-content'}
                    className={'Synonyms'}
                    disabled={editingDisabled}
                    inheritedTitle={'Inherited synonyms'}
                    inheritedItems={inheritedSynonyms}
                    items={synonyms}
                    inheritedFrom={inheritedSynonymsMeta}
                  />
                  <h2 key={'description-title'}>Description</h2>
                  <EditorTextArea
                    dataCy="substanceDescriptionTextarea"
                    key={'description-content'}
                    disabled={editingDisabled}
                    className={'DescriptionTextArea'}
                    currentValue={description}
                    onChange={(value) => description.set(value)}
                    inheritedTitle={inheritedDescription ? 'Inherited description' : null}
                    inheritedValue={inheritedDescription}
                    inheritedFrom={inheritedDescriptionMeta}
                  />
                </div>
                <div className="BottomBar">
                  <SuccessInfo saved={saved} failed={failed} classNameSaved="Saved" classNameFailed="Failed" />
                  <button
                    data-cy="closeSubstanceEditButton"
                    className="CloseButton"
                    onClick={() => {
                      onClose(id.get());
                      open.set(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Modal>
          ) : (
            ''
          ),
        open,
      )}
    </div>
  );
};
