import * as U from 'karet.util';
import * as React from 'karet';

export const styleVisibleToggle = (observable, visibleStyle = 'block') =>
  U.mapValue((value) => ({ display: value ? visibleStyle : 'none' }), observable);

export const styleInvertedVisibleToggle = (observable, visibleStyle = 'block') =>
  U.mapValue((value) => ({ display: value ? 'none' : visibleStyle }), observable);

const Info = ({ className, text, style }) => (
  <div className={className} style={style}>
    {text}
  </div>
);

export const SuccessInfo = ({ saved, failed, classNameSaved, classNameFailed }) => (
  <div data-cy="saveSuccessInfo">
    <Info key="Saved" className={classNameSaved} text={saved} style={styleVisibleToggle(saved)} />
    <Info key="Failed" className={classNameFailed} text={failed} style={styleVisibleToggle(failed)} />
  </div>
);

export const isNonEmptyString = (str) => str !== null && str !== undefined && str.length > 0;
