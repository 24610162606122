import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { Modal } from '../../Components/Modal';
import { clinicalSignService } from '../../../services/clinicalsign';
import { ClinicalSign } from './ClinicalSignMeta';
import './ClinicalSignEditor.css';

const onCreateSign = (open, clinicalSign, allClinicalSigns, addTo) => {
  const data = clinicalSign.get();
  clinicalSignService.createClinicalSign(data.name, data.description).then(() => {
    allClinicalSigns.modify(R.append(data));
    if (addTo) {
      addTo.modify(R.append(data.name));
    }
    open.set(false);
  });
};

export const ClinicalSignEditor = ({ open, allClinicalSigns, addTo }) => {
  const clinicalSign = U.atom(ClinicalSign.create());
  const name = ClinicalSign.name(clinicalSign);
  const description = ClinicalSign.description(clinicalSign);
  return (
    <Modal open={open}>
      <div className="ClinicalSignEditor">
        <h2>Create clinical sign</h2>
        <label>Name</label>
        <input type="text" value={name} onChange={(evt) => name.set(evt.target.value)} />
        <label>Description</label>
        <textarea cols="60" rows="10" value={description} onChange={(evt) => description.set(evt.target.value)} />
        <button className="CancelButton" onClick={() => open.set(false)}>
          Cancel
        </button>
        <button className="CreateSignButton" onClick={() => onCreateSign(open, clinicalSign, allClinicalSigns, addTo)}>
          Create
        </button>
      </div>
    </Modal>
  );
};
