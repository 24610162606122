import React, { FC } from 'react';
import { Loader } from '../../Components/Loader';
import { StripeCoupon, StripePrice } from './types';
import { applyCoupon, formatMoney, freeTrialText } from './pricing';

interface SubscriptionPriceProps {
  coupon: StripeCoupon | null;
  price: StripePrice | null;
  loadingPrice: boolean;
  showFreeTrialText?: boolean;
}

export const SubscriptionPrice: FC<SubscriptionPriceProps> = ({
  coupon,
  price,
  loadingPrice,
  showFreeTrialText = true,
}) => {
  const priceFragment = (
    <React.Fragment>
      <span data-cy="subscriptionPrice" className="SubscriptionPrice">
        {formatMoney(applyCoupon(price, coupon))} &euro; per month
        {coupon && coupon.durationInMonths ? ` for ${coupon.durationInMonths} months` : ''} (excl. VAT)
      </span>
      {coupon?.valid === true && (
        <span data-cy="subscriptionNormalPrice" className="SubscriptionNormalPrice">
          Normal price: {formatMoney(price?.unitAmount ?? 999)} &euro; / month
        </span>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {loadingPrice ? <Loader /> : priceFragment}
      {showFreeTrialText && <span className="TrialPeriod">{freeTrialText(60)}</span>}
    </React.Fragment>
  );
};
