import { handleJsonResponse } from './common';

function getClinicalSigns() {
  return fetch('/api/clinicalsigns', {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonResponse);
}

function createClinicalSign(name, description) {
  const body = { name, description };
  return fetch('/api/clinicalsigns', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(handleJsonResponse);
}

export const clinicalSignService = {
  getClinicalSigns,
  createClinicalSign,
};
