import * as React from 'karet';
import { map, sortBy, values } from 'underscore';
import { splitEvery } from 'ramda';
import './ClinicalSignList.css';
import { ClinicalSign } from './ClinicalSign';

const orderSigns = (categories) => {
  return categories.map((category) => {
    return { ...category, clinicalSigns: category.clinicalSigns.map((s, index) => ({ name: s, order: index })) };
  });
};

const orderCategories = (categories) => {
  const categorySignMap = orderSigns(categories);
  return sortBy(values(categorySignMap), (category) => category.order);
};

const renderClinicalSigns = (signs) => {
  return map(signs, (signCategory) => {
    const sortedSigns = sortBy(signCategory.clinicalSigns, (sign) => sign.order);
    const chuncked = splitEvery(3, sortedSigns);
    return [
      <h3 className="ClinicalSignListHeading" key={`${signCategory.name}-heading`}>
        {signCategory.name}
      </h3>,
      <div key={`${signCategory.name}-separator`} className={'Separator'} />,
      chuncked.map((chunck, idx) => (
        <div key={`ClinicalSignChunk-${idx}`} className={'ClinicalSignChunck'}>
          {chunck.map((sign) => (
            <ClinicalSign key={sign.name} name={sign.name} />
          ))}
        </div>
      )),
    ];
  });
};

const mappedClinicalSigns = (substance) => {
  const additionalInfo = substance.additionalInfo;
  const clinicalSigns = additionalInfo && additionalInfo.clinicalSigns;
  const categories =
    clinicalSigns && clinicalSigns.categories && clinicalSigns.categories.length > 0 ? clinicalSigns.categories : [];

  const inheritedClinicalSigns = substance.inheritedClinicalSignsCategories;
  const inheritedCategories = inheritedClinicalSigns && inheritedClinicalSigns.length > 0 ? inheritedClinicalSigns : [];

  const useInheritedSigns = categories.length === 0 && inheritedCategories.length > 0;
  return useInheritedSigns ? inheritedCategories : categories;
};

const mappedDescription = (substance) => {
  const additionalInfo = substance.additionalInfo;
  const clinicalSigns = additionalInfo && additionalInfo.clinicalSigns;

  const signsDescription = clinicalSigns ? clinicalSigns.description : '';
  const inheritedSignsDescription = substance.inheritedClinicalSignsDescription
    ? substance.inheritedClinicalSignsDescription
    : '';

  const useInheritedDescription =
    (!signsDescription || signsDescription.length === 0) &&
    inheritedSignsDescription &&
    inheritedSignsDescription.length > 0;
  return useInheritedDescription ? inheritedSignsDescription : signsDescription;
};

export const ClinicalSignList = ({ substance }) => {
  const categoriesToUse = mappedClinicalSigns(substance);
  const descriptionToUse = mappedDescription(substance);

  const allPossibleSigns =
    categoriesToUse.length > 0 ? orderCategories(categoriesToUse) : [{ name: '', order: 0, clinicalSigns: [] }];

  const content = renderClinicalSigns(allPossibleSigns);

  return (
    <div className={'ClinicalSignList'}>
      {descriptionToUse && <div className="ClinicalSignListDescription">{descriptionToUse}</div>}
      <div className={`ClinicalSignListContainer`}>{content}</div>
    </div>
  );
};
