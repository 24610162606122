import { StripeCoupon, StripePrice } from './types';

export function formatMoney(unitAmount: number | null) {
  return typeof unitAmount === 'number' && !isNaN(unitAmount) ? (unitAmount / 100).toFixed(2) : '';
}

export function applyCoupon(price: StripePrice | null, coupon: StripeCoupon | null) {
  if (!price?.unitAmount) {
    return NaN;
  }
  const { unitAmount } = price;

  if (coupon?.valid === false) {
    return unitAmount;
  }
  if (coupon?.percentOff) {
    return ((100 - coupon.percentOff) / 100) * unitAmount;
  } else if (coupon?.amountOff) {
    return unitAmount - coupon.amountOff;
  } else {
    return unitAmount;
  }
}

export const freeTrialText = (days: number) =>
  `Free trial for ${days} days. Your card will not be billed until your free period has ended.`;
