import * as React from 'karet';
import ReactDOM from 'react-dom';
import App from './App/App';
import * as authService from './services/auth';
import * as configService from './services/config';

function renderApp(loading = true, configData = null, initialUser = null) {
  ReactDOM.render(<App {...{ loading, configData, initialUser }} />, document.getElementById('root'));
}

renderApp(true, null, null);

configService
  .getConfig()
  .then((config) => {
    authService
      .getUser()
      .then((user) => renderApp(false, config, user))
      .catch(() => renderApp(false, config, null));
  })
  .catch(() => renderApp(false, null, null));
