import * as U from 'karet.util';
import * as L from 'partial.lenses';
import * as R from 'ramda';

const isCategorySelected = (activity, category, subCategories) =>
  activity.category === category && (subCategories === undefined || subCategories.indexOf(activity.subCategory) !== -1);

export const ToxinInfoMeta = {
  id: U.view('id'),
  name: U.view([L.prop('name'), L.valueOr(null)]),
  description: U.view('description'),
  treatmentActivities: U.view([L.prop('treatmentActivities'), L.normalize(R.sortBy(L.get('order')))]),
  inheritedTreatmentActivities: U.view([L.prop('inheritedTreatmentActivities'), L.normalize(R.sortBy(L.get('order')))]),
  synonyms: U.view('synonyms'),
  children: U.view(L.prop('children'), L.valueOr([])),
};

export const TreatmentActivities = {
  category: (category, subCategories, visibility, activities) =>
    U.view([
      L.filter(
        (activity) =>
          isCategorySelected(activity, category, subCategories) &&
          (activity.visibility === 'Both' || activity.visibility === visibility),
      ),
      L.valueOr([]),
    ])(activities),
  subCategory: (subCategory, visibility, activities) =>
    U.view([
      L.filter(
        (activity) =>
          activity.subCategory === subCategory &&
          (activity.visibility === 'Both' || activity.visibility === visibility),
      ),
      L.valueOr([]),
    ])(activities),
};
