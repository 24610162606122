import * as React from 'karet';
import * as U from 'karet.util';
import { EditableList } from '../../Components/EditableList';
import { Item, CheckList } from './ActivityMeta';
import './ActivityItemEditor.css';

export const ActivityItemEditor = ({ item }) => {
  const name = Item.name(item);
  const description = Item.description(item);
  const nameAsHeading = Item.nameAsHeading(item);
  const remark = Item.remark(item);
  const generic = Item.generic(item);
  const checklist = Item.checklist(item);
  const notes = CheckList.notes(checklist);
  const dos = CheckList.dos(checklist);
  const donts = CheckList.donts(checklist);
  return (
    <React.Fragment>
      <label>Name</label>
      <input type="text" value={name} onChange={(evt) => name.set(evt.target.value)} />
      <label>Use separator</label>
      {U.mapValue(
        (asHeading) => (
          <div className={'NameAsHeadingWrapper'}>
            <input type="radio" checked={asHeading} onChange={() => nameAsHeading.set(true)} />
            Yes
            <input type="radio" checked={!asHeading} onChange={() => nameAsHeading.set(false)} />
            No
          </div>
        ),
        nameAsHeading,
      )}
      <label>Generic</label>
      {generic.get() ? 'Yes' : 'No'}
      <label>Description</label>
      <textarea cols="60" rows="5" value={description} onChange={(evt) => description.set(evt.target.value)} />
      <label>Remark</label>
      <textarea cols="60" rows="5" value={remark} onChange={(evt) => remark.set(evt.target.value)} />
      <label>Notes</label>
      <EditableList className="ItemList" items={notes} />
      <label>Do</label>
      <EditableList className="ItemList" items={dos} />
      <label>Don't</label>
      <EditableList className="ItemList" items={donts} />
    </React.Fragment>
  );
};
