import React, { FC } from 'react';
import ReactSelect, { Props } from 'react-select';
import { StylesConfig } from 'react-select/src/styles';

export type CountrySelection = {
  label: string;
  value: string;
};

export const CountrySelect: FC<Props<CountrySelection, false>> = (props) => <ReactSelect styles={styles} {...props} />;

const styles: StylesConfig<CountrySelection, false> = {
  control: (base) => ({
    ...base,
    borderRadius: '0px',
    border: '1px solid #93cac9',
    color: 'var(--dark-indigo)',
    backgroundColor: 'white',
    padding: '0px',
    minHeight: '30px',
  }),
  input: (base) => ({
    ...base,
    height: '26px',
    maxHeight: '26px',
    margin: '0px',
    paddingLeft: '0.5em',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '2px',
  }),
  valueContainer: (base) => ({
    ...base,
    padding: '2px 0px',
  }),
  placeholder: (base) => ({
    ...base,
  }),
  singleValue: (base) => {
    return {
      ...base,
      top: '60%',
      paddingLeft: '0.5em',
    };
  },
};
