import * as React from 'karet';
import Feedback from './Feedback';
import { Modal } from '../../Components/Modal';
import './ModalFeedback.css';

export const ModalFeedback = ({ feedbackOpen }) => {
  return (
    <Modal open={feedbackOpen}>
      <div className="ModalFeedback">
        <Feedback open={feedbackOpen} />
      </div>
    </Modal>
  );
};
