import * as U from 'karet.util';
import * as React from 'karet';

const TextEdit = ({ value, rows, staticClassName, editClassName }) => {
  const editing = U.atom(false);
  return (
    <div>
      {U.mapValue(
        (editVisible) =>
          editVisible ? (
            <textarea
              className={editClassName}
              value={value}
              rows={rows}
              onChange={(evt) => value.set(evt.target.value)}
              onBlur={() => editing.set(false)}
            />
          ) : (
            <div className={staticClassName} onClick={() => editing.set(true)}>
              {value}
            </div>
          ),
        editing,
      )}
    </div>
  );
};

export default TextEdit;
