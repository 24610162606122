import * as React from 'karet';
import * as U from 'karet.util';
import AsyncSelect from 'react-select/async';
import Highlighter from 'react-highlight-words';
import { debounce } from 'throttle-debounce';
import { flatten } from 'underscore';
import { toxinService } from '../../../../services/toxin';
import { isToxinSelectable } from '../../../../utils/toxin';
import './SubstanceSelector.css';
import * as analytics from '../../../../services/analytics';

const AsyncSelectLifted = U.toKaret(AsyncSelect);

const selectStyles = {
  control: (base) => ({
    ...base,
    borderRadius: '0px',
    border: '1px solid #979797',
    color: 'var(--dark-indigo)',
    backgroundColor: 'white',
  }),
  option: (base, state) => {
    return {
      ...base,
      fontStyle: state.data.hasChildren ? 'Italic' : 'Normal',
      fontSize: 18 - state.data.level,
      marginLeft: state.data.level * 10 + 5,
    };
  },
  input: (base) => ({
    ...base,
    height: '32px',
  }),
  valueContainer: (base) => ({
    ...base,
    fontSize: '16px',
    fontWeight: 'bold',
    color: 'var(--dark-indigo)',
  }),
};

const mapOptions = (searchValue, level, toxins) => {
  return flatten(
    toxins.map((toxin) => {
      const synonym = toxin.synonyms.find(
        (synonym) => synonym.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 && searchValue.length > 0,
      );
      const synoMatch = synonym ? `(${synonym})` : '';
      const hidden = toxin.visible ? '' : '(hidden)';
      const label = toxin.name + synoMatch + hidden;

      const disabled = label.toLowerCase().indexOf(searchValue.toLowerCase()) === -1 && searchValue.length > 0;
      const option = {
        label: label,
        value: toxin.id,
        level,
        hasChildren: toxin.children.length > 0,
        visible: !isToxinSelectable(toxin),
        disabled: disabled,
      };
      if (toxin.children.length > 0) {
        const children = mapOptions(searchValue, level + 1, toxin.children);
        return [option].concat(children);
      }
      return option;
    }),
  );
};

const loadOptions = (props) => (searchValue, callback) => {
  const { allowEmpty, showHidden, feedback, sendAnalytics } = props;
  if (sendAnalytics && searchValue && searchValue.length > 3) {
    analytics.siteSearch(searchValue);
  }
  toxinService
    .searchToxins(searchValue)
    .then((toxins) => {
      const clearedToxins = showHidden
        ? toxins
        : toxins
            .filter((toxin) => toxin.visible)
            .map((toxin) => {
              toxin.children = toxin.children.filter((childToxin) => childToxin.visible);
              return toxin;
            });
      const options = mapOptions(searchValue, 0, clearedToxins);
      if (allowEmpty) {
        options.unshift({ label: 'No parent', value: null, level: 0, hasChildren: false, disabled: false });
      }
      if (feedback) {
        options.push({
          label: 'Report missing substance',
          value: 'FEEDBACK',
          level: 0,
          hasChildren: false,
          disabled: false,
        });
      }
      callback(options);
    })
    .catch((e) => console.log(`Toxin search error: ${e}`));
};

const loadOptionsDebounced = (props) => debounce(500, loadOptions(props));

const SubstanceSelector = ({ value, className, onChange, allowEmpty, showHidden, feedback, sendAnalytics }) => {
  const props = { allowEmpty, showHidden, feedback, sendAnalytics };
  const searchTerm = U.atom('');
  const debouncedLoadOptions = loadOptionsDebounced(props);

  const optionLoader = (searchValue, callback) => {
    searchTerm.set(searchValue);
    return debouncedLoadOptions(searchValue, callback);
  };

  const internalOnChange = (option) => {
    const currentTerm = searchTerm.get();
    searchTerm.set('');
    return onChange(option, currentTerm);
  };

  return (
    <AsyncSelectLifted
      className={className}
      placeholder=""
      defaultOptions={true}
      isClearable={true}
      loadOptions={optionLoader}
      isOptionDisabled={(option) => option.disabled}
      formatOptionLabel={(option, { inputValue }) => (
        <Highlighter
          highlightClassName="SubstanceSelector Highlighter"
          searchWords={typeof inputValue === 'string' ? inputValue.split() : []}
          textToHighlight={option.label || ''}
        />
      )}
      onChange={internalOnChange}
      value={value}
      styles={selectStyles}
      maxMenuHeight={400}
    />
  );
};

export default SubstanceSelector;
