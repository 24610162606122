import { handleJsonResponse } from './common';

const jsonRequest = (method, body = undefined) => ({
  method,
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
  body: body ? JSON.stringify(body) : undefined,
});

export function getBillingDetails() {
  return fetch(`/api/billingdetails`, jsonRequest('GET')).then(handleJsonResponse);
}

export function addPaymentMethod(paymentMethodId, cardholderName) {
  return fetch(`/api/billingdetails/paymentmethod`, jsonRequest('PUT', { paymentMethodId, cardholderName })).then(
    handleJsonResponse,
  );
}

export function updateBillingInformation(billingInformation) {
  return fetch(`/api/billingdetails/billinginformation`, jsonRequest('PUT', billingInformation)).then(
    handleJsonResponse,
  );
}
