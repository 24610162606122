import React, { useState } from 'react';
import { DefaultBillingInformationUpdateForm } from './DefaultBillingInformationUpdateForm';
import { UsBillingInformationUpdateForm } from './UsBillingInformationUpdateForm';

const BillingInformation = ({ billingDetails, onUpdate, onStartEditing, config }) => {
  const [isEditing, setIsEditing] = useState(false);
  const region = config.region;

  if (isEditing) {
    return region === 'default' ? (
      <DefaultBillingInformationUpdateForm
        billingDetails={billingDetails}
        onUpdate={onUpdate}
        onCancel={() => setIsEditing(false)}
      />
    ) : (
      <UsBillingInformationUpdateForm
        billingDetails={billingDetails}
        onUpdate={onUpdate}
        onCancel={() => setIsEditing(false)}
      />
    );
  }
  return (
    <>
      <dl>
        {region === 'default' && (
          <>
            <dt>VAT identification number</dt>
            <dd>{billingDetails.vatId}</dd>
          </>
        )}
        <dt>Billing address</dt>
        <dd>
          {billingDetails.companyName && (
            <>
              {billingDetails.companyName}
              <br />
            </>
          )}
          {billingDetails.streetAddress}, {billingDetails.postalCode}, {billingDetails.city},{' '}
          {(billingDetails.state && billingDetails.state + ', ') || ''}
          {billingDetails.countryCode}
        </dd>
      </dl>
      <button
        onClick={() => {
          onStartEditing();
          setIsEditing(true);
        }}
      >
        Update billing information
      </button>
    </>
  );
};

export default BillingInformation;
