import * as React from 'karet';

export const EditorSelect = ({ currentValue, name, options, onSelect, className }) => {
  return (
    <div className={`EditorSelect ${className}`}>
      <select name={name} value={currentValue} onChange={(evt) => onSelect(evt.target.value)}>
        {options.map((option, index) => {
          return (
            <option key={`${className}-option-${index}`} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
};
