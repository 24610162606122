import * as React from 'karet';
import { styleVisibleToggle } from '../../utils/ui';
import './Modal.css';

export const Modal = ({ open, children }) => {
  return (
    <div className="Modal">
      <div className="Background" style={styleVisibleToggle(open)} />
      <div className="Content" style={styleVisibleToggle(open)}>
        {children}
      </div>
    </div>
  );
};
