import * as React from 'karet';
import { useEffect, useState } from 'react';
import * as U from 'karet.util';
import * as R from 'ramda';
import { user as userService } from '../../services/user';
import * as accountService from '../../services/account';
import { SuccessInfo } from '../../utils/ui';
import { User } from './UserMeta';
import './UserManager.css';
import { ConfirmButton } from '../Components/ConfirmButton';
import { Loader } from '../Components/Loader';

// eslint-disable-next-line
const removeUser = (user) => {
  userService.removeUser(user.get().id).then(() => user.remove());
};

const removeAccount = (user) => {
  accountService.destroyAccount(user.get().accountId).then(() => user.remove());
};

const createUser = (region, users) => {
  userService.createUser('na@na.com', 'EndUser', region).then((newUser) => {
    users.modify(R.append(newUser));
  });
};

const updateUser = (saved, failed, userData) => {
  const time = new Date().toTimeString();
  userService
    .updateUser(userData)
    .then(() => {
      saved.set(`Saved ${time}`);
      failed.set(null);
    })
    .catch(() => {
      failed.set(`Failed ${time}`);
      saved.set(null);
    });
};

const sendWelcomeEmail = (user, region, ajaxLoader) => {
  ajaxLoader.set('show');
  userService
    .sendWelcome(user.get().email, region.get())
    .catch((err) => alert('Sending failed. See technical details below.\n\n' + JSON.stringify(err)))
    .finally(() => ajaxLoader.set(''));
};

const UserEdit = ({ saved, failed, user }) => {
  const id = User.id(user);
  const firstName = User.firstName(user);
  const lastName = User.lastName(user);
  const email = User.email(user);
  const role = User.role(user);
  const region = User.region(user);
  const accountType = User.accountType(user);
  const updateLock = U.atom(false);
  const ajaxLoader = U.atom('');

  const [subscription] = useState(
    user
      .skip(1)
      .filter(() => !updateLock.get())
      .debounce(1000)
      .observe((userData) => updateUser(saved, failed, userData)),
  );

  useEffect(() => {
    return () => {
      subscription.unsubscribe();
    };
  }, [subscription]);

  return (
    <tr className="UserEditRow">
      <td>{id}</td>
      <td>
        <input value={firstName} name="firstName" onChange={(evt) => firstName.set(evt.target.value)} />
      </td>
      <td>
        <input value={lastName} name="lastName" onChange={(evt) => lastName.set(evt.target.value)} />
      </td>
      <td>
        <input value={email} name="email" onChange={(evt) => email.set(evt.target.value)} />
      </td>
      <td>{region}</td>
      <td>
        <select value={role} onChange={(evt) => role.set(evt.target.value)}>
          <option key="endUser" value="EndUser">
            End-user
          </option>
          <option key="admin" value="Admin">
            Admin
          </option>
        </select>
      </td>
      <td>{accountType}</td>
      <td>
        <button className="WelcomeButton" onClick={() => sendWelcomeEmail(user, region, ajaxLoader)}>
          Welcome
        </button>
      </td>
      <td className="AjaxIndicator">
        <Loader className={ajaxLoader} />
      </td>
      <td>
        <ConfirmButton className={'RemoveButton RoundButton'} onConfirm={() => removeAccount(user)} />
      </td>
    </tr>
  );
};

const loadingUsers = U.atom(false);

const getUsers = (region, users) => {
  loadingUsers.set(true);
  users.set([]);
  userService
    .getFreeUsers(region)
    .then(R.sortBy(R.prop('id')))
    .then((result) => users.set(result))
    .catch(() => users.set([]))
    .finally(() => loadingUsers.set(false));
};

export const UserManager = ({ users }) => {
  const saved = U.atom(null);
  const failed = U.atom(null);
  const filter = U.atom('');
  const filterInput = U.atom('');

  const [region, setRegion] = useState('default');

  const [filterChanges] = useState(
    filterInput
      .skipDuplicates()
      .debounce(500)
      .observe((f) => filter.set(f)),
  );

  useEffect(() => {
    return () => filterChanges.unsubscribe();
  }, [filterChanges]);

  useEffect(() => {
    getUsers(region, users);
  }, [region, users]);

  const matchesFilter = (user, f) => {
    if (f === '') {
      return true;
    }

    return (
      (user.firstName || '').toLowerCase().includes(f.toLowerCase()) ||
      (user.lastName || '').toLowerCase().includes(f.toLowerCase()) ||
      (user.email || '').toLowerCase().includes(f.toLowerCase())
    );
  };

  return (
    <div className="UserManager">
      <div className="Content">
        <h1 className={'UsersHeading'}>Manage free users ({U.view('length', users)})</h1>
        <div className="UserFilter">
          <input
            type="search"
            placeholder="[Type to filter]"
            value={filterInput}
            onChange={(e) => filterInput.set(e.target.value)}
          />
        </div>
        <div className="UsersRegionSelector">
          <label>
            <input
              type="radio"
              name="region"
              value="default"
              checked={region === 'default'}
              onChange={() => setRegion('default')}
            />
            Europe and other countries
          </label>
          <label>
            <input type="radio" name="region" value="US" checked={region === 'us'} onChange={() => setRegion('us')} />
            US
          </label>
          {U.ifElse(loadingUsers, <Loader />, null)}
        </div>
        <table className={'UsersTable'}>
          <thead>
            <tr>
              <th>Id</th>
              <th>First name</th>
              <th>Last name</th>
              <th>Email</th>
              <th>Region</th>
              <th>Role</th>
              <th>Type</th>
              <th>Send</th>
              <th />
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {U.mapValue(
              (f) => (
                <>
                  {U.mapElems(
                    (user) =>
                      matchesFilter(user.get(), f) ? (
                        <UserEdit key={user.get().id} user={user} saved={saved} failed={failed} />
                      ) : null,
                    users,
                  )}
                </>
              ),
              filter,
            )}
          </tbody>
        </table>
      </div>
      <div className="BottomBar">
        <button
          className="CreateButton"
          onClick={() => {
            filterInput.set('');
            createUser(region, users);
          }}
        >
          Create user
        </button>
        <SuccessInfo saved={saved} failed={failed} classNameSaved="Saved" classNameFailed="Failed" />
      </div>
    </div>
  );
};
