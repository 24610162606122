import { handleJsonResponse } from './common';
import { PaymentIntent } from '@stripe/stripe-js';

export interface CreateAccountRequest {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  cardholderName: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  state?: string;
  billingAddress: {
    streetAddress: string;
    postalCode: string;
    city: string;
    state?: string;
    country?: string;
  };
  countryCode?: string;
  vatId: string | null;
  paymentMethodId: string;
  termsAccepted: boolean;
  stripeCouponId: string;
  stripePriceId: string | null;
}

interface SubscriptionPatchBody {
  cancelAtPeriodEnd?: boolean;
}

interface SubscriptionPostBody {
  stripePriceId: string;
  stripeCouponId?: string;
}

export interface Subscription {
  stripeId: string;
  currentPeriodStart: string;
  currentPeriodEnd: string;
  canceledAt: string;
}

type BodyType = CreateAccountRequest | SubscriptionPatchBody | SubscriptionPostBody | { accountId: string };

function jsonRequest(method: string, body: BodyType | undefined = undefined): RequestInit {
  return {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  };
}

export function createAccount(requestBody: CreateAccountRequest) {
  return fetch('/api/accounts', jsonRequest('POST', requestBody)).then(handleJsonResponse);
}

export function destroyAccount(accountId: string) {
  return fetch(`/api/accounts/${accountId}`, jsonRequest('DELETE')).then(handleJsonResponse);
}

export function getSubscriptions(accountId: string) {
  return fetch(`/api/subscriptions/${accountId}`, jsonRequest('GET')).then((r: Response) => {
    return handleJsonResponse<Subscription[]>(r);
  });
}

export function getLatestSubscription(accountId: string) {
  return fetch(`/api/subscriptions/${accountId}/latest`, jsonRequest('GET')).then((r: Response) => {
    return handleJsonResponse<Subscription>(r);
  });
}

function patchSubscription(accountId: string, subscriptionStripeId: string, body: SubscriptionPatchBody) {
  return fetch(`/api/v2/accounts/${accountId}/subscriptions/${subscriptionStripeId}`, jsonRequest('PATCH', body)).then(
    handleJsonResponse,
  );
}

export function cancelSubscription(accountId: string, subscriptionStripeId: string) {
  return patchSubscription(accountId, subscriptionStripeId, { cancelAtPeriodEnd: true });
}

export function withdrawCancelSubscription(accountId: string, subscriptionStripeId: string) {
  return patchSubscription(accountId, subscriptionStripeId, { cancelAtPeriodEnd: false });
}

export function createSubscription(accountId: string, body: SubscriptionPostBody) {
  return fetch(`/api/v2/accounts/${accountId}/subscriptions`, jsonRequest('POST', body)).then((r: Response) => {
    return handleJsonResponse<{ paymentIntent: PaymentIntent }>(r);
  });
}

export function acceptTerms(accountId: string) {
  return fetch(`/api/accounts/terms/${accountId}`, jsonRequest('PUT', { accountId })).then(handleJsonResponse);
}
