import * as React from 'karet';
import { FC } from 'react';
import { Config } from '../types';
import { Atom } from 'karet.util';
import './FooterLogo.css';

interface FooterLogoProps {
  config: Atom<Config>;
}

const FooterLogo: FC<FooterLogoProps> = ({ config }) => {
  const region = config.get().region;
  const logoImage = `/images/${region === 'us' ? 'vetoquinolusa' : 'orionpharma'}_logo.png`;
  const altText = region === 'us' ? 'Vetoquinol' : 'Orion';
  const link = `https://${region === 'us' ? 'www.vetoquinolusa.com' : 'www.orion.com/en/'}`;

  return (
    <a href={link} target={'_blank'} className={'FooterLogoAnchor'} rel="noopener noreferrer">
      <img className={`FooterLogo__${region}`} src={logoImage} alt={altText} />
    </a>
  );
};

export default FooterLogo;
