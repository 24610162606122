import * as React from 'react';
import { FC, useState } from 'react';
import styles from './ForgotPasswordPage.module.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { user as userService } from '../../services/user';
import { useForm } from 'react-hook-form';
import { emailValidationRegex } from '../../utils/validation';
import { uniqueId } from 'lodash';

interface FormData {
  email: string;
}

export const ForgotPasswordPage: FC<RouteComponentProps> = () => {
  const [emailSentTo, setEmailSentTo] = useState('');
  const [emailSendError, setEmailSendError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit = async ({ email }: FormData) => {
    setEmailSendError(false);
    try {
      await userService.sendPasswordReset(email);
      setEmailSentTo(email);
    } catch (e: unknown) {
      console.log(e);
      setEmailSendError(true);
    }
  };

  const emailInputId = uniqueId();

  return (
    <section className={['PageContainer', styles.PageContainer].join(' ')}>
      <h1>{emailSentTo ? 'Reset Password' : 'Forgot Password'}</h1>

      {emailSentTo ? (
        <div data-cy="emailSentToMessage" className={styles.EmailSentContainer}>
          <div>An email has been sent to</div>
          <div className={styles.Email}>{emailSentTo}</div>
          <div>Please use the link in the email to create a new password.</div>
        </div>
      ) : (
        <>
          <p>Enter your email address to receive password reset instructions.</p>
          <form className={styles.Form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.FormRow}>
              <label htmlFor={emailInputId}>Email address</label>
              <input
                data-cy="forgotPasswordEmailInput"
                id={emailInputId}
                {...register('email', {
                  required: true,
                  pattern: emailValidationRegex,
                })}
              />
              {errors.email?.type === 'required' && (
                <div className="ValidationError">Please enter your email address</div>
              )}
              {errors.email?.type === 'pattern' && (
                <div className="ValidationError">Please enter a valid email address</div>
              )}
            </div>
            <button data-cy="forgotPasswordSubmitButton" type="submit">
              Send email
            </button>
            {emailSendError && (
              <div className="ValidationError">
                Error sending email. Try again and if the problem persists, contact support.
              </div>
            )}
          </form>
        </>
      )}
    </section>
  );
};

const handlePasswordResetSend = (email: string) => {
  userService.sendPasswordReset(email);
};

export default withRouter(ForgotPasswordPage);
