import React, { useState, useEffect } from 'react';

import { getBillingDetails } from '../../services/billingDetails';
import BillingInformation from './BillingInformation';
import PaymentMethod from './PaymentMethod';
import './BillingDetails.css';
import { Loader } from '../Components/Loader';

const BillingDetails = ({ config }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isError, setIsError] = useState(false);
  const [billingDetails, setBillingDetails] = useState(null);
  const [isNewPaymentMethodAdded, setIsNewPaymentMethodAdded] = useState(false);
  const [isBillingInformationUpdated, setIsBillingInformationUpdated] = useState(false);

  const fetchBillingDetails = async () => {
    setIsFetching(true);
    setIsError(false);
    try {
      return await getBillingDetails();
    } catch (e) {
      setIsError(true);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    fetchBillingDetails().then(setBillingDetails);
  }, []);

  const onStartEditing = () => {
    setIsNewPaymentMethodAdded(false);
  };

  const onPaymentMethodUpdate = () => {
    setIsNewPaymentMethodAdded(true);
    fetchBillingDetails().then(setBillingDetails);
  };

  const onBillingInformationUpdate = () => {
    setIsBillingInformationUpdated(true);
    fetchBillingDetails().then(setBillingDetails);
  };

  return (
    <>
      <hr />
      <div className="BillingDetails">
        {isFetching && <Loader className="LoadingIndicator" />}
        {isError && (
          <div className="ErrorText">Fetching billing details failed. Please try again or contact support.</div>
        )}
        {billingDetails && !isFetching && !isError && (
          <>
            <h2>Payment method</h2>
            {isNewPaymentMethodAdded && <div className="Success">New payment method added successfully.</div>}
            <PaymentMethod
              billingDetails={billingDetails}
              onUpdate={onPaymentMethodUpdate}
              onStartEditing={onStartEditing}
            />
            <hr />
            <h2>Billing information</h2>
            {isBillingInformationUpdated && <div className="Success">Billing information updated successfully.</div>}
            <BillingInformation
              billingDetails={billingDetails}
              onUpdate={onBillingInformationUpdate}
              onStartEditing={onStartEditing}
              config={config}
            />
          </>
        )}
      </div>
      <hr />
    </>
  );
};

export default BillingDetails;
