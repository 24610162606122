import * as React from 'react';
import { FC, useState } from 'react';
import './AccountPage.css';
import { useForm } from 'react-hook-form';
import { user as userService } from '../../services/user';

interface FormData {
  password: string;
  passwordAgain: string;
}

interface Props {
  changeHandler: (
    fn: (v: string) => void,
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    setFailed: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (newValue: string) => Promise<void>;
}

export const ChangePasswordForm: FC<Props> = ({ changeHandler }) => {
  const [passwordChangeSuccess, setPasswordChangeSuccess] = useState(false);
  const [passwordChangeFailure, setPasswordChangeFailure] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>();

  const onChangePasswordSubmit = ({ password }: FormData) => {
    changeHandler(userService.changePassword, setPasswordChangeSuccess, setPasswordChangeFailure)(password);
  };

  return (
    <form onSubmit={handleSubmit(onChangePasswordSubmit)}>
      <label>
        New password
        <input
          type="password"
          {...register('password', {
            required: true,
            minLength: 4,
          })}
        />
      </label>
      {errors.password?.type === 'required' && <div className="ValidationError">Password is required</div>}
      {errors.password?.type === 'minLength' && <div className="ValidationError">Must be at least 4 chars</div>}
      <label>
        Repeat password
        <input
          type="password"
          {...register('passwordAgain', {
            required: true,
            minLength: 4,
            validate: {
              matchesPreviousPassword: (value) => {
                const { password } = getValues();
                return password === value || 'Passwords should match!';
              },
            },
          })}
        />
      </label>
      {errors.passwordAgain?.type === 'required' && <div className="ValidationError">Repeat password is required</div>}
      {errors.passwordAgain?.type === 'minLength' && <div className="ValidationError">Must be at least 4 chars</div>}
      {errors.passwordAgain?.type === 'matchesPreviousPassword' && (
        <div className="ValidationError">{errors.passwordAgain.message}</div>
      )}
      {passwordChangeSuccess && <div className="Success">Password successfully changed</div>}
      {passwordChangeFailure && <div className="Failed">Password change failed</div>}
      <button type="submit">Save</button>
    </form>
  );
};
