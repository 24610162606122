import * as React from 'karet';

function renderSubstanceTree(className, onSelect, root, selected, level, allClickable) {
  const levelClassName = `Level${level} Node`;
  const hasChildren = root.children && root.children.length > 0;
  const parentClassString = hasChildren ? 'Parent' : '';
  const selectedClassString = root.name === selected ? 'Selected' : '';
  const headingClassName = parentClassString + ' ' + selectedClassString;
  return (
    <div className={levelClassName} key={root.name}>
      {hasChildren && !allClickable && <div className={headingClassName}>{root.name}</div>}
      {(!hasChildren || allClickable) && (
        <div className={headingClassName}>
          <span className={'Arrow'} />
          <span
            className={'Name'}
            onClick={() => {
              onSelect(root);
            }}
          >
            {root.name}
          </span>
        </div>
      )}
      {root.children
        .filter((toxin) => (allClickable ? true : toxin.visible))
        .map((child) => renderSubstanceTree(className, onSelect, child, selected, level + 1, allClickable))}
    </div>
  );
}

export const SubstanceTree = ({ root, selected, className, onSelect, allClickable }) => (
  <div className={className}>{renderSubstanceTree(className, onSelect, root, selected, 0, allClickable)}</div>
);
