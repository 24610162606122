import * as React from 'react';
import { FC } from 'react';
import './AccountPage.css';
import { Config, User } from '../../types';
import { ChangeEmailForm } from './ChangeEmailForm';
import { ChangePasswordForm } from './ChangePasswordForm';
import { SubscriptionAdmin } from './SubscriptionAdmin';
import { ConfirmButton } from '../Components/ConfirmButton';
import { destroyAccount } from '../../services/account';

interface Props {
  config: Config;
  user: User;
  handleLogout: (history: History) => void;
  handleCancellation: (history: History) => void;
  history: History;
}

export const AccountPage: FC<Props> = (props) => {
  const { user, handleLogout,handleCancellation, history } = props;

  const doDestroyAccount = async () => {
    await destroyAccount(user.accountId);
    handleCancellation(history);
  };

  return (
    <div className={['AccountPage', 'PageContainer'].join(' ')}>
      <h1 className={'Tagline'}>Account</h1>
      <div>Account email: {user.email}</div>
      <hr />
      <h2>Change email address</h2>
      <div>
        <ChangeEmailForm changeHandler={changeHandler} />
      </div>
      <hr />
      <h2>Change password</h2>
      <div>
        <ChangePasswordForm changeHandler={changeHandler} />
      </div>
      {user.accountRole === 'OWNER' && <SubscriptionAdmin {...props} />}
      <hr />
      <h2>Delete account</h2>
      <p>
        Deleting your account will immediately cancel your subscription and delete all account-related data. This action
        will remove all the users related to this account. <strong>This is an irrecoverable action</strong> and your
        account will be permanently lost.
      </p>
      <ConfirmButton
        text="Delete account"
        confirmText="Click to confirm"
        className="DeleteAccount"
        onConfirm={doDestroyAccount}
      />
    </div>
  );
};

function changeHandler<T>(
  fn: (v: T) => void,
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
  setFailed: React.Dispatch<React.SetStateAction<boolean>>,
) {
  return async (newValue: T) => {
    setFailed(false);
    setSuccess(false);

    try {
      await fn(newValue);
      setSuccess(true);
    } catch (err) {
      setFailed(true);
    }
  };
}
