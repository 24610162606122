import * as U from 'karet.util';
import * as L from 'partial.lenses';

export const ClinicalSignGroup = {
  create: ({ name = 'New group', order = 0, defaultCategory, clinicalSigns = [] } = {}) => ({
    name,
    order,
    default: defaultCategory,
    clinicalSigns,
  }),
  name: U.view('name'),
  order: U.view('order'),
  default: U.view('default'),
  clinicalSigns: U.view('clinicalSigns'),
};

export const ClinicalSignGrouping = {
  create: ({
    categories = [ClinicalSignGroup.create({ name: 'Default', order: 0, default: true, clinicalSigns: [] })],
    description = '',
  } = {}) => ({ categories, description }),
  description: U.view('description'),
  categories: U.view([L.prop('categories'), L.valueOr([])]),
};

export const Substance = {
  create: ({
    name = 'Not named',
    parent = '',
    parentDescription = '',
    description = '',
    unit = 'mg',
    synonyms = [],
    toxicityLevels = [],
    ingredients = [],
    visible = true,
  } = {}) => ({ name, parent, parentDescription, description, unit, synonyms, toxicityLevels, ingredients, visible }),
  id: U.view('id'),
  name: U.view('name'),
  parent: U.view('parent'),
  parentDescription: U.view('parentDescription'),
  description: U.view([L.prop('description'), L.valueOr('')]),
  inheritedDescription: U.view('inheritedDescription'),
  unit: U.view('unit'),
  synonyms: U.view('synonyms'),
  inheritedSynonyms: U.view('inheritedSynonyms'),
  toxicityLevels: U.view([L.prop('toxicityLevels'), L.valueOr([])]),
  inheritedToxicityLevels: U.view([L.prop('inheritedToxicityLevels'), L.valueOr([])]),
  ingredients: U.view('ingredients'),
  clinicalSignGrouping: U.view([
    L.prop('additionalInfo'),
    L.prop('clinicalSigns', L.valueOr(ClinicalSignGrouping.create())),
  ]),
  inheritedClinicalSignsDescription: U.view([L.prop('inheritedClinicalSignsDescription'), L.valueOr('')]),
  inheritedClinicalSignsCategories: U.view([
    L.prop('inheritedClinicalSignsCategories'),
    L.valueOr([ClinicalSignGroup.create({ name: 'Default', order: 0, default: true, clinicalSigns: [] })]),
  ]),
  inheritedMeta: U.view('inheritedMeta'),
  visible: U.view('visible'),
};
