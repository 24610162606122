import * as React from 'react';
import { FC, useState } from 'react';
import styles from './AccountDeletedPage.module.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';


interface FormData {
  email: string;
}

export const AccountDeletedPage: FC<RouteComponentProps> = () => {


  return (
    <section className={['PageContainer', styles.PageContainer].join(' ')}>
      <h1>Your account has been removed correctly!</h1>

     
    </section>
  );
};



export default withRouter(AccountDeletedPage);
