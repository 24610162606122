import { handleJsonResponse } from './common';

function getToxins() {
  return fetch('/api/toxins', {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response.status;
  });
}

function searchToxins(search) {
  return fetch(`/api/toxins?search=${encodeURIComponent(search)}`, {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response.status;
  });
}

function getToxinDose({ id, weight, amount, unit }) {
  const params = {
    amount,
    unit,
    weight,
  };

  const query = Object.entries(params)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
  return fetch(`/api/toxins/${encodeURIComponent(id)}/dose?${query}`, {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response.status;
  });
}

function getToxinInfo(id) {
  return fetch(`/api/toxins/${encodeURIComponent(id)}/info`, {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonResponse);
}

function updateToxin(name, toxinInfo) {
  return fetch(`/api/toxins/${encodeURIComponent(name)}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(toxinInfo),
  }).then(handleJsonResponse);
}

function createToxin() {
  return fetch(`/api/toxins`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({}),
  }).then(handleJsonResponse);
}

function removeToxin(name) {
  return fetch(`/api/toxins/${encodeURIComponent(name)}`, {
    method: 'DELETE',
    credentials: 'include',
  }).then(handleJsonResponse);
}

export const toxinService = {
  getToxins,
  searchToxins,
  getToxinDose,
  getToxinInfo,
  updateToxin,
  createToxin,
  removeToxin,
};
