import * as React from 'karet';
import * as U from 'karet.util';
import { SignUpForm } from './SignUpForm/SignUpForm';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

import './SignUpPage.css';
import { FC } from 'react';
import { Atom } from 'karet.util';
import { RouteComponentProps } from 'react-router-dom';
import { Config } from '../../types';

interface SignUpPageProps extends RouteComponentProps {
  config: Atom<Config | null>;
  privacyPolicyLink: string;
  termsOfUseLink: string;
}

export const SignUpPage: FC<SignUpPageProps> = ({ config, privacyPolicyLink, termsOfUseLink }) => {
  const loading = U.atom(false);
  const success = U.atom(false);
  const error = U.atom<boolean | null>(null);
  const apiKey = config.get()?.stripePublicKey;

  if (!apiKey) {
    return (
      <React.Fragment>
        <p>Sign up temporarily unavailable. Please try again later.</p>
      </React.Fragment>
    );
  }
  const stripePromise = loadStripe(apiKey);
  return (
    <section className={['SignUp', 'PageContainer'].join(' ')}>
      {U.ifElse(
        success,
        <div className={'OrderDone'}>
          <h1 className={'Tagline'}>Thank you</h1>
          <p>
            You will shortly receive a <b>ToxBuddy account activation email from us.</b> Sometimes the activation email
            may end up in your spam folder, so check that if it hasn’t arrived in your inbox.
          </p>
          <p>
            The activation email includes a link through which you can set up a password for your ToxBuddy account.
            After creating the password, you can log in by using your email address and the password. If you do not
            receive the activation email within 24 hours, please contact our customer support.
          </p>
        </div>,
        <Elements stripe={stripePromise}>
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <SignUpForm
                stripe={stripe}
                elements={elements}
                config={config}
                privacyPolicylink={privacyPolicyLink}
                termsOfUseLink={termsOfUseLink}
                onSuccess={() => {
                  error.set(false);
                  success.set(true);
                }}
                onError={() => {
                  success.set(false);
                  error.set(true);
                }}
                onLoading={() => {
                  loading.set(true);
                }}
                onReady={() => {
                  loading.set(false);
                }}
              />
            )}
          </ElementsConsumer>
        </Elements>,
      )}
    </section>
  );
};
