import * as U from 'karet.util';
import * as React from 'karet';

const NameEdit = ({ value, disabled, editClassName }) => {
  const editing = U.atom(false);
  return (
    <div>
      {U.mapValue(
        (editVisible) =>
          editVisible ? (
            <input
              className={editClassName}
              disabled={disabled}
              value={value}
              onChange={(evt) => value.set(evt.target.value)}
              onBlur={() => editing.set(false)}
            />
          ) : (
            <span onClick={() => editing.set(true)}>{value}</span>
          ),
        editing,
      )}
    </div>
  );
};

export default NameEdit;
