import * as React from 'karet';

export const EditorYesNoToggle = ({ isOn, onToggle, className }) => {
  return (
    <div className={`YesNoToggleWrapper ${className}`}>
      <input key={'visibility-toggle-yes'} checked={isOn} type={'radio'} onChange={() => onToggle(true)} />
      Yes
      <input key={'visibility-toggle-no'} checked={!isOn} type={'radio'} onChange={() => onToggle(false)} />
      No
    </div>
  );
};
