import React, { ChangeEvent, FormEvent } from 'react';
import { createAccount, CreateAccountRequest } from '../../../services/account';
import { CardNumberElement } from '@stripe/react-stripe-js';
import * as analytics from '../../../services/analytics';
import PaymentMethodForm from '../../Billing/PaymentMethodForm';
import DefaultBillingInformationForm from '../../Billing/DefaultBillingInformationForm';
import './SignUpForm.css';
import { Loader } from '../../Components/Loader';

import {
  Stripe,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeElements,
} from '@stripe/stripe-js';
import { SignUpFormState, UsBillingAddress } from './types';
import { DefaultRegionSubscriptionTypeSelector } from './DefaultRegionSubscriptionTypeSelector';
import { Config } from '../../../types';
import { Atom } from 'karet.util';
import { UsRegionSubscriptionTypeSelector } from './UsRegionSubscriptionTypeSelector';
import { isFormValid, missingFieldNames } from './validation';
import UsBillingInformationForm from '../../Billing/UsBillingInformationForm';
import { CountrySelection } from '../../Billing/CountrySelect';
import { StateSelection } from '../../Billing/UsStateSelect';

interface SignUpFormProps {
  stripe: Stripe | null;
  elements: StripeElements | null;
  config: Atom<Config | null>;
  privacyPolicylink: string;
  termsOfUseLink: string;
  onSuccess: () => void;
  onLoading: () => void;
  onError: () => void;
  onReady: () => void;
}

export class SignUpForm extends React.Component<SignUpFormProps, SignUpFormState> {
  constructor(props: SignUpFormProps) {
    super(props);
    this.state = initialState;
    this.onFieldChanged = this.onFieldChanged.bind(this);
    this.onEmailFieldChanged = this.onEmailFieldChanged.bind(this);
    this.onUsBillingAddressFieldChanged = this.onUsBillingAddressFieldChanged.bind(this);
    this.onStripePriceIdChanged = this.onStripePriceIdChanged.bind(this);
  }

  region() {
    return this.props.config.get()?.region;
  }

  handleSubmit(evt: FormEvent<HTMLFormElement>) {
    evt.preventDefault();
    this.setState({ loading: true, error: false, userExists: false });

    const state = this.state;
    const { stripe, elements } = this.props;
    const cardElement = elements?.getElement(CardNumberElement);

    if (!cardElement) {
      throw Error('CardNumberElement missing.');
    }

    const sameBillingAddress = this.state.sameBillingAddress;
    const countryCode = this.props.config.get()?.region === 'us' ? 'US' : state.country?.value;

    const billingAddress = {
      streetAddress: sameBillingAddress ? state.streetAddress : state.billingAddress.streetAddress,
      postalCode: sameBillingAddress ? state.postalCode : state.billingAddress.postalCode,
      city: sameBillingAddress ? state.city : state.billingAddress.city,
      state: sameBillingAddress ? state.state?.value : state.billingAddress.state?.value,
      country: countryCode,
    };

    stripe
      ?.createPaymentMethod({
        type: 'card',
        card: cardElement,
        // eslint-disable-next-line
        billing_details: {
          name: state.cardholderName,
          email: state.email,
          phone: state.phone,
        },
      })
      .then((payload) => {
        if (payload.paymentMethod) {
          const createAccountRequest: CreateAccountRequest = {
            firstName: state.firstName,
            lastName: state.lastName,
            cardholderName: state.cardholderName,
            email: state.email,
            phone: state.phone,
            streetAddress: state.streetAddress,
            postalCode: state.postalCode,
            city: state.city,
            state: state.state?.value,
            billingAddress,
            countryCode,
            companyName: state.companyName,
            vatId: state.vatId,
            paymentMethodId: payload.paymentMethod.id,
            termsAccepted: state.termsAccepted,
            stripeCouponId: state.stripeCouponId,
            stripePriceId: state.stripePriceId,
          };

          return createAccount(createAccountRequest)
            .then(() => {
              this.setState({ loading: false });
              this.props.onSuccess();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
              this.setState({ loading: false });
              if (err.status === 409) {
                this.setState({ userExists: true });
                analytics.error(err.status + ': User exists');
              } else {
                analytics.error(err.status + ': Unknown error');
              }
              this.setState({ error: true });
            });
        } else {
          this.setState({ loading: false, error: true });
          analytics.error(payload.error?.code + ': ' + payload.error?.message);
          this.props.onLoading();
        }
      })
      .catch((err) => {
        this.setState({ loading: false, error: true });
        analytics.error('Unknown error: ' + err);
        this.props.onReady();
      });
  }

  formValid() {
    const region = this.region();
    return region ? isFormValid(region, this.state) : false;
  }

  getMissingFieldNames() {
    const region = this.region();
    return region ? missingFieldNames(region, this.state) : [];
  }

  onFieldChanged(field: keyof SignUpFormState) {
    return (evt: ChangeEvent<HTMLInputElement>) => {
      this.setState((prevState) => ({ ...prevState, [field]: evt.target.value }));
    };
  }

  onEmailFieldChanged(field: 'email' | 'emailVerify') {
    return (evt: ChangeEvent<HTMLInputElement>) => {
      const newValue = evt.target.value;
      const emailVerified = newValue === (field === 'email' ? this.state.emailVerify : this.state.email);
      this.setState((prevState) => ({ ...prevState, [field]: newValue, emailVerified }));
    };
  }

  onSameBillingAddressChanged(evt: ChangeEvent<HTMLInputElement>) {
    this.setState({ sameBillingAddress: evt.target.checked });
  }

  onUsBillingAddressFieldChanged(field: keyof UsBillingAddress) {
    return (evt: ChangeEvent<HTMLInputElement>) => {
      this.setState((prevState) => ({
        ...prevState,
        billingAddress: { ...prevState.billingAddress, [field]: evt.target.value },
      }));
    };
  }

  onCheckboxChanged(field: keyof SignUpFormState) {
    return () => {
      this.setState((prevState) => ({ ...prevState, [field]: !prevState[field] }));
    };
  }

  onCountryChanged(country: CountrySelection | null) {
    this.setState({ country });
  }

  onStateChanged(state: StateSelection | null) {
    this.setState({ state });
  }

  onUsBillingAddressStateChanged(state: StateSelection | null) {
    this.setState((p) => ({ billingAddress: { ...p.billingAddress, state } }));
  }

  onVatIdChanged(value: string) {
    this.setState({
      vatId: value ? value.toUpperCase() : null,
    });
  }

  onStripePriceIdChanged(value: string) {
    this.setState({ stripePriceId: value });
  }

  render() {
    const submitEnabled = this.formValid() && !this.state.loading && !this.state.loadingPrice;
    const privacyPolicyLink = this.props.privacyPolicylink;
    const termsOfUseLink = this.props.termsOfUseLink;

    return (
      <div className="SignUpForm">
        <h1 className={'Tagline'}>Sign up</h1>
        <h2 className={'SubTagline'}>Not a customer yet?</h2>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <section>
            <div className="SignUpFormRow">
              <label className="OneThird">
                First Name
                <input data-cy="firstNameInput" type="text" onChange={this.onFieldChanged('firstName')} />
              </label>
              <label className="OneThird">
                Last Name
                <input data-cy="lastNameInput" type="text" onChange={this.onFieldChanged('lastName')} />
              </label>
              <label className="OneThird">
                Phone Number
                <input data-cy="phoneInput" type="tel" onChange={this.onFieldChanged('phone')} />
              </label>
            </div>
            <div className="SignUpFormRow">
              <label>
                Email Address
                <input data-cy="emailInput" type="email" onChange={this.onEmailFieldChanged('email')} />
              </label>
              <label>
                Verify Email Address
                <input
                  className={this.state.emailVerified ? '' : 'EmailsNotEqual'}
                  data-cy="verifyEmailInput"
                  type="email"
                  onPaste={(e) => e.preventDefault()}
                  onChange={this.onEmailFieldChanged('emailVerify')}
                />
              </label>
            </div>
            {this.region() === 'default' && (
              <DefaultBillingInformationForm
                onFieldChanged={this.onFieldChanged}
                onCountryChanged={this.onCountryChanged.bind(this)}
                onVatIdChanged={this.onVatIdChanged.bind(this)}
                companyName={this.state.companyName}
                country={this.state.country}
                vatId={this.state.vatId}
                streetAddress={this.state.streetAddress}
                postalCode={this.state.postalCode}
                city={this.state.city}
              />
            )}
            {this.region() === 'us' && (
              <UsBillingInformationForm
                onFieldChanged={this.onFieldChanged}
                onStateChanged={this.onStateChanged.bind(this)}
                onBillingAddressFieldChanged={this.onUsBillingAddressFieldChanged}
                onBillingStateChanged={this.onUsBillingAddressStateChanged.bind(this)}
                onSameBillingAddressChanged={this.onSameBillingAddressChanged.bind(this)}
                sameBillingAddress={this.state.sameBillingAddress}
                companyName={this.state.companyName}
                streetAddress={this.state.streetAddress}
                city={this.state.city}
                state={this.state.state}
                zipCode={this.state.postalCode}
                billingAddress={this.state.billingAddress}
              />
            )}

            <PaymentMethodForm
              onCardholderNameChange={this.onFieldChanged('cardholderName')}
              onCardNumberChange={(event: StripeCardNumberElementChangeEvent) =>
                this.setState({ cardNumberComplete: event.complete })
              }
              onCardExpiryChange={(event: StripeCardExpiryElementChangeEvent) =>
                this.setState({ cardExpiryComplete: event.complete })
              }
              onCardCvcChange={(event: StripeCardCvcElementChangeEvent) =>
                this.setState({ cardCvcComplete: event.complete })
              }
            />
          </section>
          <hr />
          {this.region() === 'default' && (
            <DefaultRegionSubscriptionTypeSelector
              onSubscriptionTypeChanged={(newType) => this.setState({ subscriptionType: newType })}
              onCouponIdChanged={(stripeCouponId: string) => this.setState({ stripeCouponId })}
              onPriceIdChanged={this.onStripePriceIdChanged}
            />
          )}
          {this.region() === 'us' && (
            <UsRegionSubscriptionTypeSelector
              onCouponIdChanged={(stripeCouponId: string) => this.setState({ stripeCouponId })}
              onPriceIdChanged={this.onStripePriceIdChanged}
            />
          )}
          <hr />
          <section>
            <label className="Horizontal">
              <input
                data-cy="termsAcceptedCheckbox"
                type="checkbox"
                name="termsAccepted"
                checked={this.state.termsAccepted}
                onChange={this.onCheckboxChanged('termsAccepted').bind(this)}
              />
              By signing up I agree to the&nbsp;
              <a rel="noopener noreferrer" target={'_blank'} href={termsOfUseLink}>
                Terms of Use
              </a>
              &nbsp;and&nbsp;
              <a rel="noopener noreferrer" target={'_blank'} href={privacyPolicyLink}>
                Privacy policy
              </a>
            </label>
          </section>
          {this.region() === 'default' && (
            <section>
              <label className="Horizontal">
                <input
                  data-cy="assureAllUsersAreVetsCheckbox"
                  type="checkbox"
                  name="assuredAllUsersAreVets"
                  checked={this.state.assuredAllUsersAreVets}
                  onChange={this.onCheckboxChanged('assuredAllUsersAreVets').bind(this)}
                />
                {this.state.subscriptionType === 'individual' && 'I hereby certify that I am a licenced veterinarian'}
                {this.state.subscriptionType === 'clinic' &&
                  'I hereby certify that all users using the account are licenced veterinarians'}
              </label>
            </section>
          )}
          {this.state.error && !this.state.userExists ? (
            <div className="validationErrorText">There was a problem signing you up. Please contact support.</div>
          ) : (
            <React.Fragment />
          )}
          {this.state.userExists ? (
            <div className="validationErrorText">
              Given user already exists, payment was not done. Please contact support.
            </div>
          ) : (
            <React.Fragment />
          )}
          <button data-cy="submitButton" disabled={!submitEnabled} className="OrderButton">
            Sign me up
          </button>
          {!submitEnabled && !this.state.loading && (
            <div className="validationErrorText">
              Please fill all required fields: {this.getMissingFieldNames().join(', ')}
            </div>
          )}
          {this.state.loading && <Loader />}
        </form>
      </div>
    );
  }
}

const initialState: SignUpFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  emailVerify: '',
  emailVerified: true,

  companyName: '',
  streetAddress: '',
  postalCode: '',
  city: '',
  country: { value: '', label: '' },
  state: { value: '', label: '' },
  vatId: '',

  sameBillingAddress: true,

  billingAddress: {
    streetAddress: '',
    postalCode: '',
    city: '',
    state: { value: '', label: '' },
  },

  cardholderName: '',

  cardNumberComplete: false,
  cardExpiryComplete: false,
  cardCvcComplete: false,

  subscriptionType: 'individual',
  stripeCouponId: '',

  termsAccepted: false,
  assuredAllUsersAreVets: false,

  success: false,
  loading: false,
  loadingPrice: false,
  userExists: false,
  stripePriceId: null,
  monthlyPrice: 0,
  error: false,
};
