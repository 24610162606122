import * as React from 'karet';
import * as U from 'karet.util';
import { TreatmentActivity } from './Activity/TreatmentActivity';
import { LaboratoryActivity } from './Activity/LaboratoryActivity';
import { CreateActivity } from '../../../Components/CreateActivity';
import { ToxinInfoMeta, TreatmentActivities } from '../../ToxinInfo/ToxinInfoMeta';
import { DropDownPane } from '../../../Components/DropDownPane';
import './TreatmentPlan.css';
import { flatMap, map } from 'lodash';
import { ClinicalSignList } from '../ClinicalSignList/ClinicalSignList';
import * as R from 'ramda';

const hasClinicalSigns = (substance) => {
  if (substance === null) {
    return false;
  }

  const additionalInfo = substance.additionalInfo;
  const clinicalSigns = additionalInfo && additionalInfo.clinicalSigns;
  const hasCategories = clinicalSigns && clinicalSigns.categories && clinicalSigns.categories.length > 0;

  const inheritedClinicalSigns = substance.inheritedClinicalSignsCategories;
  const hasInheritedCategories = inheritedClinicalSigns && inheritedClinicalSigns.length > 0;
  return hasCategories || hasInheritedCategories;
};

const timestampBlock = (substance) => {
  const pageCreated = new Date(substance.createdAt).toDateString();
  const itemTimestamps =
    substance.treatmentActivities && substance.treatmentActivities.length > 0
      ? flatMap(substance.treatmentActivities, ({ items }) => map(items, (item) => item.updatedAt))
      : [];
  const levelTimestamps =
    substance.toxicityLevels && substance.toxicityLevels.length > 0
      ? map(substance.toxicityLevels, (level) => level.updatedAt)
      : [];
  const pageUpdated = [substance.updatedAt];
  const sortedUpdates = pageUpdated
    .concat(itemTimestamps, levelTimestamps)
    .map((timestamp) => new Date(timestamp))
    .sort((a, b) => b - a);
  const lastUpdated = sortedUpdates[0].toDateString();

  return (
    <div className={'RightColumn'}>
      <div>Page created {pageCreated}</div>
      <div>Last reviewed {lastUpdated}</div>
    </div>
  );
};

const descriptionBlock = (substance) => {
  const synonymsToUse =
    substance.synonyms && substance.synonyms.length > 0 ? substance.synonyms : substance.inheritedSynonyms;
  const descriptionToUse =
    substance.description && substance.description.length > 0 ? substance.description : substance.inheritedDescription;
  const synonymsBlock =
    Array.isArray(synonymsToUse) && synonymsToUse.length > 0 ? (
      <div className={'DescriptionBlockSynonyms'}>
        <span className={'DescriptionBlockSynonymsTitle'}>Also known as</span>
        <span className={'DescriptionBlockSynonymsContent'}>{synonymsToUse.join(', ')}</span>
      </div>
    ) : (
      ''
    );
  const descriptionBlock =
    typeof descriptionToUse === 'string' && descriptionToUse.length > 0 ? (
      <div className={'DescriptionBlockDescription'}>{descriptionToUse}</div>
    ) : (
      ''
    );
  const rightColumn = timestampBlock(substance);
  return (
    <div className={'DescriptionBlockFlexWrapper'}>
      <div className={'LeftColumn'}>
        {synonymsBlock}
        {descriptionBlock}
      </div>
      {rightColumn}
    </div>
  );
};

const activityList = (toxinId, activities, inheritedActivities, adminUser, title) => {
  const inheritedVisible = U.mapValue((a) => R.isNil(a) || (!R.isNil(a) && a.length === 0), activities);
  return U.ifElse(
    inheritedVisible,
    <div key={title}>
      {U.mapElems((activity, index) => {
        return [
          <TreatmentActivity
            key={activity.get().name + index + '-inherited'}
            toxinId={toxinId}
            showHeading={true}
            showMarkers={false}
            activity={activity}
            activities={activities}
            adminUser={adminUser}
          />,
        ];
      }, inheritedActivities)}
    </div>,
    <div key={title}>
      {U.mapElems((activity, index) => {
        return [
          <TreatmentActivity
            key={activity.get().name + index + '-common'}
            toxinId={toxinId}
            showHeading={true}
            showMarkers={false}
            activity={activity}
            activities={activities}
            adminUser={adminUser}
          />,
        ];
      }, activities)}
    </div>,
  );
};

const laboratoryActivityList = (toxinId, activities, inheritedActivities, adminUser, title) => {
  const inheritedVisible = U.mapValue((a) => R.isNil(a) || (!R.isNil(a) && a.length === 0), activities);
  return U.ifElse(
    inheritedVisible,
    <div key={title}>
      {U.mapElems((activity, index) => {
        return [
          <LaboratoryActivity
            key={activity.get().name + index + '-inherited'}
            toxinId={toxinId}
            showHeading={true}
            showMarkers={false}
            activity={activity}
            activities={activities}
            adminUser={adminUser}
          />,
        ];
      }, inheritedActivities)}
    </div>,
    <div key={title}>
      {U.mapElems((activity, index) => {
        return [
          <LaboratoryActivity
            key={activity.get().name + index + '-common'}
            toxinId={toxinId}
            showHeading={true}
            showMarkers={false}
            activity={activity}
            activities={activities}
            adminUser={adminUser}
          />,
        ];
      }, activities)}
    </div>,
  );
};

const sectionVisible = (section, inherited, adminUser) => {
  return U.combine([section, inherited], (activities, inheritedActvities) => {
    if (adminUser) {
      return true;
    }
    return (activities && activities.length > 0) || (inheritedActvities && inheritedActvities.length > 0);
  });
};

export const TreatmentPlan = ({ selectedSubstance, adminUser, view, allOpen }) => {
  const toxinId = ToxinInfoMeta.id(selectedSubstance);
  const treatmentActivities = ToxinInfoMeta.treatmentActivities(selectedSubstance);
  const decontamination = TreatmentActivities.subCategory('Decontamination', view, treatmentActivities);
  const laboratory = TreatmentActivities.category('Diagnosis', ['Laboratory', 'History'], view, treatmentActivities);
  const treatment = TreatmentActivities.category('Treatment', ['SymptomaticCare', 'Drugs'], view, treatmentActivities);
  const monitor = TreatmentActivities.category(
    'Monitor',
    ['Laboratory', 'SupportiveCare', 'Other'],
    view,
    treatmentActivities,
  );
  const prognosis = TreatmentActivities.category('Prognosis', undefined, view, treatmentActivities);
  const pharmacology = TreatmentActivities.category('Pharmacology', undefined, view, treatmentActivities);
  const references = TreatmentActivities.category('References', undefined, view, treatmentActivities);

  const inheritedTreatmentActivities = ToxinInfoMeta.inheritedTreatmentActivities(selectedSubstance);
  const inheritedDecontamination = TreatmentActivities.subCategory(
    'Decontamination',
    view,
    inheritedTreatmentActivities,
  );
  const inheritedLaboratory = TreatmentActivities.category(
    'Diagnosis',
    ['Laboratory', 'History'],
    view,
    inheritedTreatmentActivities,
  );
  const inheritedTreatment = TreatmentActivities.category(
    'Treatment',
    ['SymptomaticCare', 'Drugs'],
    view,
    inheritedTreatmentActivities,
  );
  const inheritedMonitor = TreatmentActivities.category(
    'Monitor',
    ['Laboratory', 'SupportiveCare', 'Other'],
    view,
    inheritedTreatmentActivities,
  );
  const inheritedPrognosis = TreatmentActivities.category('Prognosis', undefined, view, inheritedTreatmentActivities);
  const inheritedPharmacology = TreatmentActivities.category(
    'Pharmacology',
    undefined,
    view,
    inheritedTreatmentActivities,
  );
  const inheritedReferences = TreatmentActivities.category('References', undefined, view, inheritedTreatmentActivities);

  const decontaminationVisible = sectionVisible(decontamination, inheritedDecontamination, adminUser);
  const laboratoryVisible = sectionVisible(laboratory, inheritedLaboratory, adminUser);
  const treatmentVisible = sectionVisible(treatment, inheritedTreatment, adminUser);
  const monitorVisible = sectionVisible(monitor, inheritedMonitor, adminUser);
  const prognosisVisible = sectionVisible(prognosis, inheritedPrognosis, adminUser);
  const pharmacologyVisible = sectionVisible(pharmacology, inheritedPharmacology, adminUser);
  const referencesVisible = sectionVisible(references, inheritedReferences, adminUser);
  return (
    <section className="TreatmentPlan">
      {U.mapValue((substance) => (substance ? descriptionBlock(substance) : ''), selectedSubstance)}
      {U.mapValue((substance) => {
        return hasClinicalSigns(substance) ? (
          <DropDownPane
            title={`Clinical signs associated with ${substance.name}`}
            visible={U.atom(true)}
            boxClassName="DropdownBox"
            paneClassName="DropdownPane"
          >
            <ClinicalSignList substance={substance} />
          </DropDownPane>
        ) : (
          ''
        );
      }, selectedSubstance)}
      {U.ifElse(
        decontaminationVisible,
        <DropDownPane
          title="Decontamination"
          visible={U.atom(true)}
          boxClassName="DropdownBox"
          paneClassName="DropdownPane"
        >
          {activityList(toxinId, decontamination, inheritedDecontamination, adminUser, 'Decontamination')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              categories={{ Treatment: ['Decontamination'] }}
              titles={{ Treatment: 'Create decontamination' }}
              visibility={view}
              activities={decontamination}
            />
          )}
        </DropDownPane>,
        '',
      )}
      {U.ifElse(
        laboratoryVisible,
        <DropDownPane title="Diagnostics" visible={allOpen} boxClassName="DropdownBox" paneClassName="DropdownPane">
          {laboratoryActivityList(toxinId, laboratory, inheritedLaboratory, adminUser, 'Diagnostics')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              visibility={view}
              categories={{ Diagnosis: ['Laboratory', 'History'] }}
              activities={treatmentActivities}
            />
          )}
        </DropDownPane>,
        '',
      )}
      {U.ifElse(
        treatmentVisible,
        <DropDownPane
          title="Symptomatic treatment"
          visible={allOpen}
          boxClassName="DropdownBox"
          paneClassName="DropdownPane"
        >
          {activityList(toxinId, treatment, inheritedTreatment, adminUser, 'Symptomatic treatment')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              categories={{ Treatment: ['SymptomaticCare', 'Drugs'] }}
              titles={{ Treatment: ['Create'] }}
              visibility={view}
              activities={treatmentActivities}
            />
          )}
        </DropDownPane>,
        '',
      )}
      {U.ifElse(
        monitorVisible,
        <DropDownPane title="Monitoring" visible={allOpen} boxClassName="DropdownBox" paneClassName="DropdownPane">
          {activityList(toxinId, monitor, inheritedMonitor, adminUser, 'Monitoring')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              categories={{ Monitor: ['Laboratory', 'SupportiveCare', 'Other'] }}
              visibility={view}
              activities={treatmentActivities}
            />
          )}
        </DropDownPane>,
        '',
      )}
      {U.ifElse(
        prognosisVisible,
        <DropDownPane title="Prognosis" visible={allOpen} boxClassName="DropdownBox" paneClassName="DropdownPane">
          {activityList(toxinId, prognosis, inheritedPrognosis, adminUser, 'Prognosis')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              categories={{ Prognosis: ['Other'] }}
              titles={{ Prognosis: 'Create prognosis' }}
              visibility={view}
              activities={treatmentActivities}
            />
          )}
        </DropDownPane>,
        '',
      )}
      {U.ifElse(
        pharmacologyVisible,
        <DropDownPane title="Pharmacology" visible={allOpen} boxClassName="DropdownBox" paneClassName="DropdownPane">
          {activityList(toxinId, pharmacology, inheritedPharmacology, adminUser, 'Pharmacology')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              categories={{ Pharmacology: ['Other'] }}
              titles={{ Pharmacology: 'Create' }}
              visibility={view}
              activities={treatmentActivities}
            />
          )}
        </DropDownPane>,
        '',
      )}
      {U.ifElse(
        referencesVisible,
        <DropDownPane title="References" visible={allOpen} boxClassName="DropdownBox" paneClassName="DropdownPane">
          {activityList(toxinId, references, inheritedReferences, adminUser, 'References')}
          {adminUser && (
            <CreateActivity
              toxinId={toxinId}
              categories={{ References: ['Other'] }}
              titles={{ References: 'Create' }}
              visibility={view}
              activities={treatmentActivities}
            />
          )}
        </DropDownPane>,
        '',
      )}
    </section>
  );
};
