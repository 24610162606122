export function getConfig() {
  return fetch('/api/config', {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    }
    throw response.status;
  });
}
