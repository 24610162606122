import React, { ChangeEvent, FC } from 'react';
import './SignUpForm.css';
import { getMonthlyPrice, getYearlyPrice } from '../../../services/pricing';
import { StripeCoupon, StripePrice, UsRegionSubscriptionType } from './types';
import { Loader } from '../../Components/Loader';
import { applyCoupon, formatMoney, freeTrialText } from './pricing';
import { CouponCode } from './CouponCode';

interface Props {
  onCouponIdChanged: (id: string) => void;
  onPriceIdChanged: (id: string) => void;
  showFreeTrialText?: boolean;
}

const addCouponDurationText = (coupon: StripeCoupon | null, isYearlySubscription = false) => {
  if (coupon?.duration === 'repeating' || coupon?.duration === 'once') {
    return isYearlySubscription ? 'for the first year ' : `for ${coupon.durationInMonths} months `;
  }
  return '';
};

export const UsRegionSubscriptionTypeSelector: FC<Props> = ({
  onCouponIdChanged,
  onPriceIdChanged,
  showFreeTrialText = false,
}) => {
  const [subscriptionType, setSubscriptionType] = React.useState<UsRegionSubscriptionType>('yearly');
  const [monthlyPrice, setMonthlyPrice] = React.useState<StripePrice | null>(null);
  const [yearlyPrice, setYearlyPrice] = React.useState<StripePrice | null>(null);
  const [loadingPrice, setLoadingPrice] = React.useState(false);
  const [coupon, setCoupon] = React.useState<StripeCoupon | null>(null);

  const subscriptionTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value as UsRegionSubscriptionType;
    setSubscriptionType(v);
    onPriceIdChanged(v === 'monthly' ? monthlyPrice?.id ?? '' : yearlyPrice?.id ?? '');
  };

  const onCouponChanged = (newCoupon: StripeCoupon | null) => {
    if (newCoupon !== null) {
      setCoupon(newCoupon);
      onCouponIdChanged(newCoupon.id);
    } else {
      setCoupon(null);
      onCouponIdChanged('');
    }
  };

  React.useEffect(() => {
    setLoadingPrice(true);
    Promise.all([getMonthlyPrice(), getYearlyPrice()]).then(([monthly, yearly]) => {
      if (!monthly || !yearly) {
        throw Error('Unexpected error fetching price');
      }
      setMonthlyPrice(monthly);
      setYearlyPrice(yearly);

      setLoadingPrice(false);
      onPriceIdChanged(yearly?.id);
    });
  }, [onPriceIdChanged]);

  return (
    <>
      {loadingPrice && <Loader />}
      {!loadingPrice && (
        <section>
          <label className="Horizontal us-subscription">

          <input
              type="radio"
              name="subscriptionType"
              value="yearly"
              onChange={subscriptionTypeChange}
              checked={subscriptionType === 'yearly'}
            />

            <div>
              <span>
                ${yearlyPrice && formatMoney(applyCoupon(yearlyPrice, coupon))}/year{' '}
                {addCouponDurationText(coupon, true)}(plus applicable taxes)
              </span>
              {coupon?.valid && (
                <span className="SubscriptionNormalPrice">
                  Normal price: ${formatMoney(yearlyPrice?.unitAmount ?? NaN)}/year
                </span>
              )}
            </div>
          </label>

          <label className="Horizontal us-subscription">
            <input
              type="radio"
              name="subscriptionType"
              value="monthly"
              onChange={subscriptionTypeChange}
              checked={subscriptionType === 'monthly'}
            />
            <div>
              <span>
                ${monthlyPrice && formatMoney(applyCoupon(monthlyPrice, coupon))}/month {addCouponDurationText(coupon)}
                (plus applicable taxes)
              </span>
              {coupon?.valid && (
                <span className="SubscriptionNormalPrice">
                  Normal price: ${formatMoney(monthlyPrice?.unitAmount ?? NaN)}/month
                </span>
              )}
            </div>
          </label>

          {showFreeTrialText && <span className="TrialPeriod"> {freeTrialText(30)}</span>}

          <CouponCode onCouponChanged={onCouponChanged} coupon={coupon} />

          <div className="SubscriptionTotal" />
        </section>
      )}
    </>
  );
};
