import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { ClinicalSignGrouping, ClinicalSignGroup } from '../SubstanceMeta';
import './ClinicalSignGroupingEditor.css';
import { EditableList } from '../../Components/EditableList';
import NameEdit from '../../Components/NameEdit';
import { ClinicalSignEditor } from '../clinicalsigneditor/ClinicalSignEditor';
import { ConfirmButton } from '../../Components/ConfirmButton';
import { EditorTextArea } from '../EditorComponents/EditorTextArea';

const onCreateCategory = (categories) => {
  const defaultCategory = categories.get().length === 0;
  categories.modify(R.append(ClinicalSignGroup.create({ defaultCategory })));
};

const ClinicalSignGroupingEditor = ({
  grouping,
  allClinicalSigns,
  disabled,
  inheritedGrouping,
  inheritedDescription,
  inheritedGroupingFrom,
  inheritedDescriptionFrom,
}) => {
  const showInheritedSigns = U.atom(false);
  const categories = ClinicalSignGrouping.categories(grouping);
  const description = ClinicalSignGrouping.description(grouping);

  const inheritedCategoriesVisible = U.combine(
    [categories, inheritedGrouping],
    (value, inherited) => value.length === 0 && inherited.length > 0,
  );
  const inheritedLink = U.mapValue(
    (visible) =>
      visible ? (
        <div className={'InheritedLink'} onClick={() => showInheritedSigns.set(!showInheritedSigns.get())}>
          Inherited Grouping
        </div>
      ) : (
        ''
      ),
    inheritedCategoriesVisible,
  );
  const inheritedContent = U.mapValue(
    (show) =>
      show ? (
        <div className={'InheritedContent'}>
          {U.mapElems(
            (category, categoryIndex) => (
              <div key={`category-wrapper-${categoryIndex}`}>
                <div className={'InheritedCategoryTitle'}>{category.get().name}</div>
                <ul>
                  {category.get().clinicalSigns.map((sign, signIndex) => (
                    <li key={`category-wrapper-item-${signIndex}`}>{sign}</li>
                  ))}
                </ul>
              </div>
            ),
            inheritedGrouping,
          )}
        </div>
      ) : (
        ''
      ),
    showInheritedSigns,
  );

  const inheritedFromContent = U.mapValue(
    (show) => (show ? <div className={'InheritedFrom'}>Inherited from: {inheritedGroupingFrom}</div> : ''),
    showInheritedSigns,
  );
  return (
    <div className="ClinicalSignGroupingEditor">
      <h2>Clinical signs description</h2>
      <EditorTextArea
        inheritedValue={inheritedDescription}
        inheritedTitle={'Inherited description'}
        currentValue={description}
        disabled={disabled}
        className={'ClinicalSignsDescriptionTextArea'}
        onChange={(value) => description.set(value)}
        inheritedFrom={inheritedDescriptionFrom}
      />
      <h2>Clinical signs grouping</h2>
      {inheritedLink}
      {inheritedContent}
      {inheritedFromContent}
      {U.mapElems((category, index) => {
        const name = ClinicalSignGroup.name(category);
        const clinicalSigns = ClinicalSignGroup.clinicalSigns(category);
        const showClinicalSignEditor = U.atom(false);

        return (
          <div className="Group" key={index}>
            <div className="Heading">
              <ConfirmButton
                className={'RemoveButton RoundButton'}
                disabled={disabled?.get()}
                onConfirm={() => category.remove()}
              />
              <NameEdit disabled={disabled} value={name} />
            </div>
            {U.mapValue(
              (signs) =>
                signs ? (
                  <EditableList
                    className="SignList"
                    items={clinicalSigns}
                    allowMove={true}
                    showCreateItem={showClinicalSignEditor}
                    disabled={disabled}
                    allItems={allClinicalSigns}
                  />
                ) : (
                  ''
                ),
              clinicalSigns,
            )}
            <ClinicalSignEditor
              open={showClinicalSignEditor}
              allClinicalSigns={allClinicalSigns}
              addTo={clinicalSigns}
            />
          </div>
        );
      }, categories)}
      <button
        key="CreateButton"
        className="CreateButton"
        disabled={disabled}
        onClick={() => onCreateCategory(categories)}
      >
        Create Group
      </button>
    </div>
  );
};

export default ClinicalSignGroupingEditor;
