import * as React from 'karet';
import * as U from 'karet.util';
import { withRouter } from 'react-router-dom';
import { isEqual } from 'underscore';
import SubstanceSelector from './SubstanceSelector';
import { SubstanceTree } from '../../../Components/SubstanceTree';
import { toxinService } from '../../../../services/toxin';
import { DoseSubmit } from './CaseDataFormMeta';
import { styleInvertedVisibleToggle } from '../../../../utils/ui';
import { findRootToxin } from '../../../../utils/toxin';
import { ModalFeedback } from '../../Feedback/ModalFeedback';
import * as analytics from '../../../../services/analytics';
import './CaseDataForm.css';
import { ConfirmButton } from '../../../Components/ConfirmButton';
import { SubstanceEditor } from '../../../SubstanceEditor/SubstanceEditor';
import { clinicalSignService } from '../../../../services/clinicalsign';
import { conditionService } from '../../../../services/condition';

const calculateDose = async (doseSubmit, substanceWithDose) => {
  if (DoseSubmit.canSubmit(doseSubmit)) {
    analytics.caseEdited(doseSubmit.weight, doseSubmit.name, doseSubmit.amount, doseSubmit.unit);
    substanceWithDose.set(await toxinService.getToxinDose(doseSubmit));
  }
};

const calculateDoseWithEnter = async (event, doseSubmit, substanceWithDose) => {
  if (event.keyCode === 13) {
    calculateDose(doseSubmit, substanceWithDose);
  }
};

const selectSubstance = async (id, name, substanceWithDose, selectedSubstance, doseSubmit, feedbackOpen) => {
  if (name === null) {
    substanceWithDose.set(null);
    selectedSubstance.set(null);
    doseSubmit.set(DoseSubmit.create());
  } else if (name === 'FEEDBACK') {
    feedbackOpen.set(true);
  } else {
    const substance = await toxinService.getToxinInfo(id);
    analytics.virtualPageView(substance.name, 'substance');
    selectedSubstance.set(substance);
    if (substance.children.length > 0) {
      substanceWithDose.set(null);
      doseSubmit.set(DoseSubmit.create());
    } else if (substanceWithDose.get()) {
      calculateDose(doseSubmit.get(), substanceWithDose);
    }
  }
};

const onSubstanceTreeChanged = (
  id,
  substances,
  selectedSubstance,
  substanceWithDose,
  doseSubmit,
  clinicalSigns,
  conditions,
) => {
  toxinService.getToxins().then((fetched) => {
    return toxinService.getToxinInfo(id).then((substance) => {
      substances.set(fetched);
      selectedSubstance.set(substance);
      if (substance.children.length > 0) {
        substanceWithDose.set(null);
      } else if (substanceWithDose.get()) {
        calculateDose(doseSubmit.get(), substanceWithDose);
      }
    });
  });
  clinicalSignService.getClinicalSigns().then((result) => clinicalSigns.set(result));
  conditionService.getConditions().then((result) => conditions.set(result));
};

const createToxin = (substances, selectedSubstance, substanceWithDose, doseSubmit, clinicalSigns, conditions) => {
  toxinService.createToxin().then((substance) => {
    onSubstanceTreeChanged(
      substance.id,
      substances,
      selectedSubstance,
      substanceWithDose,
      doseSubmit,
      clinicalSigns,
      conditions,
    );
  });
};

const removeToxin = (id, selectedSubstance, substances, substanceWithDose, doseSubmit) => {
  toxinService
    .removeToxin(id)
    .then(() => toxinService.getToxins())
    .then((fetched) => {
      selectedSubstance.set(null);
      substanceWithDose.set(null);
      doseSubmit.set(DoseSubmit.create());
      substances.set(fetched);
    });
};

const _CaseDataForm = ({
  adminUser,
  substanceWithDose,
  selectedSubstance,
  substances,
  conditions,
  clinicalSigns,
  history,
}) => {
  const doseSubmit = U.atom(DoseSubmit.create());
  const feedbackOpen = U.atom(false);
  const root = U.atom(null);

  const calculationDisabled = U.mapValue((substance) => substance && substance.children.length > 0, selectedSubstance);

  const submitId = DoseSubmit.id(doseSubmit);
  const submitName = DoseSubmit.name(doseSubmit);
  const weight = DoseSubmit.weight(doseSubmit);
  const amount = DoseSubmit.amount(doseSubmit);
  const unit = DoseSubmit.unit(doseSubmit);
  const hasChildren = DoseSubmit.hasChildren(doseSubmit);
  selectedSubstance.skipDuplicates(isEqual).observe((substance) => {
    if (substance) {
      if (substance.name !== submitName.get()) {
        submitId.set(substance.id);
        submitName.set(substance.name);
        unit.set(substance.unit);
        hasChildren.set(substance.children.length > 0 && !adminUser);
      }
    }
  });

  doseSubmit.debounce(500).observe((value) => {
    if (DoseSubmit.canSubmit(value) && substanceWithDose.get()) {
      calculateDose(doseSubmit.get(), substanceWithDose);
    } else {
      substanceWithDose.set(null);
    }
  });

  selectedSubstance.merge(substances).observe(() => {
    const substance = selectedSubstance.get();
    const rootSubstance = findRootToxin(substances.get(), substance ? substance.name : null);
    root.set([substance, rootSubstance]);
  });

  return (
    <section className="CaseDataForm">
      <label htmlFor="substance">Substance</label>
      {U.mapValue(
        (value) => (
          <div className={'SelectorWrapper'}>
            <SubstanceSelector
              className="SubstanceSelector"
              value={value ? { value: value.id, label: value.name } : null}
              showHidden={adminUser}
              feedback={true}
              onChange={(option, searchTerm) => {
                if (option) {
                  analytics.substanceSelected(option.label, searchTerm);
                }
                return option
                  ? option.value === 'FEEDBACK'
                    ? history.push('/feedback')
                    : selectSubstance(
                        option.value,
                        option.label,
                        substanceWithDose,
                        selectedSubstance,
                        doseSubmit,
                        feedbackOpen,
                      )
                  : selectSubstance(null, null, substanceWithDose, selectedSubstance, doseSubmit, feedbackOpen);
              }}
              sendAnalytics={true}
            />
            <img className={'SearchIcon'} src="/images/icons/ic_search.svg" width={32} alt="search" />
          </div>
        ),
        selectedSubstance,
      )}
      {U.mapValue(([substance, rootSubstance]) => {
        return rootSubstance && rootSubstance.children.length > 0 ? (
          <SubstanceTree
            className="SubstanceTree"
            root={rootSubstance}
            selected={substance.name}
            onSelect={(substance) =>
              selectSubstance(
                substance.id,
                substance.name,
                substanceWithDose,
                selectedSubstance,
                doseSubmit,
                feedbackOpen,
              )
            }
            allClickable={adminUser}
          />
        ) : (
          ''
        );
      }, root)}
      <div className={'RowFlexWrapper'}>
        <div className={'FormRowWrapper'} disabled={calculationDisabled}>
          <div className={'CaseDataFormRow'}>
            <img src="/images/icons/ic_substance.svg" width={32} alt="substance" />
            <span>Ingested amount</span>
            <input
              id="amount"
              type="number"
              min="0"
              step="0.01"
              disabled={calculationDisabled}
              value={amount}
              onChange={(evt) => amount.set(evt.target.value)}
              onKeyDown={(event) => calculateDoseWithEnter(event, doseSubmit.get(), substanceWithDose)}
            />
            <span className="amountUnit">{unit}</span>
          </div>
          <div className={'CaseDataFormRow'}>
            <img src="/images/icons/ic_dog.svg" width={32} alt="dog" />
            <span>Dog weight</span>
            <input
              id="weight"
              type="number"
              min="1"
              disabled={calculationDisabled}
              value={weight}
              onChange={(evt) => weight.set(evt.target.value)}
              onKeyDown={(event) => calculateDoseWithEnter(event, doseSubmit.get(), substanceWithDose)}
            />
            <span className="weightUnit">kg</span>
          </div>
        </div>
        {U.mapValue(
          (value) =>
            !value ? (
              <div className={'Disclaimer'}>
                <div className={'Oval'}>
                  <img src="/images/icons/icon-remark-blue.svg" height={32} alt="remark" />
                </div>
                All calculations are for healthy, adult dogs.
              </div>
            ) : (
              <div />
            ),
          selectedSubstance,
        )}
        {U.mapValue(
          (value) =>
            value && value.children.length > 0 ? (
              <div className={'Disclaimer'}>
                <div className={'Oval'}>
                  <img src="/images/icons/icon-remark-blue.svg" height={32} alt="remark" />
                </div>
                Choose a more specific substance.
              </div>
            ) : (
              <div />
            ),
          selectedSubstance,
        )}
      </div>
      <div className="ButtonContainer">
        <button
          data-cy="calculateDoseButton"
          className="CalculateButton"
          disabled={U.mapValue((doseSubmit) => !DoseSubmit.canSubmit(doseSubmit), doseSubmit)}
          style={styleInvertedVisibleToggle(substanceWithDose)}
          onClick={() => calculateDose(doseSubmit.get(), substanceWithDose)}
        >
          Calculate
        </button>
        <div data-cy="editToxinButton" className="EditToxinButton">
          {U.mapValue((substance) => {
            return adminUser && substance ? (
              <SubstanceEditor
                selectedSubstance={selectedSubstance}
                substances={substances.get()}
                onClose={(id) =>
                  onSubstanceTreeChanged(
                    id,
                    substances,
                    selectedSubstance,
                    substanceWithDose,
                    doseSubmit,
                    clinicalSigns,
                    conditions,
                  )
                }
                clinicalSigns={clinicalSigns}
                conditions={conditions}
              />
            ) : (
              ''
            );
          }, selectedSubstance)}
        </div>
        {adminUser ? (
          <button
            data-cy="createToxinButton"
            className="CreateToxinButton"
            onClick={() =>
              createToxin(substances, selectedSubstance, substanceWithDose, doseSubmit, clinicalSigns, conditions)
            }
          >
            Create
          </button>
        ) : (
          ''
        )}
        {U.mapValue(
          (substance) =>
            adminUser && substance ? (
              <ConfirmButton
                className={'RemoveButton'}
                text={'Remove'}
                confirmText={'Confirm'}
                onConfirm={() =>
                  removeToxin(substance.id, selectedSubstance, substances, substanceWithDose, doseSubmit)
                }
              />
            ) : (
              ''
            ),
          selectedSubstance,
        )}
        <ModalFeedback feedbackOpen={feedbackOpen} />
      </div>
    </section>
  );
};

export const CaseDataForm = withRouter(_CaseDataForm);
