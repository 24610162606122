import * as U from 'karet.util';
import * as R from 'ramda';

export const ToxicityLevel = {
  create: ({ description = '', threshold = 0, category = 'NonToxic', conditions = [] } = {}) => ({
    description,
    threshold,
    category,
    conditions,
  }),
  description: U.view('description'),
  threshold: U.view('threshold'),
  category: U.view('category'),
  conditions: U.view('conditions'),
};

export const ToxicityLevels = {
  levels: R.identity,
  addLevel: R.pipe(ToxicityLevel.create, R.append),
};
