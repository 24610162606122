import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { Modal } from '../../Components/Modal';
import { Condition } from './ConditionMeta';
import { conditionService } from '../../../services/condition';
import './ConditionEditor.css';

const onCreateCondition = (open, condition, allConditions, addTo) => {
  const data = condition.get();
  conditionService.createCondition(data.name, data.description, data.clinicalSigns).then(() => {
    allConditions.modify(R.append(data));
    if (addTo) {
      addTo.modify(R.append(data.name));
    }
    open.set(false);
  });
};

const onUpdateCondition = (open, condition, allConditions) => {
  const data = condition.get();
  conditionService.updateCondition(data.name, data.description, data.clinicalSigns).then(() => {
    const index = allConditions.get().findIndex((condition) => condition.name === data.name);
    allConditions.modify(R.update(index, data));
    open.set(false);
  });
};

export const ConditionEditor = ({ existingCondition, open, allConditions, addTo }) => {
  const condition = existingCondition ? existingCondition : U.atom(Condition.create());
  const name = Condition.name(condition);
  const description = Condition.description(condition);

  return (
    <Modal open={open}>
      <div className="ConditionEditor">
        <h2>{U.ifElse(existingCondition, 'Update condition', 'Create condition')}</h2>
        <label>Name</label>
        {existingCondition ? (
          <div className="ConditionName">{name}</div>
        ) : (
          <input className="ConditionName" type="text" value={name} onChange={(evt) => name.set(evt.target.value)} />
        )}
        <label>Description</label>
        <textarea cols="60" rows="10" value={description} onChange={(evt) => description.set(evt.target.value)} />
        <button className="CancelButton" onClick={() => open.set(false)}>
          Cancel
        </button>
        <button
          className="CreateConditionButton"
          onClick={() => {
            existingCondition
              ? onUpdateCondition(open, condition, allConditions)
              : onCreateCondition(open, condition, allConditions, addTo);
          }}
        >
          {existingCondition ? 'Update' : 'Create'}
        </button>
      </div>
    </Modal>
  );
};
