import * as React from 'karet';
import * as U from 'karet.util';
import { FaCircle } from 'react-icons/fa';
import { Checklist } from '../../../../Components/Checklist';
import { ActivityEditor } from '../../../../SubstanceEditor/activityeditor/ActivityEditor';
import { Activity, Item } from '../../../../SubstanceEditor/activityeditor/ActivityMeta';
import { styleVisibleToggle, isNonEmptyString } from '../../../../../utils/ui';
import './TreatmentActivity.css';

export const ActivityDescription = ({ description, showMarkers }) => {
  const visibleStyle = styleVisibleToggle(
    U.mapValue((value) => isNonEmptyString(value), description),
    'grid',
  );
  return showMarkers ? (
    <div key={description} className="ActivityDescriptionContainer" style={visibleStyle}>
      <FaCircle className="ActivityDescriptionMarker" />
      <span className="ActivityDescription">{description}</span>
    </div>
  ) : (
    <span className="ActivityDescription" style={visibleStyle}>
      {description}
    </span>
  );
};

export const ItemRemark = ({ remark, showMarkers }) => {
  const visibleStyle = styleVisibleToggle(
    U.mapValue((value) => isNonEmptyString(value), remark),
    'grid',
  );
  return showMarkers ? (
    <div className="Remark" style={visibleStyle}>
      <img src="images/icons/icon_remark.svg" alt="remark icon" />
      <span className="preserve-line-breaks">{remark}</span>
    </div>
  ) : (
    <span className="preserve-line-breaks" style={visibleStyle}>
      {remark}
    </span>
  );
};

const ActivityItem = ({ item }) => {
  const name = Item.name(item);
  const remark = Item.remark(item);
  const description = Item.description(item);
  const checklist = Item.checklist(item);
  const nameAsHeading = Item.nameAsHeading(item);
  const showSeparator = U.combine(
    [name, nameAsHeading],
    (name, nameAsHeading) => name && name.length > 0 && nameAsHeading,
  );
  return (
    <div className="ActivityItem">
      <h3>{name}</h3>
      {U.mapValue((show) => (show ? <div className="Separator" /> : ''), showSeparator)}
      <div>{description}</div>
      <ItemRemark remark={remark} showMarkers={true} />
      <Checklist list={checklist} />
    </div>
  );
};

export const TreatmentActivity = ({ toxinId, activity, activities, showHeading, showMarkers, adminUser }) => {
  const name = Activity.name(activity);
  const items = Activity.items(activity);
  const description = Activity.description(activity);
  const subCategory = Activity.subCategory(activity);
  const adminClassName = adminUser ? 'AdminItem' : '';
  return (
    <div key={`TreatmentActivity-${name}`} className={`TreatmentActivity ${subCategory.get()} ${adminClassName}`}>
      {U.mapValue(
        (nameValue) =>
          isNonEmptyString(nameValue) && showHeading ? <h2 className="ActivityName">{nameValue}</h2> : '',
        name,
      )}
      {U.mapValue(
        (descriptionValue) =>
          isNonEmptyString(descriptionValue) ? (
            <ActivityDescription description={description} showMarkers={showMarkers} />
          ) : (
            ''
          ),
        description,
      )}
      {U.mapElems(
        (item, index) => (
          <ActivityItem key={Item.name(item).get() + '-' + index} item={item} index={index} />
        ),
        items,
      )}
      {adminUser && <ActivityEditor toxinId={toxinId} activity={activity} activities={activities} />}
    </div>
  );
};
