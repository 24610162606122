import React, { useState } from 'react';
import { NewPaymentMethodForm } from './NewPaymentMethodForm';

const brands = {
  amex: 'American Express',
  diners: 'Diners Club',
  discover: 'Discover',
  jcb: 'JCB',
  mastercard: 'MasterCard',
  unionpay: 'UnionPay',
  visa: 'Visa',
};

function getExpirationWarning(expirationDate) {
  const now = new Date();
  const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
  const startOfNextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  if (startOfMonth.getTime() >= expirationDate.getTime()) {
    return 'EXPIRED';
  } else if (startOfNextMonth.getTime() >= expirationDate.getTime()) {
    return 'Expires soon';
  }
  return null;
}

const PaymentMethod = ({ billingDetails, onUpdate, onStartEditing }) => {
  const [isEditing, setIsEditing] = useState(false);
  const { card, email } = billingDetails;
  const brand = brands[card.brand];
  const expirationWarning = getExpirationWarning(new Date(card.exp_year, card.exp_month));
  if (isEditing) {
    return <NewPaymentMethodForm email={email} onUpdate={onUpdate} onCancel={() => setIsEditing(false)} />;
  }
  return (
    <>
      <dl>
        <dt>Credit card</dt>
        <dd>
          {brand && <span className="Brand">{brand}</span>}
          {` •••• •••• •••• ${card.last4}`}
        </dd>
        <dt>Cardholder name</dt>
        <dd>{billingDetails.cardholderName}</dd>
        <dt>Expiration date</dt>
        <dd>
          {card.exp_month < 10 ? '0' : ''}
          {card.exp_month}/{card.exp_year}
          {expirationWarning && <span className="ExpiresSoon">{expirationWarning}</span>}
        </dd>
      </dl>
      <button
        onClick={() => {
          onStartEditing();
          setIsEditing(true);
        }}
      >
        Add new payment method
      </button>
    </>
  );
};

export default PaymentMethod;
