import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import { map, keys } from 'underscore';
import { activityService } from '../../services/activity';
import './CreateActivity.css';

function createActivity(toxinId, category, subCategory, visibility, activities) {
  activityService.createActivity({ toxinId, category, subCategory, visibility }).then((activity) => {
    activities.modify(R.append(activity));
  });
}

const SingleCategory = ({ className, toxinId, category, subCategories, visibility, activities, title }) => {
  const selectedCategory = U.atom(subCategories[0]);
  return (
    <div className={className}>
      {subCategories.length > 1 && (
        <select onChange={(evt) => selectedCategory.set(evt.target.value)}>
          {subCategories.map((category) => (
            <option value={category} key={category}>
              {category}
            </option>
          ))}
        </select>
      )}
      <button onClick={() => createActivity(toxinId.get(), category, selectedCategory.get(), visibility, activities)}>
        {title}
      </button>
    </div>
  );
};

export const CreateActivity = ({ toxinId, categories, titles, visibility, activities }) => {
  return (
    <div className={keys(categories).length > 1 ? 'CreateActivityMulti' : 'CreateActivity'}>
      {map(categories, (values, key) => (
        <SingleCategory
          title={titles ? titles[key] : 'Create'}
          className="DropDown"
          toxinId={toxinId}
          category={key}
          visibility={visibility}
          key={key}
          subCategories={values}
          activities={activities}
        />
      ))}
    </div>
  );
};
