import { handleJsonResponse } from './common';
import { RegionId } from '../../../backend/config/types';

function getFreeUsers(region: RegionId) {
  return fetch(`/api/users/free/list?region=${region}`, {
    method: 'get',
    credentials: 'include',
  }).then(handleJsonResponse);
}

function getUsers() {
  return fetch('/api/users/list', {
    method: 'get',
    credentials: 'include',
  }).then(handleJsonResponse);
}

function createUser(email: string, role: string, region: RegionId) {
  return fetch('/api/users', {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, role, region }),
  }).then(handleJsonResponse);
}

function updateUser(userData: { id: string }) {
  return fetch(`/api/users/${userData.id}`, {
    method: 'put',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  }).then(handleJsonResponse);
}

function changeEmail(email: string) {
  return fetch('/api/users/email', {
    method: 'put',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  }).then(handleJsonResponse);
}

function changePassword(password: string) {
  return fetch('/api/users/newpassword', {
    method: 'put',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ password }),
  }).then(handleJsonResponse);
}

function removeUser(id: string) {
  return fetch(`/api/users/${id}`, {
    method: 'delete',
    credentials: 'include',
  }).then(handleJsonResponse);
}

function postPasswordResetRequest(email: string, isWelcome = false, region?: RegionId) {
  return fetch('/api/users/passwordreset', {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, isWelcome, region }),
  }).then(handleJsonResponse);
}

function sendWelcome(email: string, region: RegionId) {
  return postPasswordResetRequest(email, true, region);
}

function sendPasswordReset(email: string) {
  return postPasswordResetRequest(email);
}

function resetPassword(email: string, newPassword: string, hash: string) {
  return fetch('/api/users/password', {
    method: 'put',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, newPassword, passwordReset: hash }),
  }).then(handleJsonResponse);
}

export const user = {
  getUsers,
  getFreeUsers,
  createUser,
  updateUser,
  changeEmail,
  changePassword,
  removeUser,
  sendWelcome,
  sendPasswordReset,
  resetPassword,
};
