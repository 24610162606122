import * as U from 'karet.util';
import * as L from 'partial.lenses';

export const User = {
  create: ({ email = 'na@na.com', role = 'EndUser', firstName = 'John', lastName = 'Doe' } = {}) => ({
    email,
    role,
    firstName,
    lastName,
  }),
  id: U.view('id'),
  email: U.view('email'),
  firstName: U.view('firstName'),
  lastName: U.view('lastName'),
  region: U.view('region'),
  role: U.view('role'),
  accountType: U.view([L.prop('accountType'), L.valueOr('FREE')]),
  accountId: U.view([L.prop('accountId')]),
  stripeId: U.view([L.prop('stripeId'), L.valueOr('')]),
  subscriptionStatus: U.view([L.prop('subscriptionStatus'), L.valueOr('ACTIVE')]),
};
