import { handleJsonResponse } from './common';

function updateActivity({ toxinId, activity }) {
  return fetch(`/api/treatment/activity/${activity.id}/${toxinId}`, {
    method: 'PUT',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(activity),
  }).then(handleJsonResponse);
}

function createActivity({ toxinId, category, subCategory, visibility }) {
  return fetch(`/api/treatment/activity`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ toxinId, category, subCategory, visibility, generic: false }),
  }).then(handleJsonResponse);
}

function deleteActivity({ toxinId, activityId }) {
  return fetch(`/api/treatment/activity/${activityId}/${toxinId}`, {
    method: 'DELETE',
    credentials: 'include',
  }).then(handleJsonResponse);
}

export const activityService = {
  updateActivity,
  createActivity,
  deleteActivity,
};
