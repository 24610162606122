import * as React from 'karet';
import * as U from 'karet.util';
import { DropDownPane } from '../../Components/DropDownPane';
import { ConditionEditor } from '../conditioneditor/ConditionEditor';
import { EditableList } from '../../Components/EditableList';
import { ToxicityLevel } from './ToxicityLevelMeta';
import './ToxicityLevels.css';
import './SignConditionEditor.css';
import * as R from 'ramda';
import { ConfirmButton } from '../../Components/ConfirmButton';

const ToxicityLevelControl = ({ level, allConditions, allClinicalSigns, disabled }) => {
  const description = ToxicityLevel.description(level);
  const threshold = ToxicityLevel.threshold(level);
  const category = ToxicityLevel.category(level);
  const conditions = ToxicityLevel.conditions(level);
  const conditionsTitle = U.mapValue((value) => `Conditions(${value})`, U.view('length', conditions));

  const showConditionEditor = U.atom(false);
  const showConditionUpdateEditor = U.atom(false);
  const selectedConditionName = U.atom(null);
  const selectedCondition = U.atom(null);
  const selectedIndex = U.atom(null);
  selectedConditionName.observe((name) => {
    if (name && name !== '') {
      const conditions = allConditions.get();
      const index = conditions.findIndex((condition) => condition.name === name);
      selectedIndex.set(index);
      selectedCondition.set(conditions[index]);
    }
  });

  return [
    <select
      key="category"
      disabled={disabled.get()}
      value={category}
      onChange={(evt) => category.set(evt.target.value)}
    >
      <option value="NonToxic">Non-toxic</option>
      <option value="Mild">Mild</option>
      <option value="Moderate">Moderate</option>
      <option value="High">High</option>
      <option value="Lethal">Lethal</option>
      <option value="Unknown">Toxic dose unknown</option>
      <option value="CaseSpecific">Case-specific</option>
    </select>,
    <input
      key="threshold"
      type="number"
      value={threshold}
      disabled={disabled.get()}
      onChange={(evt) => threshold.set(evt.target.value)}
    />,
    <div key="descriptionSigns" className="DescriptionSigns">
      <textarea
        rows={5}
        disabled={disabled.get()}
        value={description}
        onChange={(evt) => description.set(evt.target.value)}
      />
      {U.mapValue(
        (disabled) =>
          !disabled ? (
            <div className="SignConditionEditor">
              <DropDownPane
                title={conditionsTitle}
                disabled={disabled}
                visible={U.atom(false)}
                boxClassName="DropdownBox"
                paneClassName="DropdownPane"
              >
                <EditableList
                  className="List"
                  showCreateItem={showConditionEditor}
                  showEditItem={showConditionUpdateEditor}
                  items={conditions}
                  selected={selectedConditionName}
                  allItems={allConditions}
                />
              </DropDownPane>
              <ConditionEditor
                open={showConditionEditor}
                allClinicalSigns={allClinicalSigns}
                allConditions={allConditions}
                addTo={conditions}
              />
              {U.mapValue((showEditor) => {
                return showEditor ? (
                  <ConditionEditor
                    open={showConditionUpdateEditor}
                    existingCondition={selectedCondition}
                    allClinicalSigns={allClinicalSigns}
                    allConditions={allConditions}
                    addTo={conditions}
                  />
                ) : (
                  ''
                );
              }, showConditionUpdateEditor)}
            </div>
          ) : (
            ''
          ),
        disabled,
      )}
    </div>,
    <ConfirmButton
      key={'removeLevel'}
      className={'RemoveButton RoundButton'}
      disabled={disabled.get()}
      onConfirm={() => level.remove()}
    />,
  ];
};

const StaticToxicityLevel = ({ level }) => {
  const description = ToxicityLevel.description(level);
  const threshold = ToxicityLevel.threshold(level);
  const category = ToxicityLevel.category(level);
  const conditions = ToxicityLevel.conditions(level);
  const categoryDiv = U.mapValue(
    (text) => (text ? <div className={'StaticToxicityLevelCategory'}>{text}</div> : ''),
    category,
  );
  const thresholdDiv = U.mapValue(
    (text) => (text ? <div className={'StaticToxicityLevelThreshold'}>{text}</div> : ''),
    threshold,
  );
  const descriptionDiv = U.mapValue(
    (text) => (text ? <div className={'StaticToxicityLevelDescription'}>{text}</div> : ''),
    description,
  );
  const conditionsTitleDiv = U.mapValue(
    (items) =>
      items && items.length > 0 ? <div className={'StaticToxicityLevelConditionsTitle'}>Conditions:</div> : '',
    conditions,
  );
  const conditionsDiv = U.mapValue(
    (items) => (items ? <div className={'StaticToxicityLevelConditions'}>{items.join(', ')}</div> : ''),
    conditions,
  );
  return (
    <div className={'StaticToxicityLevelWrapper'}>
      {categoryDiv}
      {thresholdDiv}
      {descriptionDiv}
      {conditionsTitleDiv}
      {conditionsDiv}
    </div>
  );
};

export const ToxicityLevelEditor = ({
  toxicityLevels,
  allConditions,
  allClinicalSigns,
  disabled,
  inheritedToxicityLevels,
  inheritedFrom,
}) => {
  const showInherited = U.atom(false);
  const inheritedVisible = U.combine(
    [toxicityLevels, inheritedToxicityLevels],
    (value, inherited) => value.length === 0 && inherited.length > 0,
  );
  const inheritedLink = U.mapValue(
    (visible) =>
      visible ? (
        <div className={'InheritedLink'} onClick={() => showInherited.set(!showInherited.get())}>
          Inherited Toxicity Levels
        </div>
      ) : (
        ''
      ),
    inheritedVisible,
  );
  const inheritedLevels = U.mapElems(
    (level, index) => <StaticToxicityLevel level={level} key={`StaticToxicityLevelWrapper-${index}`} />,
    inheritedToxicityLevels,
  );
  const inheritedContent = U.mapValue(
    (show) => (show ? <div className={'InheritedContent'}>{inheritedLevels}</div> : ''),
    showInherited,
  );
  const inheritedFromContent = U.mapValue(
    (show) => (show ? <div className={'InheritedFrom'}>Inherited from: {inheritedFrom}</div> : ''),
    showInherited,
  );
  return (
    <div className="ToxicityLevelsWrapper">
      {inheritedLink}
      {inheritedContent}
      {inheritedFromContent}
      <div className="ToxicityLevels">
        {U.mapElems(
          (item, index) => (
            <ToxicityLevelControl
              key={`level${index}`}
              level={item}
              disabled={disabled}
              allConditions={allConditions}
              allClinicalSigns={allClinicalSigns}
            />
          ),
          toxicityLevels,
        )}
        <button
          className="CreateButton"
          disabled={disabled.get()}
          onClick={() => toxicityLevels.modify(R.append(ToxicityLevel.create()))}
        >
          Create level
        </button>
      </div>
    </div>
  );
};
