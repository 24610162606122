import * as React from 'karet';
import * as U from 'karet.util';
import { Checklist } from '../../../../Components/Checklist';
import { ActivityDescription, ItemRemark } from './TreatmentActivity';
import { ActivityEditor } from '../../../../SubstanceEditor/activityeditor/ActivityEditor';
import { Activity, Item } from '../../../../SubstanceEditor/activityeditor/ActivityMeta';
import './LaboratoryActivity.css';
import { isNonEmptyString } from '../../../../../utils/ui';

const ActivityItem = ({ item, showMarkers }) => {
  const name = Item.name(item);
  const checklist = Item.checklist(item);
  const remark = Item.remark(item);
  const description = Item.description(item);
  const nameAsHeading = Item.nameAsHeading(item);
  const showSeparator = U.combine(
    [name, nameAsHeading],
    (name, nameAsHeading) => name && name.length > 0 && nameAsHeading,
  );
  return (
    <div key={name} className="ActivityItem">
      <h3>{name}</h3>
      {U.mapValue((show) => (show ? <div className="Separator" /> : ''), showSeparator)}
      <div className="Description">
        {U.mapValue(
          (descriptionValue) =>
            descriptionValue.length > 0
              ? [
                  <div key="descValue1" className="Text">
                    {description}
                  </div>,
                  <div key="descValue2" />,
                ]
              : '',
          description,
        )}
        <Checklist list={checklist} />
        <ItemRemark remark={remark} showMarkers={showMarkers} />
      </div>
    </div>
  );
};

export const LaboratoryActivity = ({ toxinId, activity, activities, showHeading, showMarkers, adminUser }) => {
  const name = Activity.name(activity);
  const items = Activity.items(activity);
  const description = Activity.description(activity);
  const adminClassName = adminUser ? 'AdminItem' : '';
  return (
    <div key={`LaboratoryActivity-${name}`} className={`LaboratoryActivity ${adminClassName}`}>
      {U.mapValue(
        (nameValue) =>
          isNonEmptyString(nameValue) && showHeading ? <h2 className="ActivityName">{nameValue}</h2> : '',
        name,
      )}
      {U.mapValue(
        (descriptionValue) =>
          isNonEmptyString(descriptionValue) ? (
            <ActivityDescription description={description} showMarkers={showMarkers} />
          ) : (
            ''
          ),
        description,
      )}
      {U.mapElems(
        (item) => (
          <ActivityItem key={Item.name(item).get()} item={item} showMarkers={showMarkers} />
        ),
        items,
      )}
      {adminUser && <ActivityEditor toxinId={toxinId} activity={activity} activities={activities} />}
    </div>
  );
};
