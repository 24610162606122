let analyticsEnabled = true;

function dataLayerEvent(event, payload) {
  if (!analyticsEnabled) {
    return;
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    ...payload,
    event,
  });
}

export function caseEdited(weight, substance, amount, amountUnit) {
  dataLayerEvent('editCase', {
    animalType: 'dog',
    weight,
    weightUnit: 'kg',
    substance,
    amount,
    amountUnit,
  });
}

export function siteSearch(searchTerm) {
  dataLayerEvent('siteSearch', { searchTerm });
}

export function substanceSelected(substance, searchTerm) {
  dataLayerEvent('substanceSelected', { substance, searchTerm });
}

function setAnalyticsEnablement(loggedInUser) {
  if (loggedInUser && loggedInUser.email) {
    const userEmailDomain = loggedInUser.email.split('@')[1].trim();
    const analyticsDisabledUserDomains = [
      'healthwareinternational.com',
      'reaktor.com',
      'reaktor.fi',
      'orionpharma.com',
      'orion.fi',
      'vetoquinol.com',
    ];
    analyticsEnabled = !analyticsDisabledUserDomains.includes(userEmailDomain);
  } else {
    analyticsEnabled = true;
  }
  if (!analyticsEnabled) {
    console.log(`Google Analytics reporting disabled for user ${loggedInUser.email}`);
  }
}

export function initialize(initialUser) {
  setAnalyticsEnablement(initialUser);
}

export function login(email) {
  setAnalyticsEnablement({ email });
  dataLayerEvent('userLogin', {});
}

export function logout() {
  dataLayerEvent('userLogout', {});
  analyticsEnabled = true;
}

function slugify(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^-\w]+/g, '')
    .replace(/--+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

export function virtualPageView(pageName, baseUrl = '') {
  dataLayerEvent('virtualPageview', {
    pageName,
    pageUrl: [baseUrl, slugify(pageName)].join('/'),
  });
}

export function sectionOpened(section) {
  dataLayerEvent('dropdownClicked', { sectionName: section });
}

export function sectionOpenedAutomatic(section) {
  dataLayerEvent('dropdownOpened', { sectionName: section });
}

export function error(errorMsg) {
  dataLayerEvent('displayError', { errorMessage: errorMsg });
}
