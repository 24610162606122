import * as U from 'karet.util';
import * as R from 'ramda';
import * as React from 'karet';
import { FaAngleUp } from 'react-icons/fa';
import TextEdit from './TextEdit';
import { ConfirmButton } from './ConfirmButton';

const moveItem = (newIndex, item, items) => {
  const text = item.get();
  item.remove();
  items.modify(R.insert(Math.max(0, newIndex), text));
};

const renderItem = (allowEdit, allowMove, index, item, items, disabled) => {
  return [
    <ConfirmButton
      className={'RemoveButton RoundButton'}
      key="remove"
      onConfirm={() => item.remove()}
      disabled={disabled?.get()}
    />,
    allowEdit ? (
      <TextEdit editClassName="TextEdit" disabled={disabled} key={index} value={item} rows={3} />
    ) : (
      <span className="StaticText" key={index}>
        {item}
      </span>
    ),
    allowEdit || allowMove ? (
      <button
        className="MoveButton RoundButton"
        disabled={disabled}
        onClick={() => moveItem(index - 1, item, items)}
        key="up"
      >
        <FaAngleUp />
      </button>
    ) : (
      ''
    ),
  ];
};

const onChange = (selectedItem, evt) => {
  selectedItem.set(evt.target.value);
};

const itemToOption = (item) => {
  const name = item.name || item;
  return (
    <option key={name} value={name}>
      {name}
    </option>
  );
};

const hasItems = (items) => items && items.get() && items.get(0).length > 0;

export const EditableList = ({
  className,
  selected,
  disabled,
  allowMove,
  showCreateItem,
  showEditItem,
  items,
  allItems,
}) => {
  const firstItem = hasItems(allItems) ? allItems.get()[0] : '';
  const selectedItem = selected ? selected : U.atom();
  selectedItem.set(firstItem.name || firstItem);
  return (
    <div className={className}>
      {U.mapElems((item, index) => renderItem(!allItems, allowMove, index, item, items, disabled), items)}
      <button
        className="AddButton RoundButton"
        disabled={disabled}
        onClick={() => {
          items.modify(R.append(selectedItem.get()));
          selectedItem.set('');
        }}
        key="add"
      >
        +
      </button>
      {allItems ? (
        <select onChange={R.curry(onChange)(selectedItem)} disabled={disabled}>
          {U.mapElems((item) => itemToOption(item.get()), allItems)}
        </select>
      ) : (
        <input onChange={R.curry(onChange)(selectedItem)} disabled={disabled} value={selectedItem} />
      )}
      <div className="ButtonContainer">
        {showCreateItem && (
          <button className="CreateButton" disabled={disabled} onClick={() => showCreateItem.set(true)} key="create">
            Create
          </button>
        )}
        {showEditItem && (
          <button className="EditButton" onClick={() => showEditItem.set(true)} disabled={disabled} key="edit">
            Edit
          </button>
        )}
      </div>
    </div>
  );
};
