import * as U from 'karet.util';
import * as R from 'ramda';

export const Ingredient = {
  create: ({ name = '', ingredientAmount = 0, unit = 'mg' } = {}) => ({ name, ingredientAmount, unit }),
  name: U.view('name'),
  ingredientAmount: U.view('ingredientAmount'),
  unit: U.view('unit'),
};

export const Ingredients = {
  ingredients: R.identity,
  addIngredient: R.pipe(Ingredient.create, R.append),
};
