import * as U from 'karet.util';

export const DoseSubmit = {
  create: ({ id = null, name = null, unit = null, amount = '', weight = '', hasChildren = false } = {}) => ({
    id,
    name,
    unit,
    amount,
    weight,
    hasChildren,
  }),
  canSubmit: ({ id, name, unit, amount, weight, hasChildren }) =>
    id && name && unit && amount > 0 && weight > 0 && !hasChildren,
  id: U.view('id'),
  name: U.view('name'),
  unit: U.view('unit'),
  amount: U.view('amount'),
  weight: U.view('weight'),
  hasChildren: U.view('hasChildren'),
};
