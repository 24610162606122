import * as React from 'karet';
import { useState, useEffect } from 'react';
import * as U from 'karet.util';
import './TopBar.css';
import { Link, withRouter, useRouteMatch } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';

export const TopBar = withRouter(({ user, currentRegion, handleLogout, selectedSubstance, history }) => {
  const loggedIn = user.get() !== null;
  const adminUser = user.get() !== null && user.get().role === 'Admin';
  const isMobile = window.innerWidth <= 1024;
  const naviOpen = U.atom(false);
  const className = !loggedIn ? 'WhiteTopBar' : '';
  const resetSelectedSubstance = () => selectedSubstance.set(null);

  const loginRouteMatch = useRouteMatch('/login');
  const homeRouteMatch = useRouteMatch('/');
  const libraryRouteMatch = useRouteMatch('/index');
  const feedbackRouteMatch = useRouteMatch('/feedback');
  const adminRouteMatch = useRouteMatch('/admin');
  const accountRouteMatch = useRouteMatch('/account');
  const signupRouteMatch = useRouteMatch('/signup');

  const [selectedRegion, setSelectedRegion] = useState(currentRegion);

  useEffect(() => {
    if (!loggedIn && selectedRegion !== currentRegion) {
      const route = loginRouteMatch ? 'login' : signupRouteMatch ? 'signup' : '';
      window.location.href = `/api/redirects/regions/${selectedRegion}/${route}`;
    }
  }, [loggedIn, selectedRegion, currentRegion, loginRouteMatch, signupRouteMatch]);

  const classNameRouteMatch = {
    Home: homeRouteMatch,
    Library: libraryRouteMatch,
    FeedbackPage: feedbackRouteMatch,
    Admin: adminRouteMatch,
    Account: accountRouteMatch,
    LoginLink: loginRouteMatch,
    SignUpLink: signupRouteMatch,
  };

  const appendSelected = (className) => {
    const routeMatch = classNameRouteMatch[className];
    return className + (routeMatch && routeMatch.isExact ? ' selected' : '');
  };

  return (
    <section className={'TopBar'}>
      <div className={className}>
        <div className={'TopBarWrapper'}>
          {loggedIn && isMobile && (
            <div onClick={() => naviOpen.set(!naviOpen.get())}>
              <FaBars className="MobileNaviIcon" />
            </div>
          )}
          {!loggedIn && !isMobile && <img src="/images/doglogo.svg" alt="" className={'DogLogo'} />}
          <Link className="title" to="/" onClick={resetSelectedSubstance}>
            <img src="/images/TB.svg" alt="ToxBuddy" className={'MainLogo'} />
          </Link>
          {loggedIn && !isMobile && (
            <div className="links">
              {adminUser && (
                <Link data-cy="adminTabLink" to="/admin" className={appendSelected('Admin')}>
                  Admin
                </Link>
              )}
              <Link to="/" className={appendSelected('Home')} onClick={resetSelectedSubstance}>
                Home
              </Link>
              <Link to="/feedback" className={appendSelected('FeedbackPage')}>
                Feedback
              </Link>
              <Link to="/index" className={appendSelected('Library')}>
                Toxin index
              </Link>
              <Link to="/account" className={appendSelected('Account')}>
                Account
              </Link>
              <div className="LogOut" onClick={() => handleLogout(history)}>
                Log out
              </div>
            </div>
          )}
          {!loggedIn && (
            <>
              <div className="links">
                <div>
                  <Link to="/login" className={appendSelected('LoginLink')}>
                    Login
                  </Link>
                </div>
                <div>
                  <Link to="/signup" className={appendSelected('SignUpLink')}>
                    Sign up
                  </Link>
                </div>
                <div>
                  <select value={currentRegion} onChange={(e) => setSelectedRegion(e.target.value)}>
                    {/* eslint-disable-next-line */}
                    <option value="default">&#127466;&#127482;</option>
                    {/* eslint-disable-next-line */}
                    <option value="us">&#127482;&#127480;</option>
                  </select>
                </div>
              </div>
            </>
          )}
          {U.mapValue(
            (open) =>
              open ? (
                <div className="MobileLinks">
                  <div className={'MobileLink'}>
                    <Link to="/" className={'Home'} onClick={resetSelectedSubstance}>
                      Home
                    </Link>
                  </div>
                  <div className={'MobileLink'}>
                    <Link to="/index" className={'Library'}>
                      Toxin index
                    </Link>
                  </div>
                  <div className={'MobileLink'}>
                    <Link to="/feedback" className={'Feedback'}>
                      Feedback
                    </Link>
                  </div>
                  <div className={'MobileLink'}>
                    <Link to="/account" className={'Account'}>
                      Account
                    </Link>
                  </div>
                  {adminUser && (
                    <div className={'MobileLink'}>
                      <Link to="/admin" className={'Admin'}>
                        Admin
                      </Link>
                    </div>
                  )}
                  <div className={'MobileLink'}>
                    <div className="LogOut" onClick={handleLogout}>
                      Log out
                    </div>
                  </div>
                </div>
              ) : (
                ''
              ),
            naviOpen,
          )}
        </div>
      </div>
      {!loggedIn && !isMobile && <img src="/images/header.svg" alt="" className={'HeaderSlice'} />}
    </section>
  );
});
