import * as React from 'react';
import { FC, useEffect, useMemo, useState } from 'react';
import './AccountPage.css';
import { Config, User } from '../../types';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import BillingDetails from '../Billing/BillingDetails';
import {
  cancelSubscription,
  getLatestSubscription,
  Subscription,
  withdrawCancelSubscription,
} from '../../services/account';
import { ConfirmButton } from '../Components/ConfirmButton';
import { SubscriptionRenewalForm } from './SubscriptionRenewalForm';

interface Props {
  config: Config;
  user: User;
}

enum OperationResult {
  Success,
  Failure,
}

type StateActionDispatch<T> = React.Dispatch<React.SetStateAction<T>>;
type SetStateDispatch = StateActionDispatch<OperationResult | null>;

export const SubscriptionAdmin: FC<Props> = ({ config, user }) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [fetchSubscriptionResult, setFetchSubscriptionResult] = useState<OperationResult | null>(null);
  const [cancelSubscriptionResult, setCancelSubscriptionResult] = useState<OperationResult | null>(null);
  const [withdrawCancelSubscriptionResult, setWithdrawCancelSubscriptionResult] = useState<OperationResult | null>(
    null,
  );

  const stripe = useMemo(() => loadStripe(config.stripePublicKey), [config.stripePublicKey]);
  const isFreeAccount = user.freeAccount;
  const isPaidAccount = !isFreeAccount;

  useEffect(() => {
    setFetchSubscriptionResult(null);
    fetchLatestSubscription(user.accountId).then(({ subscription, fetchResult }) => {
      setSubscription(subscription);
      setFetchSubscriptionResult(fetchResult);
    });
  }, [user.accountId, cancelSubscriptionResult, withdrawCancelSubscriptionResult]);

  const doSubscriptionOperation =
    (setResult: SetStateDispatch, doOperation: (accountId: string, subscriptionStripeId: string) => Promise<unknown>) =>
    (subscription: Subscription) =>
    async () => {
      setCancelSubscriptionResult(null);
      setWithdrawCancelSubscriptionResult(null);
      try {
        await doOperation(user.accountId, subscription.stripeId);
        setResult(OperationResult.Success);
      } catch (e: unknown) {
        console.log(e);
        setResult(OperationResult.Failure);
      }
    };
  const doCancelSubscription = doSubscriptionOperation(setCancelSubscriptionResult, cancelSubscription);
  const doWithdrawCancellation = doSubscriptionOperation(
    setWithdrawCancelSubscriptionResult,
    withdrawCancelSubscription,
  );

  const freeAccountComponent = () => (
    <div className="Subscription">
      <dt>Subscription type</dt>
      <dd>Free account</dd>
    </div>
  );

  const paidAccountComponent = () => (
    <div>
      {subscription && (
        <div className="Subscription">
          <dl>
            <dt>Latest period paid</dt>
            <dd>
              {dateFormat(subscription.currentPeriodStart)} - {dateFormat(subscription.currentPeriodEnd)}
            </dd>

            <dt>Subscription status</dt>
            <dd>
              <SubscriptionStatus subscription={subscription} />
            </dd>
          </dl>
          {isCancellable(subscription) && (
            <>
              <h2>Cancel subscription</h2>
              <p>
                Canceling your subscription will schedule your subscription to terminate at the end of current billing
                period.
              </p>
              <ConfirmButton
                text="Cancel subscription"
                confirmText="Click to confirm"
                className="DeleteAccount"
                onConfirm={doCancelSubscription(subscription)}
              />
            </>
          )}
          {isEnded(subscription) && (
            <SubscriptionRenewalForm config={config} accountId={user.accountId} stripePromise={stripe} />
          )}
          {cancelSubscriptionResult === OperationResult.Success && (
            <div className="Success">Subscription successfully canceled</div>
          )}
          {canRollbackCancel(subscription) && (
            <ConfirmButton
              text="Resubscribe"
              confirmText="Click to confirm"
              className="DeleteAccount"
              onConfirm={doWithdrawCancellation(subscription)}
            />
          )}
          {withdrawCancelSubscriptionResult === OperationResult.Success && (
            <div className="Success">Subscription successfully restored</div>
          )}
          {withdrawCancelSubscriptionResult === OperationResult.Failure && (
            <div className="Error">Unexpected resubscribe error</div>
          )}
        </div>
      )}
      {fetchSubscriptionResult === OperationResult.Failure && <div>Failed to load subscription</div>}
      <Elements stripe={stripe}>
        <BillingDetails config={config} />
      </Elements>
    </div>
  );

  return (
    <div>
      <hr />
      <h2>Subscription</h2>
      {isFreeAccount && freeAccountComponent()}
      {isPaidAccount && paidAccountComponent()}
    </div>
  );
};

const GRACE_PERIOD_IN_DAYS = 30;
const MILLISECONDS_IN_DAY = 24 * 60 * 60 * 1000;
const isCanceled = (subscription: Subscription) => subscription && subscription.canceledAt !== null;

const isEnded = (subscription: Subscription) => {
  if (!subscription) {
    return false;
  }
  const cancelAt = cancelTime(subscription);
  if (cancelAt && cancelAt < Date.now()) {
    return true;
  }
  return new Date(subscription.currentPeriodEnd).getTime() + GRACE_PERIOD_IN_DAYS * MILLISECONDS_IN_DAY < Date.now();
};

const isCancellable = (subscription: Subscription | null) =>
  subscription && !isEnded(subscription) && !isCanceled(subscription);

const cancelTime = (subscription: Subscription) =>
  isCanceled(subscription) ? new Date(subscription.canceledAt).getTime() : undefined;

const canRollbackCancel = (subscription: Subscription) => {
  const cancelAt = cancelTime(subscription);
  return cancelAt && cancelAt > Date.now();
};

const SubscriptionStatus: FC<{ subscription: Subscription }> = ({ subscription }) => {
  const now = Date.now();
  const currentPeriodEndTime = new Date(subscription.currentPeriodEnd).getTime();
  const isPaid = currentPeriodEndTime > now;
  const inGracePeriod =
    currentPeriodEndTime <= now && currentPeriodEndTime + GRACE_PERIOD_IN_DAYS * MILLISECONDS_IN_DAY > now;

  return (
    <span>
      {isPaid && !isEnded(subscription) ? (
        isCanceled(subscription) ? (
          `Canceled to end on ${dateFormat(subscription.canceledAt)}`
        ) : (
          'Recurring'
        )
      ) : inGracePeriod ? (
        <span className="OverDue">Payment overdue, check your payment method</span>
      ) : (
        `Ended ${subscription.canceledAt ? `(${dateFormat(subscription.canceledAt)})` : ''}`
      )}
    </span>
  );
};

const dateFormat = (dateString: string) => {
  const timestamp = Date.parse(dateString);
  if (isNaN(timestamp)) {
    throw new Error(`Not a date: ${dateString}`);
  }
  return new Date(timestamp).toDateString();
};

async function fetchLatestSubscription(
  accountId: string,
): Promise<{ subscription: Subscription | null; fetchResult: OperationResult | null }> {
  try {
    const subscription = await getLatestSubscription(accountId);
    return { subscription: subscription || null, fetchResult: OperationResult.Success };
  } catch (err: unknown) {
    // eslint-disable-next-line no-console
    console.error(err);
    return { subscription: null, fetchResult: OperationResult.Failure };
  }
}
