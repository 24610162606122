import * as React from 'karet';
import { ToxinIndex } from '../ToxinIndex/ToxinIndex';
import { withRouter } from 'react-router-dom';
import { CheckToxinCalculatorAccess } from '../../Components/CheckToxinCalculatorAccess';

const Library = ({ user, selectedSubstance, history }) => {
  const adminUser = user.get() !== null && user.get().role === 'Admin';
  return (
    <section className={`SubstanceInfoWrapper`}>
      <CheckToxinCalculatorAccess user={user}>
        <div className="SubstanceInfoContent">
          <ToxinIndex
            onSelect={(substance) => {
              selectedSubstance.set(substance);
              history.push('/');
            }}
            showHidden={adminUser}
          />
        </div>
      </CheckToxinCalculatorAccess>
    </section>
  );
};

export default withRouter(Library);
