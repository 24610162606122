export function handleJsonResponse<T = unknown>(response: Response): Promise<T | undefined> | undefined {
  if (response.status === 401) {
    window.location.reload();
  } else {
    return response.text().then((text) => {
      if (response.status === 200) {
        if (text.length > 0) {
          return JSON.parse(text) as T;
        }
        return;
      } else {
        // eslint-disable-next-line
        throw { status: response.status, body: text };
      }
    });
  }
}
