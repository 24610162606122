import * as React from 'karet';
import * as R from 'ramda';
import * as U from 'karet.util';
import { object, reduce, map, filter, pairs } from 'underscore';
import { HashLink as Link } from 'react-router-hash-link';
import { toxinService } from '../../../services/toxin';
import { flattenSubstances } from '../../../utils/toxin';
import './ToxinIndex.css';
import * as analytics from '../../../services/analytics';

const renderLetters = (substances) => {
  return map(substances, (letterSubstances, letter) => {
    return letterSubstances.length > 0 ? (
      <Link to={`#substance${letter}`} className={'Letter Enabled'} key={letter}>
        {' '}
        {letter}
      </Link>
    ) : (
      <div className="Letter Disabled" key={letter}>
        {letter}
      </div>
    );
  });
};

const selectSubstance = async (selected, onSelect) => {
  const substance = await toxinService.getToxinInfo(selected.id);
  onSelect(substance);
  window.scrollTo(0, 0);
};

const renderLetterContainers = (substances, searchTerm, onSelect) => {
  const lettersWithSubstances = object(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    filter(pairs(substances), ([_, letterSubstances]) => letterSubstances.length > 0),
  );
  return map(lettersWithSubstances, (substances, letter) => {
    return (
      <div className="Letter" key={letter}>
        <div className="Heading" id={`substance${letter}`}>
          {letter}
        </div>
        <div className="Toxins">
          {substances.map((substance) => {
            const synonymMatch = findSynonymMatch(searchTerm, substance);
            return (
              <div
                className={'ToxinNameLink'}
                key={`library-${substance.id}`}
                onClick={() => {
                  analytics.virtualPageView(substance.name, 'library');
                  selectSubstance(substance, onSelect);
                }}
              >
                {substance.name}{' '}
                {synonymMatch ? (
                  <small>
                    <em>({synonymMatch})</em>
                  </small>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  });
};

const searchToxins = (search, showHidden, substances) => {
  toxinService.searchToxins(search).then((toxins) => {
    substances.set(mapToLetterList(toxins, showHidden));
  });
};

const findSynonymMatch = (searchTerm, toxin) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return undefined;
  }

  return toxin.synonyms.find(
    (synonym) => synonym.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 && searchTerm.length > 0,
  );
};

const mapToLetterList = (substances, showHidden) => {
  const letters = [
    '',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  const letterMap = object(letters.map((letter) => [letter, []]));
  const flatList = flattenSubstances(substances);
  const filteredFlatList = showHidden ? flatList : flatList.filter((toxin) => toxin.visible);
  return reduce(
    filteredFlatList,
    (memo, substance) => {
      const firstLetter = substance.name.charAt(0).toUpperCase();
      const slot = letters.indexOf(firstLetter) !== -1 ? firstLetter : '';
      return Object.assign({}, memo, { [slot]: memo[slot].concat([substance]) });
    },
    letterMap,
  );
};

export const ToxinIndex = ({ onSelect, showHidden }) => {
  const substances = U.atom([]);
  const searchTerm = U.atom('');
  searchTerm.debounce(500).observe((term) => searchToxins(term, showHidden, substances));

  return (
    <div key={'ToxinIndex'} className="ToxinIndex">
      <h1 className={'Tagline'}>Toxin index</h1>
      <div className="Search">
        <img src="/images/icons/ic_substance.svg" width={30} alt="substance" />
        <U.Input
          type="text"
          value={searchTerm}
          onBlur={(evt) => {
            analytics.siteSearch(evt.target.value);
          }}
        />
      </div>
      <div className="List">
        <div className="Toxins">
          {U.mapValue((list) => renderLetterContainers(list, searchTerm.get(), onSelect), substances)}
        </div>
        <div className="Letters">{U.mapValue((list) => renderLetters(list), substances)}</div>
      </div>
    </div>
  );
};
