import React, { ChangeEvent, FC } from 'react';
import * as R from 'ramda';
import './SignUpForm.css';
import { getCoupon } from '../../../services/pricing';
import { StripeCoupon } from './types';
import { debounce } from 'lodash';

interface Props {
  onCouponChanged: (c: StripeCoupon | null) => void;
  coupon: StripeCoupon | null;
}

export const CouponCode: FC<Props> = ({ onCouponChanged, coupon }) => {
  const [couponCode, setCouponCode] = React.useState('');

  const debouncedGetCoupon = React.useCallback(
    debounce(async (code: string) => {
      const coupon = (await getCoupon(code)) as StripeCoupon;
      onCouponChanged(coupon);
    }, 500),
    [onCouponChanged],
  );

  const onCouponCodeChanged = async (e: ChangeEvent<HTMLInputElement>) => {
    const couponCode = R.trim(e.target.value);
    setCouponCode(couponCode);
    if (!R.isEmpty(couponCode)) {
      debouncedGetCoupon(couponCode);
    } else {
      onCouponChanged(null);
    }
  };

  return (
    <>
      <label className="CouponCode">
        Coupon code
        <input data-cy="couponCodeInput" type="text" value={couponCode} onChange={onCouponCodeChanged} />
        {coupon?.valid === false && <div>This coupon code is expired!</div>}
        {!R.isEmpty(couponCode) && R.isNil(coupon) && <div>This coupon code is invalid!</div>}
      </label>
    </>
  );
};
