import * as R from 'ramda';
import * as U from 'karet.util';
import * as L from 'partial.lenses';

export const Activity = {
  id: U.view('id'),
  name: U.view('name'),
  category: U.view('category'),
  subCategory: U.view('subCategory'),
  description: U.view('description'),
  remark: U.view('remark'),
  generic: U.view('generic'),
  visibility: U.view('visibility'),
  items: U.view([L.prop('items'), L.valueOr([]), L.normalize(R.sortBy(L.get('order')))]),
  order: U.view('order'),
  inherited: U.view('inheritedFrom'),
};

export const Activities = {
  at: (index, activities) => U.view([L.index(index)])(activities),
};

export const Item = {
  create: ({
    id = null,
    name = 'New item',
    nameAsHeading = true,
    description = '',
    generic = false,
    remark = '',
    checklist = { dos: [], donts: [], notes: [] },
  } = {}) => ({ id, name, description, generic, remark, checklist, nameAsHeading }),
  id: U.view('id'),
  name: U.view('name'),
  remark: U.view('remark'),
  description: U.view([L.prop('description'), L.valueOr('')]),
  generic: U.view('generic'),
  order: U.view('order'),
  checklist: U.view('checklist'),
  nameAsHeading: U.view('nameAsHeading'),
};

export const Items = {
  at: (index, items) => U.view([L.index(index)])(items),
};

export const CheckList = {
  dos: U.view('dos'),
  donts: U.view('donts'),
  notes: U.view('notes'),
};
