import { CountrySelection } from '../App/Billing/CountrySelect';

const prefixedDigits = (prefix: string, numDigits: number) => ({
  regex: new RegExp(`^${prefix}\\d{${numDigits}}$`),
  sample: `${prefix}${'9'.repeat(numDigits)}`,
});

const suffixedDigits = (suffix: string, numDigits: number) => ({
  regex: new RegExp(`^\\d{${numDigits}}${suffix}$`),
  sample: `${'9'.repeat(numDigits)}${suffix}`,
});

export const vatIdValidation: { [k: string]: { regex: RegExp; sample: string } } = {
  AT: prefixedDigits('ATU', 8),
  BE: {
    regex: /^BE[0-1]\d{9}$/,
    sample: 'BE09999999XX',
  },
  BG: {
    regex: /^BG\d{9,10}$/,
    sample: 'BG999999999',
  },
  HR: prefixedDigits('HR', 11),
  CH: {
    regex: /^[\\.A-Z0-9]{9,20}$/,
    sample: '123.456.789 MWST',
  },
  CY: {
    regex: /^CY[A-Z0-9]{9}$/,
    sample: 'CY99999999L',
  },
  CZ: {
    regex: /^CZ\d{8,9,10}$/,
    sample: 'CZ999999999',
  },
  DK: prefixedDigits('DK', 8),
  EE: prefixedDigits('EE', 9),
  FI: prefixedDigits('FI', 8),
  FR: {
    regex: /^FR[A-Z0-9]{2}\d{9}$/,
    sample: 'FRXX999999999',
  },
  DE: prefixedDigits('DE', 9),
  GB: {
    regex: /^[A-Z0-9]{9}$/,
    sample: '123456789',
  },
  GR: prefixedDigits('EL', 9),
  HU: prefixedDigits('HU', 8),
  IE: {
    regex: /^IE\d{7}[A-Z]{1,2}$/,
    sample: 'IE9999999FA',
  },
  IT: prefixedDigits('IT', 11),
  LV: prefixedDigits('LV', 11),
  LT: {
    regex: /^LT\d{9,12}$/,
    sample: 'LT999999999/LT999999999999',
  },
  LU: prefixedDigits('LU', 8),
  MT: prefixedDigits('MT', 8),
  NL: {
    regex: /^NL\d{9}B\d{2}$/,
    sample: 'NL999999999B01',
  },
  NO: suffixedDigits('MVA', 9),
  PL: prefixedDigits('PL', 10),
  PT: prefixedDigits('PT', 9),
  RO: prefixedDigits('RO', 8),
  SK: prefixedDigits('SK', 10),
  SI: prefixedDigits('SI', 8),
  ES: {
    regex: /^ES[A-Z]?\d{7,8}[A-Z]?$/,
    sample: 'ESX9999999R',
  },
  SE: Object.assign(prefixedDigits('SE', 12), { sample: 'SE999999999901' }),
};

export const getVatIdPlaceholder = (country: CountrySelection | null) => {
  if (!country?.value) {
    return '';
  }
  return vatIdValidation[country.value]?.sample || '';
};
