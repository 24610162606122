import * as React from 'react';
import { FC, useState } from 'react';
import './AccountPage.css';
import { useForm } from 'react-hook-form';
import { user as userService } from '../../services/user';
import { emailValidationRegex } from '../../utils/validation';

interface FormData {
  email: string;
  emailAgain: string;
}

interface Props {
  changeHandler: (
    fn: (v: string) => void,
    setSuccess: React.Dispatch<React.SetStateAction<boolean>>,
    setFailed: React.Dispatch<React.SetStateAction<boolean>>,
  ) => (newValue: string) => Promise<void>;
}

export const ChangeEmailForm: FC<Props> = ({ changeHandler }) => {
  const [emailChangeSuccess, setEmailChangeSuccess] = useState(false);
  const [emailChangeFailure, setEmailChangeFailure] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<FormData>();

  const onChangeEmailSubmit = ({ email }: FormData) => {
    changeHandler(userService.changeEmail, setEmailChangeSuccess, setEmailChangeFailure)(email);
  };

  return (
    <form onSubmit={handleSubmit(onChangeEmailSubmit)}>
      <label>
        Email address
        <input
          {...register('email', {
            required: true,
            pattern: emailValidationRegex,
          })}
        />
      </label>
      {errors.email?.type === 'required' && <div className="ValidationError">Email is required</div>}
      {errors.email?.type === 'pattern' && (
        <div className="ValidationError">Value does not look like email address</div>
      )}
      <label>
        Repeat email address
        <input
          {...register('emailAgain', {
            required: true,
            pattern: emailValidationRegex,
            validate: {
              matchesPreviousEmail: (value) => {
                const { email } = getValues();
                return email === value || 'Emails should match!';
              },
            },
          })}
        />
      </label>
      {errors.emailAgain?.type === 'required' && <div className="ValidationError">Repeat email is required</div>}
      {errors.emailAgain?.type === 'pattern' && (
        <div className="ValidationError">Value does not look like email address</div>
      )}
      {errors.emailAgain?.type === 'matchesPreviousEmail' && (
        <div className="ValidationError">{errors.emailAgain.message}</div>
      )}
      {emailChangeSuccess && <div className="Success">Email address successfully changed</div>}
      {emailChangeFailure && <div className="Failed">Email change failed</div>}
      <button type="submit">Save</button>
    </form>
  );
};
