import React, { ChangeEvent, FC } from 'react';
import { CountrySelect, CountrySelection } from './CountrySelect';
import countryList from 'react-select-country-list';
import * as R from 'ramda';
import { getVatIdPlaceholder } from '../../utils/vatIdValidation';
import './BillingInformationForm.css';
import { SignUpFormState } from '../auth/SignUpForm/types';
import { isVatIdValid } from '../auth/SignUpForm/validation';

interface BillingInformationFormProps {
  onFieldChanged: (field: keyof SignUpFormState) => (e: ChangeEvent<HTMLInputElement>) => void;
  onCountryChanged: (v: CountrySelection | null) => void;
  onVatIdChanged: (v: string) => void;
  companyName: string;
  country: CountrySelection | null;
  vatId: string | null;
  streetAddress: string;
  postalCode: string;
  city: string;
}

const DefaultBillingInformationForm: FC<BillingInformationFormProps> = (props) => {
  const {
    onFieldChanged,
    onCountryChanged,
    onVatIdChanged,
    companyName,
    country,
    vatId,
    streetAddress,
    postalCode,
    city,
  } = props;

  return (
    <div className="BillingInformationForm">
      <label>
        Clinic or Business Name
        <input
          data-cy="businessNameInput"
          type="text"
          value={companyName || ''}
          onChange={onFieldChanged('companyName')}
        />
      </label>

      <label>
        Street Address
        <input
          data-cy="streetInput"
          type="text"
          value={streetAddress || ''}
          onChange={onFieldChanged('streetAddress')}
        />
      </label>
      <label className="OneThird">
        Postal Code
        <input data-cy="postalCodeInput" type="text" value={postalCode || ''} onChange={onFieldChanged('postalCode')} />
      </label>
      <label className="TwoThirds">
        City
        <input data-cy="cityInput" type="text" value={city || ''} onChange={onFieldChanged('city')} />
      </label>

      <label className="OneThird">
        Country
        <CountrySelect
          data-cy="countrySelectInput"
          options={filterCountries(countryList().getData())}
          value={country}
          onChange={onCountryChanged}
        />
      </label>
      <label className="TwoThirds">
        VAT identification number
        <input
          data-cy="vatNumberInput"
          type="text"
          value={vatId || ''}
          placeholder={getVatIdPlaceholder(country)}
          onChange={(evt) => onVatIdChanged(evt.target.value)}
        />
        {!R.isEmpty(vatId) && !isVatIdValid(country, vatId) ? (
          <div className="validationErrorText">Invalid format for country '{country?.label}'</div>
        ) : null}
      </label>
    </div>
  );
};

function filterCountries(countries: CountrySelection[]) {
  return countries.filter((c) => c.value.toLowerCase() !== 'us');
}

export default DefaultBillingInformationForm;
