import { handleJsonResponse } from './common';
import { StripeCoupon, StripePrice } from '../App/auth/SignUpForm/types';

const getInitParams: RequestInit = {
  method: 'GET',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
  },
};

export async function getIndividualUserPrice() {
  const response = await fetch(`/api/subscriptions/prices/individual`, getInitParams);
  return handleJsonResponse<StripePrice>(response);
}

export async function getClinicPrice(clinicSize: number) {
  const response = await fetch(`/api/subscriptions/prices/clinic/${clinicSize}`, getInitParams);
  return handleJsonResponse<StripePrice>(response);
}

export async function getMonthlyPrice() {
  const response = await fetch(`/api/subscriptions/prices/monthly`, getInitParams);
  return handleJsonResponse<StripePrice>(response);
}

export async function getYearlyPrice() {
  const response = await fetch(`/api/subscriptions/prices/yearly`, getInitParams);
  return handleJsonResponse<StripePrice>(response);
}

export async function getCoupon(couponCode: string) {
  const response = await fetch(`/api/subscriptions/coupons/${couponCode}`, getInitParams);
  return handleJsonResponse<StripeCoupon>(response);
}
