import * as React from 'karet';
import * as U from 'karet.util';
import ToxicityGraph from './ToxicityGraph';
import { equals, pick } from 'ramda';
import './CaseDetails.css';
import { TreatmentPlan } from './TreatmentPlan/TreatmentPlan';
import { CaseDataForm } from './CaseDataForm/CaseDataForm';

const toxinEquals = (oldVersion, newVersion) => {
  const oldPicked = oldVersion
    ? pick(
        [
          'id',
          'unit',
          'description',
          'toxicityLevels',
          'parent',
          'synonyms',
          'parentDescription',
          'additionalInfo',
          'ingredients',
          'visible',
          'children',
          'name',
        ],
        oldVersion,
      )
    : {};
  const newPicked = newVersion
    ? pick(
        [
          'id',
          'unit',
          'description',
          'toxicityLevels',
          'parent',
          'synonyms',
          'parentDescription',
          'additionalInfo',
          'ingredients',
          'visible',
          'children',
          'name',
        ],
        newVersion,
      )
    : {};
  if (newPicked.toxicityLevels) {
    newPicked.toxicityLevels = newPicked.toxicityLevels.map((level) => {
      return pick(['category', 'conditions', 'description', 'threshold'], level);
    });
  }
  if (oldPicked.toxicityLevels) {
    oldPicked.toxicityLevels = oldPicked.toxicityLevels.map((level) => {
      return pick(['category', 'conditions', 'description', 'threshold'], level);
    });
  }

  return equals(oldPicked, newPicked);
};

export const CaseDetails = ({
  substanceWithDose,
  selectedSubstance,
  adminUser,
  substances,
  conditions,
  clinicalSigns,
}) => {
  const skipDuplicatesSelected = selectedSubstance.skipDuplicates(toxinEquals);
  const allOpen = U.atom(false);

  return (
    <section className="CaseDetails">
      <div className="CaseDataFormWrapper">
        <CaseDataForm
          substanceWithDose={substanceWithDose}
          selectedSubstance={selectedSubstance}
          adminUser={adminUser}
          substances={substances}
          conditions={conditions}
          clinicalSigns={clinicalSigns}
        />
        {U.mapValue(
          (calculated) =>
            calculated !== null && calculated.ingested
              ? [
                  <div className="ToxicityGraphWrapper" key="ToxicityGraph">
                    <ToxicityGraph substanceWithDose={substanceWithDose} />
                  </div>,
                ]
              : '',
          substanceWithDose,
        )}
      </div>

      {U.mapValue(
        (selected) =>
          selected !== null
            ? [
                <div className="TreatmentPlanWrapper" key="TreatmentPlan">
                  <TreatmentPlan
                    selectedSubstance={selectedSubstance}
                    adminUser={adminUser}
                    view={'CaseDetails'}
                    allOpen={allOpen}
                  />
                </div>,
              ]
            : '',
        skipDuplicatesSelected,
      )}
    </section>
  );
};
