import * as React from 'karet';
import * as U from 'karet.util';
import { FaAngleDown, FaMinus } from 'react-icons/fa';
import { styleInvertedVisibleToggle, styleVisibleToggle } from '../../utils/ui';
import './DropDownPane.css';
import * as analytics from '../../services/analytics';

export const DropDownPane = ({ visible, boxClassName, paneClassName, title, children }) => {
  const visibleState = U.atom(false);
  const style = styleVisibleToggle(visibleState);
  const invertedStyle = styleInvertedVisibleToggle(visibleState);
  const boxClassNameState = U.mapValue((visible) => `${boxClassName} ${visible ? 'Open' : ''}`, visibleState);
  visible.observe((value) => {
    visibleState.set(value);
    if (value) {
      analytics.sectionOpenedAutomatic(title);
    }
  });

  function toggleSection() {
    visibleState.set(!visibleState.get());
    if (visibleState.get()) analytics.sectionOpened(title);
  }

  return (
    <div className="DropDownContainer">
      <div className={boxClassNameState} onClick={() => toggleSection()}>
        {title}
        <span className="Button" style={style}>
          <FaMinus />
        </span>
        <span className="Button" style={invertedStyle}>
          <FaAngleDown />
        </span>
      </div>
      <div className={paneClassName} style={style}>
        {children}
      </div>
    </div>
  );
};
