import React, { useState } from 'react';
import * as R from 'ramda';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as analytics from '../../services/analytics';
import PaymentMethodForm from './PaymentMethodForm';
import { addPaymentMethod } from '../../services/billingDetails';
import { Loader } from '../Components/Loader';

export const NewPaymentMethodForm = ({ email, onCancel, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCardExpiryComplete, setIsCardExpiryComplete] = useState(false);
  const [isCardCvcComplete, setIsCardCvcComplete] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    setFailed(false);
    const cardElement = elements.getElement(CardNumberElement);
    stripe
      .createPaymentMethod({
        type: 'card',
        card: cardElement,
        // eslint-disable-next-line
        billing_details: {
          email,
          name: cardholderName,
        },
      })
      .then((payload) => {
        if (payload.paymentMethod) {
          return addPaymentMethod(payload.paymentMethod.id, cardholderName)
            .then(() => {
              setIsLoading(false);
              onUpdate();
            })
            .catch((err) => {
              setIsLoading(false);
              setFailed(true);
              analytics.error(err.status + ': Unknown error');
            });
        } else {
          setIsLoading(false);
          setFailed(true);
          analytics.error(payload.error.code + ': ' + payload.error.message);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setFailed(true);
        analytics.error('Unknown error: ' + err);
      });
  };
  const getMissingFieldNames = () => {
    const missingFields = [];
    R.isEmpty(cardholderName) && missingFields.push(["'Cardholder name'"]);
    R.equals(false, isCardNumberComplete) && missingFields.push(["'Card number'"]);
    R.equals(false, isCardExpiryComplete) && missingFields.push(["'Expiration date'"]);
    R.equals(false, isCardCvcComplete) && missingFields.push(["'CVC'"]);
    return missingFields;
  };
  const onCardholderNameChange = (evt) => setCardholderName(evt.target.value);
  const isFormValid = cardholderName && isCardNumberComplete && isCardExpiryComplete && isCardCvcComplete;
  const submitEnabled = isFormValid && !isLoading;
  return (
    <form onSubmit={handleSubmit}>
      <section>
        <PaymentMethodForm
          onCardholderNameChange={onCardholderNameChange}
          onCardNumberChange={(event) => setIsCardNumberComplete(event.complete)}
          onCardExpiryChange={(event) => setIsCardExpiryComplete(event.complete)}
          onCardCvcChange={(event) => setIsCardCvcComplete(event.complete)}
        />
      </section>
      {failed && (
        <div className="ErrorText">There was a problem adding new payment method. Please contact support.</div>
      )}
      {isLoading && <Loader />}
      <div>
        <button type="submit" disabled={!submitEnabled}>
          Add new payment method
        </button>
        <button type="button" onClick={() => onCancel()} className="CancelButton">
          Cancel
        </button>
      </div>
      {!submitEnabled && !isLoading && (
        <div className="validationErrorText">Please fill all required fields: {getMissingFieldNames().join(', ')}</div>
      )}
    </form>
  );
};
