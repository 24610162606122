import * as React from 'karet';
import * as U from 'karet.util';
import * as R from 'ramda';
import * as L from 'partial.lenses';
import { findIndex } from 'underscore';
import { debounce } from 'throttle-debounce';
import { Modal } from '../../Components/Modal';
import { activityService } from '../../../services/activity';
import { Activity, Activities, Item } from './ActivityMeta';
import { ActivityItemEditor } from './ActivityItemEditor';
import { FaAngleUp } from 'react-icons/fa';
import { SuccessInfo } from '../../../utils/ui';
import './ActivityEditor.css';
import { ConfirmButton } from '../../Components/ConfirmButton';
import { pick } from 'ramda';
import { equals } from 'ramda';

const onUpdateActivity = (saved, failed, toxinId, data) => {
  const time = new Date().toTimeString();
  activityService
    .updateActivity({ toxinId: toxinId.get(), activity: data })
    .then(() => {
      saved.set(`Saved at ${time}`);
      failed.set(null);
    })
    .catch(() => {
      failed.set(`Save failed at ${time}`);
      saved.set(null);
    });
};

const onDeleteActivity = (toxinId, activity) => {
  activityService.deleteActivity({ activityId: activity.get().id, toxinId: toxinId.get() }).then(() => {
    activity.remove();
  });
};

const onMoveActivityUp = (toxinId, activity, activities) => {
  const activityData = activity.get();
  const activitiesData = activities.get();
  const index = findIndex(activitiesData, (a) => a.id === activityData.id);
  if (index > 0) {
    const oldOrder = activityData.order;
    const prevActivity = Activities.at(index - 1, activities);

    Activity.order(activity).set(Activity.order(prevActivity).get());
    Activity.order(prevActivity).set(oldOrder);

    activityService.updateActivity({ toxinId: toxinId.get(), activity: activity.get() });
  }
};

const incClamped = (min, max) => R.pipe(R.inc, R.clamp(min, max));

const decClamped = (min, max) => R.pipe(R.dec, R.clamp(min, max));

const MoveUpButton = ({ item, prevItem, maxValue }) => {
  return (
    <button
      className="MoveButton RoundButton"
      disabled={prevItem === undefined}
      onClick={() => {
        if (prevItem) {
          item.modify(decClamped(0, maxValue));
          prevItem.modify(incClamped(0, maxValue));
        }
      }}
    >
      <FaAngleUp />
    </button>
  );
};

const switchVisibility = (toxinId, newVisibility, activity, visibility) => {
  const activityValue = { ...activity.get() };
  activityValue.visibility = newVisibility;
  activityService.updateActivity({ toxinId: toxinId.get(), activity: activityValue }).then(() => {
    visibility.set(newVisibility);
  });
};

const activityEquals = (oldVersion, newVersion) => {
  const oldPicked = oldVersion
    ? pick(
        ['id', 'order', 'name', 'category', 'subCategory', 'description', 'generic', 'visibility', 'items'],
        oldVersion,
      )
    : {};
  const newPicked = newVersion
    ? pick(
        ['id', 'order', 'name', 'category', 'subCategory', 'description', 'generic', 'visibility', 'items'],
        newVersion,
      )
    : {};
  if (newPicked.items) {
    newPicked.items = newPicked.items.map((level) => {
      return pick(['checklist', 'description', 'generic', 'id', 'name', 'nameAsHeading', 'order', 'remark'], level);
    });
  }
  if (oldPicked.items) {
    oldPicked.items = oldPicked.items.map((level) => {
      return pick(['checklist', 'description', 'generic', 'id', 'name', 'nameAsHeading', 'order', 'remark'], level);
    });
  }
  return equals(oldPicked, newPicked);
};

export const ActivityEditor = ({ toxinId, activity, activities }) => {
  const name = Activity.name(activity);
  const description = Activity.description(activity);
  const generic = Activity.generic(activity);
  const visibility = Activity.visibility(activity);
  const items = Activity.items(activity);
  const inherited = Activity.inherited(activity);
  const open = U.atom(false);
  const saved = U.atom(null);
  const failed = U.atom(null);
  const throttledUpdate = debounce(1000, onUpdateActivity);

  activity
    .skip(1)
    .skipDuplicates(activityEquals)
    .map((value) => ({
      ...value,
      items: R.pipe(R.propOr([], 'items'), R.sortBy(R.prop('order')))(value),
    }))
    .observe((value) => {
      if (open.get()) {
        throttledUpdate(saved, failed, toxinId, value);
      }
    });
  return (
    <div className="ActivityEditor">
      <div>
        {U.mapValue((inheritedValue) => {
          return inheritedValue ? (
            <div className="InheritedActivity">Inherited from {inheritedValue}</div>
          ) : (
            <React.Fragment>
              <button className="EditButton" onClick={() => open.set(!open.get())}>
                Edit
              </button>
              <ConfirmButton
                text={'Remove'}
                confirmText={'Confirm'}
                className={'DeleteButton RemoveButton'}
                onConfirm={() => onDeleteActivity(toxinId, activity)}
              />
              <button
                className="MoveButton RoundButton"
                onClick={() => onMoveActivityUp(toxinId, activity, activities)}
              >
                <FaAngleUp />
              </button>
            </React.Fragment>
          );
        }, inherited)}
      </div>
      <Modal open={open}>
        <div className="Dialog">
          <div className="Content">
            <h2>Update activity</h2>
            <label>Name</label>
            <input type="text" className="TextInput" value={name} onChange={(evt) => name.set(evt.target.value)} />
            <label>Generic</label>
            <div>{U.ifElse(generic, 'Yes', 'No')}</div>
            <label>Visibility</label>
            <select
              value={visibility}
              className="Visibility"
              onChange={(evt) => switchVisibility(toxinId, evt.target.value, activity, visibility, open)}
            >
              <option value="ToxinLibrary">Library</option>
              <option value="CaseDetails">Case details</option>
              <option value="Both">Both</option>
            </select>
            <label>Description</label>
            <textarea cols="60" rows="5" value={description} onChange={(evt) => description.set(evt.target.value)} />
            <label>Items</label>
            {U.mapElems((item, index) => {
              const prevItemOrder = index > 0 ? U.view([L.index(index - 1), 'order'], items) : undefined;
              const itemsData = items.get();
              return (
                <div key={Item.id(item).get()} className="ActivityItemEditor">
                  <ActivityItemEditor item={item} items={items} />
                  <div className="ItemControls">
                    <ConfirmButton
                      className={'RemoveButton'}
                      text={'Remove Item'}
                      confirmText={'Confirm'}
                      onConfirm={() => item.remove()}
                    />
                    <MoveUpButton
                      item={U.view('order', item)}
                      prevItem={prevItemOrder}
                      maxValue={itemsData ? itemsData.length : 0}
                    />
                  </div>
                </div>
              );
            }, items)}
            <button className="CreateItem" onClick={() => items.modify(R.append(Item.create()))}>
              Create Item
            </button>
          </div>
          <div className="BottomBar">
            <SuccessInfo saved={saved} failed={failed} classNameSaved="Saved" classNameFailed="Failed" />
            <button className="CloseButton" onClick={() => open.set(false)}>
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
