import * as React from 'karet';
import './AcceptTerms.css';
import { acceptTerms } from '../../services/account';
import * as authService from '../../services/auth';
import * as U from 'karet.util';
import { withRouter } from 'react-router-dom';

const acceptClicked = (user, errorAtom, history) => {
  const accountId = user.get().accountId;
  acceptTerms(accountId)
    .then(() => {
      authService
        .getUser()
        .then((fetched) => {
          user.set(fetched);
          history.push('/');
        })
        .catch(() => {
          errorAtom.set('Could not accept terms, please try again.');
        });
    })
    .catch(() => {
      errorAtom.set('Could not accept terms, please try again.');
    });
};

export const AcceptTerms = withRouter(({ user, handleLogout, history }) => {
  const currentUser = user.get();
  if (currentUser.termsAcceptedAt !== null) {
    history.push('/');
  }
  const errorAtom = U.atom('');
  return (
    <div className={'AcceptTermsContainer'}>
      <h1>Our Terms of Use has changed</h1>
      <p>
        To continue using ToxBuddy you must agree to the&nbsp;
        <a rel="noopener noreferrer" target={'_blank'} href="/legal">
          Terms of Use
        </a>
        &nbsp;and&nbsp;
        <a rel="noopener noreferrer" target={'_blank'} href="https://www.orion.fi/en/privacy/">
          Privacy policy
        </a>
      </p>
      <div className={'AcceptTermsButtons'}>
        <button
          data-cy="acceptTermsButton"
          className={'AcceptButton'}
          onClick={() => acceptClicked(user, errorAtom, history)}
        >
          Accept
        </button>
        <button data-cy="acceptTermsLogoutButton" className={'LogoutButton'} onClick={() => handleLogout(history)}>
          Log out
        </button>
      </div>
      {U.mapValue((error) => (error.length > 0 ? <div className={'ErrorMessage'}>{error}</div> : ''), errorAtom)}
    </div>
  );
});
