import React, { ChangeEvent, FC } from 'react';
import './BillingInformationForm.css';
import { SignUpFormState, UsBillingAddress } from '../auth/SignUpForm/types';
import { StateSelection, UsStateSelect } from './UsStateSelect';

interface UsBillingInformationFormProps {
  onFieldChanged: (field: keyof SignUpFormState) => (e: ChangeEvent<HTMLInputElement>) => void;
  onStateChanged: (state: StateSelection | null) => void;
  onBillingAddressFieldChanged: (field: keyof UsBillingAddress) => (e: ChangeEvent<HTMLInputElement>) => void;
  onBillingStateChanged: (state: StateSelection | null) => void;
  onSameBillingAddressChanged: (e: ChangeEvent<HTMLInputElement>) => void;
  companyName: string;
  streetAddress: string;
  city: string;
  state: StateSelection | null;
  zipCode: string;
  sameBillingAddress: boolean;
  billingAddress: UsBillingAddress;
}

const UsBillingInformationForm: FC<UsBillingInformationFormProps> = (props) => {
  const {
    onFieldChanged,
    onStateChanged,
    onBillingAddressFieldChanged,
    onBillingStateChanged,
    onSameBillingAddressChanged,
    sameBillingAddress,
    companyName,
    streetAddress,
    state,
    zipCode,
    city,
    billingAddress,
  } = props;

  const billingAddressDisplay = sameBillingAddress ? 'none' : 'flex';

  return (
    <div className="BillingInformationForm">
      <label>
        Clinic or Business Name
        <input type="text" value={companyName || ''} onChange={onFieldChanged('companyName')} />
      </label>

      <label>
        Street Address
        <input type="text" value={streetAddress || ''} onChange={onFieldChanged('streetAddress')} />
      </label>
      <label className="OneThird">
        City
        <input type="text" value={city || ''} onChange={onFieldChanged('city')} />
      </label>
      <label className="OneThird">
        State
        <UsStateSelect value={state} onChange={onStateChanged} />
      </label>
      <label className="OneThird">
        Zip Code
        <input type="text" value={zipCode || ''} onChange={onFieldChanged('postalCode')} />
      </label>

      <label className="Horizontal">
        <input type="checkbox" checked={sameBillingAddress} onChange={onSameBillingAddressChanged} />
        Billing address is the same as above
      </label>

      <div style={{ display: billingAddressDisplay }} className="BillingAddressForm">
        <label>
          Billing Address
          <input
            type="text"
            value={billingAddress.streetAddress || ''}
            onChange={onBillingAddressFieldChanged('streetAddress')}
          />
        </label>
        <label className="OneThird">
          City
          <input type="text" value={billingAddress.city || ''} onChange={onBillingAddressFieldChanged('city')} />
        </label>
        <label className="OneThird">
          State
          <UsStateSelect value={billingAddress.state} onChange={onBillingStateChanged} />
        </label>
        <label className="OneThird">
          Zip Code
          <input
            type="text"
            value={billingAddress.postalCode || ''}
            onChange={onBillingAddressFieldChanged('postalCode')}
          />
        </label>
      </div>
    </div>
  );
};

export default UsBillingInformationForm;
