import * as React from 'karet';
import * as U from 'karet.util';
import 'typeface-source-sans-pro';
import { feedbackService } from '../../../services/feedback';
import './Feedback.css';

const handleSubmit = (event, yourMessage, reportSent, error, userEmail, userName) => {
  event.preventDefault();
  feedbackService
    .sendFeedback(yourMessage.get(), userEmail.get(), userName.get())
    .then(() => {
      reportSent.set(true);
      error.set(false);
      setTimeout(() => {
        reportSent.set(false);
        error.set(false);
        yourMessage.set('');
      }, 2000);
    })
    .catch(() => {
      error.set(true);
    });
};

export const Feedback = ({ open, config }) => {
  const yourMessage = U.atom('');
  const userEmail = U.atom('');
  const userName = U.atom('');
  const reportSent = U.atom(false);
  const error = U.atom(false);

  let region = 'default';
  if (config) {
    region = config.get().region;
  }

  const submitDisabled = U.combine([yourMessage, reportSent], (message, sent) => {
    return message.length === 0 || sent === true;
  });

  const renderFeedbackForm = () => {
    return (
      <form onSubmit={(event) => handleSubmit(event, yourMessage, reportSent, error, userEmail, userName)}>
        <fieldset>
          <div>
            <label htmlFor="yourMessage">Your message</label>
            <U.TextArea id="yourMessage" rows="10" onChange={() => reportSent.set(false)} value={yourMessage} />
            <div className={'FeedbackMeta'}>
              <div className={'FeedbackMetaTitle'}>
                <span>Please leave your contact details if you may be contacted about your feedback.</span>
              </div>
              <div className={'TextInputFieldWrapper'}>
                <U.Input value={userEmail} placeholder={'email'} type={'email'} className={'TextInputField'} />
                <U.Input value={userName} placeholder={'name'} className={'TextInputField'} />
              </div>
            </div>

            {U.mapValue(
              (visible) => (visible === true ? <div className="ReportSent">Thank you for your feedback!</div> : ''),
              reportSent,
            )}
            {U.mapValue(
              (visible) =>
                visible === true ? <div className="Error">Sending feedback failed, please try again later.</div> : '',
              error,
            )}

            <div className="Buttons">
              {open && (
                <button type="button" onClick={() => open.set(false)}>
                  Close
                </button>
              )}
              <button type="submit" disabled={submitDisabled}>
                SEND
              </button>
            </div>
          </div>
        </fieldset>
      </form>
    );
  };

  const renderUsFeedbackForm = () => {
    return (
      <div className="usForm">
        <iframe
          width="640px"
          height="480px"
          src="https://forms.microsoft.com/e/ZTDpLEH2au?embed=true"
          style={{
            border: 1,
            maxWidth: '100%',
            maxHeight: 100 + 'vh'
          }} allowFullScreen webkitallowfullscreen mozallowfullscreen msallowfullscreen></iframe>
        <div className="additionalContact">
          <p>
          Do you have feedback or questions regarding the ToxBuddy site?  Please email us at <a href="mailto:usatoxbuddy@vetoquinol.com">USAToxBuddy@Vetoquinol.com</a>
          </p>
        </div>
      </div>
    );
  }

  const defaultParagraphs = () => {
    return (
      <p>Help us make ToxBuddy even better. Report a missing substance or tell us what else you are missing.</p>
    );
  }

  const usParagraphs = () => {
    return (
      <p>Please report any missing substances using the form below. Your assistance in keeping ToxBuddy current is greatly appreciated!</p>
    );
  }

  return (
    <section className="Feedback">
      <div className={'FeedbackContent'}>
        <h1 className={'Tagline'}>Feedback</h1>
        { region === 'us' ? usParagraphs() : defaultParagraphs()}
        { region === 'us' ? renderUsFeedbackForm() : renderFeedbackForm()}
      </div>
    </section>
  );
};

export default Feedback;
