import * as React from 'react';
import { FC } from 'react';

interface Props {
  text: string;
  confirmText: string;
  onConfirm: () => void;
  className: string;
  disabled?: boolean;
}

export const ConfirmButton: FC<Props> = ({ text, confirmText, onConfirm, className, disabled = false }) => {
  const [confirmButtonVisible, setConfirmButtonVisible] = React.useState(false);
  const textBlock = text && text.length > 0 ? text : <img src="/images/icons/cross_white.svg" alt="cross" width={10} />;
  const confirmTextBlock =
    confirmText && confirmText.length > 0 ? (
      confirmText
    ) : (
      <img src="/images/icons/minus_white.svg" alt="cross" width={10} />
    );
  React.useEffect(() => {
    if (confirmButtonVisible) {
      setTimeout(() => setConfirmButtonVisible(false), 1500);
    }
  }, [confirmButtonVisible]);
  return (
    <div className={'ConfirmRemoveWrapper'}>
      {confirmButtonVisible && (
        <button className={`${className} ConfirmButton`} onClick={onConfirm}>
          {confirmTextBlock}
        </button>
      )}
      {!confirmButtonVisible && (
        <button disabled={disabled} className={className} onClick={() => setConfirmButtonVisible(true)}>
          {textBlock}
        </button>
      )}
    </div>
  );
};
