import * as U from 'karet.util';
import * as L from 'partial.lenses';

export const Condition = {
  create: ({ name = '', description = '', clinicalSigns = [] } = {}) => ({ name, description, clinicalSigns }),
  name: U.view('name'),
  description: U.view('description'),
  clinicalSigns: U.view('clinicalSigns'),
};

export const Conditions = {
  at: (index, conditions) => U.view([L.index(index)])(conditions),
};
