import * as React from 'karet';
import './LegalDisclaimer.css';

export const Legal = () => {
  return (
    <div className="LegalDisclaimer">
      <h1 className={'Tagline'}>Terms of Use</h1>
      <p>
        These Terms of Use set forth the conditions regarding your use of our ToxBuddy content, including all features
        and functionalities, the website (<a href="https://app.toxbuddy.com">https://app.toxbuddy.com</a>), user
        interfaces, mobile applications and other online platforms and services related to ToxBuddy (collectively,
        “ToxBuddy Service”). By using the ToxBuddy Service, you agree to be bound by our Terms of Use, Privacy Policy
        and Cookie Policy, in addition to other terms and conditions that may appear in the ToxBuddy Service. You
        further agree that you may not use the ToxBuddy Service for any illegal or unauthorized purpose.
      </p>
      <p>
        Orion’s ToxBuddy is a subscription-based online information service for veterinary practices or qualified
        veterinary professionals providing information related to assessment and management of poisoning cases in dogs
        (“ToxBuddy content”). ToxBuddy content can be accessed over the Internet with Internet-ready devices (computers
        and mobile devices).
      </p>
      <p>
        References to “Orion”, “we”, “us” or “our” in this document means Orion Corporation as well as its parent,
        sister, affiliate and subsidiary entities and operational designees.
      </p>

      <br />
      <h2 className="header-add-bottom-margin">1. Subscription</h2>
      <p>
        Once activated, your ToxBuddy subscription will continue until cancelled. To use ToxBuddy Service, you must have
        Internet access and provide us with a valid Payment method, such as your credit card or debit card (see 3.
        Billing).
      </p>
      <p>
        We may offer different subscription types, including special promotional plans or subscriptions offered by third
        parties. Some subscription types may have differing conditions, which will be disclosed at your sign-up. You can
        find the specific details of your ToxBuddy subscription by logging in to the service and choosing the “Account”
        link at the top of the page.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">2. Free trials</h2>
      <p>
        Your ToxBuddy subscription may start with a free trial. The duration of the free trial period will be specified
        during sign-up and displayed on the “Account” page. The free trial is intended to allow new users and certain
        former users to try the service.
      </p>
      <p>
        Eligibility for the free trial is determined by Orion at its sole discretion and we may limit eligibility or
        duration to prevent free trial abuse. We reserve the right to revoke the free trial and put your account on hold
        in the event that we determine that you are not eligible. Users with an existing or recent ToxBuddy subscription
        are generally not eligible. We may use information such as device ID, method of payment, name of the account
        owner or an account email address used with an existing or recent ToxBuddy subscription to determine
        eligibility.
      </p>
      <p>
        We will charge the subscription fee for the next billing period to your Payment method at the end of the free
        trial period unless you cancel your subscription one (1) day before the end of the free trial period. To view
        the end date of your free trial period, log in to the service and choose the “Account” link at the top of the
        page.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">3. Billing</h2>
      <p>
        <b>Price.</b> Your billing price will be displayed upon your sign-up. Value added tax shall be added to the fees
        in accordance with the applicable regulation.
      </p>
      <p>
        <b>Billing period.</b> The subscription fee for the ToxBuddy Service will be charged to your Payment method on
        the specific billing date indicated on your “Account” page. The subscription fee shall be invoiced via Stripe.
        Unless you cancel your subscription one (1) day before your billing date, you authorize us to charge the
        subscription fee for the next billing period to your Payment method. The length of your billing period is
        principally one month, but it may vary depending on the type of your subscription. In some cases, your payment
        date may change, for example if your Payment method has not successfully settled or if your paid subscription
        began on a day not contained in a given month. To see your current billing period, log in to the service and
        choose the “Account” link at the top of the page.
      </p>
      <p>
        <b>Payment method.</b> To use the ToxBuddy Service, you must provide a valid Payment method, such as your credit
        card or debit card. If a payment is not successfully settled, due to expiration, insufficient funds, or
        otherwise, and you do not cancel your account, you remain responsible for any uncollected amounts and we may
        suspend your access to the service until we have successfully charged a valid Payment method. You can update
        your Payment method through the “Account” page. Following any update, you authorize us to continue to charge the
        applicable Payment method.
      </p>
      <p>
        <b>Changes to the price and subscription types.</b> We may change our subscription types and the price of the
        ToxBuddy Service from time to time. Any price changes or changes to your subscription type will apply no earlier
        than 14 days following notice to you.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">4. Cancellation</h2>
      <p>
        You can cancel your ToxBuddy subscription at any time, and you will continue to have access to the service
        through the end of your current billing period. Payments are non-refundable, and we do not provide refunds or
        credits for any partial monthly subscription periods. To cancel, go to the “Account” page and follow the
        instructions for cancellation. If you cancel your subscription, your account will automatically close at the end
        of your current billing period. To see when your account will close, see “Subscription” details on the “Account”
        page.
      </p>
      <p>
        Respectively, Orion may terminate your retail account, membership registration, subscription or similar uses of
        the ToxBuddy Service and/or stop providing access to the ToxBuddy Service for convenience at any time by giving
        you prior written notice via email or in context of ToxBuddy. In addition, Orion reserves the right to change,
        suspend or discontinue all or any aspects of the ToxBuddy Service at any time, without prior notice for
        development, administration or maintenance purposes. Orion may also immediately without notice terminate the
        ToxBuddy Service and/or stop providing access to the ToxBuddy Service if you have materially breached your
        obligations under the terms set out herein or violated applicable laws, or if such action is necessary to
        protect the safety or rights of Orion, other users or third parties. Upon termination, your right to use the
        ToxBuddy Service terminates and you will have no account or similar membership rights to the ToxBuddy Service.
        Orion will not be liable to you or to any third party should it exercise these rights. Orion´s proprietary
        rights, disclaimer of warranties, indemnities, limitations of liability and miscellaneous provisions shall
        survive any such termination. Orion may provide notice of termination by regular mail or e-mail.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">5. ToxBuddy service</h2>
      <p>ToxBuddy is intended to be used by veterinary practices or qualified veterinary professionals.</p>
      <p>
        The ToxBuddy Service and any content accessed through the service are for your professional use only and may not
        be shared with other individuals beyond your ToxBuddy subscription. Your ToxBuddy subscription grants you a
        limited, revocable, non-exclusive, non-transferable right to access the ToxBuddy Service and to view ToxBuddy
        content. Except for the foregoing, no right, title or interest shall be transferred to you by implication or
        otherwise. You agree not to use the results from the ToxBuddy Service for any commercial purposes (except as
        explicitly authorised in these Terms of Use).
      </p>
      <p>
        The number of devices through which you may simultaneously access and use ToxBuddy depends on your chosen
        subscription type (individual practitioner or clinic). Your subscription type can be seen on the “Account” page.
      </p>
      <p>
        THE RESPONSES AND INFORMATION PROVIDED HEREIN MAY BE USED SOLELY FOR INFORMATIONAL AND EDUCATIONAL PURPOSES AS
        GUIDELINES AND THEY DO NOT CONSTITUTE MEDICAL ADVICE, DIAGNOSIS OR TREATMENT ADVICE IN RESPECT OF PETS OR OTHER
        ANIMALS AND SHOULD NOT BE RELIED UPON AS A SUBSTITUTE FOR ANY CLINICAL DIAGNOSIS OR ADVICE OF A LICENSED
        VETERINARIAN. THE PROVISION AND USE OF THE RESPONSES AND INFORMATION HEREOF SHALL NOT CREATE A VETERINARIAN/
        CLIENT RELATIONSHIP.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">6. Intellectual property rights</h2>
      <p>
        You agree not to archive, adapt, reproduce, distribute, modify, display, perform, publish, license, transfer,
        broadcast, create derivative works from, offer for sale, use or otherwise exploit (except as explicitly
        authorised in these Terms of Use) content and information contained in or obtained from or through the ToxBuddy
        Service. You also agree not to circumvent, remove, alter, deactivate, degrade or thwart any of the content
        protections in the ToxBuddy Service; use any automated means to access the ToxBuddy Service; or use any data
        mining, data gathering or extraction method. We may terminate or restrict your use of the ToxBuddy Service if
        you violate these Terms of Use or are engaged in illegal or fraudulent use of the ToxBuddy Service.
      </p>
      <p>
        ToxBuddy Service including, but not limited to, its content, layout, user interface, brand materials and all
        intellectual property rights related to ToxBuddy are protected by copyright in accordance with the applicable
        laws and international agreements and are the exclusive property of Orion and/or its licensors or authorizing
        third parties. All trademarks, service marks, logos and trade names of Orion used in the ToxBuddy Service are
        trademarks or registered trademarks of Orion and protected under trademark law, other intellectual property
        rights and international agreements. You agree not to remove, alter or obscure any copyright, trademark or other
        proprietary rights notices included in the ToxBuddy Service.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">7. Password and account access</h2>
      <p>
        The subscriber who created the ToxBuddy account and whose Payment method is charged (the “Account owner”) has
        access and control over the ToxBuddy account and is responsible for all the activity that occurs through the
        ToxBuddy account. The Account owner is also responsible for any damages or harm resulting from the misuse of the
        ToxBuddy account. To maintain control over the account and to prevent anyone unauthorised from accessing the
        account, the Account owner must not reveal the username and the password to anyone. As an Account owner, you are
        responsible for updating and maintaining the accuracy of the information you provide to us relating to your
        account. The Account owner shall inform Orion immediately if the username and/or password is lost or disclosed
        to a third party. The notification shall be made to{' '}
        <a href="toxbuddy@orionpharma.com">toxbuddy@orionpharma.com</a>. We may terminate your account or place your
        account on hold in order to protect you or ToxBuddy from any fraudulent activity.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">8. Disclaimer of warranties</h2>
      <p>
        YOU EXPRESSLY AGREE THAT YOUR USE OF THE TOXBUDDY SERVICE IS AT YOUR SOLE RISK. TOXBUDDY SERVICE IS PROVIDED ON
        AN “AS IS” AND “AS AVAILABLE” BASIS. ORION AND ITS OFFICERS, DIRECTORS AND EMPLOYEES, AS WELL AS ITS THIRD PARTY
        AGENTS, CONTRACTORS, DISTRIBUTORS, MERCHANTS OR SPONSORS (COLLECTIVELY, “THIRD PARTIES”), CAN NOT AND DO NOT
        WARRANT THAT YOUR USE OF THE TOXBUDDY SERVICE WILL BE UNINTERRUPTED, SECURE OR ERROR-FREE. NEITHER ORION NOR ITS
        THIRD PARTIES HAVE ANY RESPONSIBILITY FOR YOUR ACTION OR INACTION IN CONNECTION WITH THE TOXBUDDY SERVICE OR FOR
        ANY DAMAGE TO YOUR COMPUTER OR DATA OR ANY OTHER DAMAGE YOU MAY INCUR IN CONNECTION WITH YOUR USE OF THE
        TOXBUDDY SERVICE. NEITHER ORION NOR ITS THIRD PARTIES WARRANT THE ACCURACY, INTEGRITY OR COMPLETENESS OF THE
        CONTENT PROVIDED IN THE TOXBUDDY SERVICE OR THE PRODUCTS OR SERVICES OFFERED BY THE TOXBUDDY SERVICE. ORION AND
        ITS THIRD PARTIES SPECIFICALLY DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF
        TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. NO ORAL OR WRITTEN
        INFORMATION OR ADVICE GIVEN BY ORION OR ITS THIRD PARTIES SHALL CREATE A WARRANTY, EXCEPT WHERE THE EXCLUSION OR
        LIMITATION OF CERTAIN WARRANTIES IS PROHIBITED BY LAW.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">9. Limitation of Liability</h2>
      <p>
        UNDER NO CIRCUMSTANCES SHALL ORION OR ITS THIRD PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL
        OR CONSEQUENTIAL DAMAGES THAT RESULT FROM YOUR USE OR INABILITY TO USE THE TOXBUDDY SERVICE, INCLUDING YOUR
        RELIANCE ON ANY INFORMATION OBTAINED FROM THE TOXBUDDY SERVICE THAT RESULTS IN MISTAKES, OMISSIONS,
        INTERRUPTIONS, DELETION OR CORRUPTION OF FILES, VIRUSES, DELAYS IN OPERATING OR TRANSMISSIONS OR ANY FAILURE OF
        PERFORMANCE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY IN ANY ACTION, WHETHER IN CONTRACT, TORT OR ANY
        OTHER CLAIM, EVEN IF AN AUTHORIZED REPRESENTATIVE OF ORION HAS BEEN ADVISED OF OR SHOULD HAVE KNOWLEDGE OF THE
        POSSIBILITY OF SUCH DAMAGES. BY YOUR USE OF THE TOXBUDDY SERVICE, YOU ACKNOWLEDGE THAT THIS PARAGRAPH SHALL
        APPLY TO ALL CONTENT AND SERVICES AVAILABLE THROUGH THE TOXBUDDY SERVICE, EXCEPT WHERE THE EXCLUSION OR
        LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES IS PROHIBITED BY LAW. SOME JURISDICTIONS DO NOT ALLOW THE
        EXCLUSION OF CERTAIN WARRANTIES OR LIMITATION OF DAMAGES FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE
        FOREGOING LIMITATIONS MAY NOT APPLY. IN ANY EVENT, UNDER NO CIRCUMSTANCES WILL ORION BE LIABLE TO YOU FOR ANY
        AMOUNT EXCEEDING ONE THOUSAND EUROS (1,000.00€).
      </p>
      <br />

      <h2 className="header-add-bottom-margin">10. Indemnity</h2>
      <p>
        You agree to indemnify and hold us and our officers, agents, employees and other partners and third parties
        harmless from any loss, liability, claim or demand, including reasonable attorney's fees, made by any other
        third party due to or arising out of your use of the ToxBuddy Service, including your use of the ToxBuddy
        Service to provide veterinary services or similar services to any third parties.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">11. Miscellaneous</h2>
      <p>
        <b>Governing Law and disputes.</b> ToxBuddy Service and these Terms of Use shall be governed by and construed in
        accordance with the laws of Finland. If there is any dispute regarding ToxBuddy Service and these Terms of Use,
        you agree that the dispute will be governed by the laws of Finland without regard to its conflict of law
        provisions. With respect to any disputes arising out of or in connection with the ToxBuddy Service or these Term
        of Use, including the validity or breach thereof, you agree to jurisdiction and venue in the District Court of
        Helsinki.
      </p>
      <p>
        <b>Customer support.</b> To find more information about the ToxBuddy Service and its features or if you need
        assistance with your account, please contact us using the contact information below or on our website.
      </p>
      <p>
        <b>Survival.</b> If any provision or provisions of these Terms of Use shall be held to be invalid, illegal, or
        unenforceable, the validity, legality and enforceability of the remaining provisions shall remain in full force
        and effect.
      </p>
      <p>
        <b>Changes & updates.</b> Errors will be corrected where discovered and we reserve the right to revoke any
        stated offer and to correct any errors, inaccuracies or omissions at any given time. We reserve the right to
        make changes or updates to our Terms of Use at any time without notice and without liability to you or any other
        participating third party. As a user of the ToxBuddy Service, you are responsible for regularly reviewing our
        Terms of Use in order to remain informed of our practices and your obligations as you visit and use the ToxBuddy
        Service. Our current Terms of Use constitute the entire agreement between you and Orion as you use the ToxBuddy
        Service and supersede all prior understandings or agreements, written or oral, in this regard. If you do not
        agree to be bound by the then current Terms of Use, your sole remedy is not to use the ToxBuddy Service. We are
        not responsible for typographical or photographic errors in the ToxBuddy Service.
      </p>
      <p>
        <b>Electronic communications.</b> We will send you information relating to your account (e.g. payment
        authorisations, invoices, changes in password or Payment method, confirmation messages, notices) in electronic
        form only, for example via emails to your email address provided during signup.
      </p>
      <br />

      <h2 className="header-add-bottom-margin">12. Contact information</h2>
      <p>
        If you have questions about the ToxBuddy Service or our Terms of Us, contact us here:{' '}
        <a href="mailto:toxbuddy@orionpharma.com">toxbuddy@orionpharma.com</a>
      </p>
      <br />
      <p>Last updated: June 30, 2020</p>
    </div>
  );
};
