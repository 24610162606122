import * as React from 'karet';
import * as U from 'karet.util';

export const EditorTextArea = ({
  inheritedValue,
  inheritedTitle,
  currentValue,
  disabled,
  onChange,
  className,
  inheritedFrom,
  dataCy,
}) => {
  const showInherited = U.atom(false);
  const inheritedVisible = U.combine(
    [currentValue, inheritedValue],
    (value, inherited) => (!value || value.length === 0) && inherited && inherited.length > 0,
  );
  const inheritedLink = U.mapValue(
    (visible) =>
      visible ? (
        <div className={'InheritedLink'} onClick={() => showInherited.set(!showInherited.get())}>
          {inheritedTitle}
        </div>
      ) : (
        ''
      ),
    inheritedVisible,
  );
  const inheritedContent = U.mapValue(
    (show) => (show ? <div className={'InheritedContent'}>{inheritedValue}</div> : ''),
    showInherited,
  );
  const inheritedFromContent = U.mapValue(
    (show) => (show ? <div className={'InheritedFrom'}>Inherited from: {inheritedFrom}</div> : ''),
    showInherited,
  );
  return (
    <div data-cy={dataCy} className={`EditorTextArea ${className}`}>
      {inheritedLink}
      {inheritedContent}
      {inheritedFromContent}
      <textarea rows={10} value={currentValue} onChange={(evt) => onChange(evt.target.value)} disabled={disabled} />
    </div>
  );
};
