import React, { useState } from 'react';
import * as R from 'ramda';
import * as analytics from '../../services/analytics';
import { updateBillingInformation } from '../../services/billingDetails';
import { Loader } from '../Components/Loader';
import { stateList, UsStateSelect } from './UsStateSelect';

export const UsBillingInformationUpdateForm = ({ billingDetails, onCancel, onUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [companyName, setCompanyName] = useState(billingDetails.companyName);
  const [state, setState] = useState(R.find((c) => c.value === billingDetails.state, stateList) || '');
  const [streetAddress, setStreetAddress] = useState(billingDetails.streetAddress);
  const [postalCode, setPostalCode] = useState(billingDetails.postalCode);
  const [city, setCity] = useState(billingDetails.city);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    setFailed(false);

    const billingInformation = {
      companyName,
      countryCode: 'US',
      streetAddress,
      postalCode,
      city,
      state: state.value,
    };

    updateBillingInformation(billingInformation)
      .then(() => {
        setIsLoading(false);
        onUpdate();
      })
      .catch((err) => {
        setIsLoading(false);
        setFailed(true);
        analytics.error(err.status + ': Unknown error');
      });
  };

  const getMissingFieldNames = () => {
    const missingFields = [];
    R.isEmpty(companyName) && missingFields.push(["'Company name'"]);
    R.or(R.isEmpty(state), R.isNil(state)) && missingFields.push(["'State'"]);
    R.isEmpty(streetAddress) && missingFields.push(["'Street address'"]);
    R.isEmpty(postalCode) && missingFields.push(["'Postal code'"]);
    R.isEmpty(city) && missingFields.push(["'City'"]);
    return missingFields;
  };

  const onFieldChanged = (field) => (evt) => {
    const { value } = evt.target;
    switch (field) {
      case 'companyName':
        setCompanyName(value);
        break;
      case 'streetAddress':
        setStreetAddress(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      default:
        throw Error(`Unknown field: ${field}`);
    }
  };

  const onStateChanged = (value) => {
    setState(value ? value : null);
  };

  const isFormValid = companyName && streetAddress && postalCode && city && state !== null;

  const isModified =
    billingDetails.companyName !== companyName ||
    billingDetails.streetAddress !== streetAddress ||
    billingDetails.postalCode !== postalCode ||
    billingDetails.city !== city ||
    billingDetails.state !== state.value;
  const submitEnabled = isFormValid && isModified && !isLoading;

  return (
    <form onSubmit={handleSubmit}>
      <section>
        <div className="BillingAddressForm">
          <label>
            Clinic or Business Name
            <input type="text" value={companyName || ''} onChange={onFieldChanged('companyName')} />
          </label>
          <label>
            Billing Address
            <input type="text" value={streetAddress || ''} onChange={onFieldChanged('streetAddress')} />
          </label>
          <label className="OneThird">
            City
            <input type="text" value={city || ''} onChange={onFieldChanged('city')} />
          </label>
          <label className="OneThird">
            State
            <UsStateSelect value={state} onChange={onStateChanged} />
          </label>
          <label className="OneThird">
            Zip Code
            <input type="text" value={postalCode || ''} onChange={onFieldChanged('postalCode')} />
          </label>
        </div>
      </section>
      {failed && (
        <div className="ErrorText">There was a problem updating billing information. Please contact support.</div>
      )}
      {isLoading && <Loader />}
      <div>
        <button type="submit" disabled={!submitEnabled}>
          Update billing information
        </button>
        <button type="button" onClick={() => onCancel()} className="CancelButton">
          Cancel
        </button>
      </div>
      {!isFormValid && !isLoading && (
        <div className="validationErrorText">Please fill all required fields: {getMissingFieldNames().join(', ')}</div>
      )}
    </form>
  );
};
