import React, { useState } from 'react';
import * as R from 'ramda';
import * as analytics from '../../services/analytics';
import { updateBillingInformation } from '../../services/billingDetails';
import DefaultBillingInformationForm from './DefaultBillingInformationForm';
import countryList from 'react-select-country-list';
import { Loader } from '../Components/Loader';

export const DefaultBillingInformationUpdateForm = ({ billingDetails, onCancel, onUpdate }) => {
  const countries = countryList().getData();
  const [isLoading, setIsLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const [companyName, setCompanyName] = useState(billingDetails.companyName);
  const [country, setCountry] = useState(R.find((c) => c.value === billingDetails.countryCode, countries) || '');
  const [vatId, setVatId] = useState(billingDetails.vatId);
  const [streetAddress, setStreetAddress] = useState(billingDetails.streetAddress);
  const [postalCode, setPostalCode] = useState(billingDetails.postalCode);
  const [city, setCity] = useState(billingDetails.city);
  const handleSubmit = (evt) => {
    evt.preventDefault();
    setIsLoading(true);
    setFailed(false);
    const billingInformation = {
      companyName,
      countryCode: country.value,
      vatId,
      streetAddress,
      postalCode,
      city,
    };
    updateBillingInformation(billingInformation)
      .then(() => {
        setIsLoading(false);
        onUpdate();
      })
      .catch((err) => {
        setIsLoading(false);
        setFailed(true);
        analytics.error(err.status + ': Unknown error');
      });
  };
  const getMissingFieldNames = () => {
    const missingFields = [];
    R.isEmpty(companyName) && missingFields.push(["'Company name'"]);
    R.or(R.isEmpty(country), R.isNil(country)) && missingFields.push(["'Country'"]);
    R.or(R.isEmpty(vatId), R.isNil(vatId)) && missingFields.push(["'VAT number'"]);
    R.isEmpty(streetAddress) && missingFields.push(["'Street address'"]);
    R.isEmpty(postalCode) && missingFields.push(["'Postal code'"]);
    R.isEmpty(city) && missingFields.push(["'City'"]);
    return missingFields;
  };
  const onFieldChanged = (field) => (evt) => {
    const { value } = evt.target;
    switch (field) {
      case 'companyName':
        setCompanyName(value);
        break;
      case 'streetAddress':
        setStreetAddress(value);
        break;
      case 'postalCode':
        setPostalCode(value);
        break;
      case 'city':
        setCity(value);
        break;
      default:
        throw Error(`Unknown field: ${field}`);
    }
  };
  const onCountryChanged = (value) => {
    setCountry(value ? value : null);
  };
  const onVatIdChanged = (value) => setVatId(value ? value.toUpperCase() : null);
  const isFormValid = companyName && country && vatId && streetAddress && postalCode && city;
  const isModified =
    isFormValid &&
    (billingDetails.companyName !== companyName ||
      billingDetails.countryCode !== country.value ||
      billingDetails.vatId !== vatId ||
      billingDetails.city !== city ||
      billingDetails.postalCode !== postalCode ||
      billingDetails.city !== city);
  const submitEnabled = isFormValid && isModified && !isLoading;
  return (
    <form onSubmit={handleSubmit}>
      <section>
        <DefaultBillingInformationForm
          onFieldChanged={onFieldChanged}
          onCountryChanged={onCountryChanged}
          onVatIdChanged={onVatIdChanged}
          companyName={companyName}
          country={country}
          vatId={vatId}
          streetAddress={streetAddress}
          postalCode={postalCode}
          city={city}
        />
      </section>
      {failed && (
        <div className="ErrorText">There was a problem updating billing information. Please contact support.</div>
      )}
      {isLoading && <Loader />}
      <div>
        <button type="submit" disabled={!submitEnabled}>
          Update billing information
        </button>
        <button type="button" onClick={() => onCancel()} className="CancelButton">
          Cancel
        </button>
      </div>
      {!isFormValid && !isLoading && (
        <div className="validationErrorText">Please fill all required fields: {getMissingFieldNames().join(', ')}</div>
      )}
    </form>
  );
};
