import * as React from 'karet';
import * as U from 'karet.util';
import { user as userService } from '../../services/user';
import * as authService from '../../services/auth';
import * as analytics from '../../services/analytics';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import './LoginPage.css';
import { FC, FormEvent } from 'react';
import { Atom } from 'karet.util';
import { History } from 'history';
import { User } from '../../types';

const handleSubmit = (
  event: FormEvent,
  email: Atom<string>,
  password: Atom<string>,
  user: Atom<User | null>,
  loginError: Atom<string | null>,
  history: History,
) => {
  event.preventDefault();
  loginError.set(null);

  return authService
    .login(email.get(), password.get())
    .then((loginUser) => {
      analytics.login(email.get());
      user.set(loginUser);
      history.push('/');
    })
    .catch((status) => {
      if (status === 401) {
        loginError.set('Invalid credentials. Please try again.');
      }
      user.set(null);
    });
};

const handleChange = (loginError: Atom<string | null>, passwordResetSent: Atom<boolean>) => {
  passwordResetSent.set(false);
  loginError.set('');
};

interface LoginPageProps extends RouteComponentProps {
  user: Atom<User | null>;
}

export const LoginPage: FC<LoginPageProps> = ({ user, history }) => {
  const formValues = U.atom({ email: '', password: '' });
  const email = U.view('email', formValues);
  const password = U.view('password', formValues);
  const loginError = U.atom<string | null>(null);
  const passwordResetSent = U.atom(false);
  const loading = U.atom(false);

  const loginDisabled = U.combine([email, password], (e, p) => e.length === 0 || p.length === 0);

  return (
    <section className={['Login', 'PageContainer'].join(' ')}>
      <div className="Loading" key={'Loading'} style={{ display: loading.get() ? 'block' : 'none' }}>
        <div className="Background" />
        <div className="Text">Creating user and subscription...</div>
      </div>
      <div className={'TaglineWrapper'}>
        <img src="/images/TB.svg" alt="ToxBuddy" className={'LoginPageLogo'} />
        <h1 className={'Tagline'}>Poisoning management</h1>
        <h2 className={'SubTagline'}>Online service for vets</h2>
      </div>
      <form onSubmit={(event) => handleSubmit(event, email, password, user, loginError, history)}>
        <fieldset>
          <div className="grid">
            <label htmlFor="email">Email</label>
            <label htmlFor="password">Password</label>
            <U.Input
              autoFocus
              data-cy="emailInput"
              id="email"
              type="email"
              value={email}
              onChange={() => handleChange(loginError, passwordResetSent)}
            />
            <U.Input
              data-cy="passwordInput"
              id="password"
              type="password"
              value={password || ''}
              onChange={() => handleChange(loginError, passwordResetSent)}
            />
            <div className={'Buttons'}>
              <button className={'LoginButton'} disabled={loginDisabled} type="submit" data-cy="loginButton">
                Log in
              </button>
              <div className="PasswordResetContainer">
                <button
                  data-cy="forgotPasswordButton"
                  className={'ForgotPasswordButton'}
                  type="button"
                  onClick={() => {
                    history.push('/forgot_password');
                  }}
                >
                  Forgot Password?
                </button>
                <div className="SignUpLinkContainer">
                  <a className="SignUpLink" href="/signup">
                    Sign Up
                  </a>{' '}
                  (Not a customer?)
                </div>
              </div>
            </div>
            {U.mapValue(
              (error: string | null) =>
                error ? (
                  <div data-cy="invalidCredentialsError" className={'InvalidCredentials'}>
                    {error} --- Or <a href="/signup">Sign Up here</a>{' '}
                  </div>
                ) : (
                  ''
                ),
              loginError,
            )}
            {U.mapValue(
              (visible: boolean) =>
                visible ? (
                  <div className={'PasswordResetSent'}>Password reset link was sent to the provided email.</div>
                ) : (
                  ''
                ),
              passwordResetSent,
            )}
          </div>
        </fieldset>
      </form>
    </section>
  );
};

export default withRouter(LoginPage);
