import * as React from 'karet';
import { CaseDetails } from './CaseDetails/CaseDetails';
import './MainView.css';
import { CheckToxinCalculatorAccess } from '../Components/CheckToxinCalculatorAccess';

const MainView = ({ user, selectedSubstance, substanceWithDose, substances, conditions, clinicalSigns }) => {
  const adminUser = user.get() !== null && user.get().role === 'Admin';

  return (
    <section className="MainView">
      <div className="MainViewLayout">
        <section className="CaseDetailsWrapper">
          <CheckToxinCalculatorAccess user={user}>
            <CaseDetails
              substanceWithDose={substanceWithDose}
              adminUser={adminUser}
              selectedSubstance={selectedSubstance}
              substances={substances}
              conditions={conditions}
              clinicalSigns={clinicalSigns}
            />
          </CheckToxinCalculatorAccess>
        </section>
      </div>
    </section>
  );
};

export default MainView;
