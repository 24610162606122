import * as React from 'react';
import { ChangeEvent, FC, FormEvent, SetStateAction } from 'react';
import queryString from 'query-string';
import { user as userService } from '../../services/user';
import './PasswordResetPage.css';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Loader } from '../Components/Loader';

const labels = {
  email: 'Email',
  password: 'New Password',
  passwordAgain: 'Repeat password',
  submitReset: 'Reset password',
  submitActivation: 'Activate account',
};

const handleSubmit =
  (
    email: string,
    password: string,
    hash: string,
    actions: {
      setPasswordResetFailed: React.Dispatch<SetStateAction<boolean>>;
      setPasswordResetDone: React.Dispatch<SetStateAction<boolean>>;
      setLoading: React.Dispatch<SetStateAction<boolean>>;
    },
  ) =>
  (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    actions.setLoading(true);
    return userService
      .resetPassword(email, password, hash)
      .then(() => {
        actions.setPasswordResetDone(true);
      })
      .catch(() => {
        actions.setPasswordResetFailed(true);
      })
      .finally(() => {
        actions.setLoading(false);
      });
  };

const validate = (email: string, password: string, passwordAgain: string): string[] => {
  let missingFields: string[] = [];
  let errors: string[] = [];

  if (email.length === 0) {
    missingFields = missingFields.concat(`'${labels.email}'`);
  }
  if (password.length === 0) {
    missingFields = missingFields.concat(`'${labels.password}'`);
  }
  if (missingFields.length > 0) {
    errors = errors.concat(`Please fill all required fields: ${missingFields.join(', ')}`);
    return errors;
  }
  if (password.length > 0 && password.length < 4) {
    errors = errors.concat('Password is too short. Must be at lear 4 characters.');
  }
  if (password.length >= 4 && password !== passwordAgain) {
    errors = errors.concat('Passwords do not match.');
  }
  return errors;
};

export const PasswordResetPage: FC<RouteComponentProps> = ({ location }) => {
  const { pathname, search } = location;
  const isActivateAccount = pathname === '/activate_account';

  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordAgain, setPasswordAgain] = React.useState('');
  const [passwordResetFailed, setPasswordResetFailed] = React.useState(false);
  const [passwordResetDone, setPasswordResetDone] = React.useState(false);
  const [isLoading, setLoading] = React.useState(false);

  const validationErrors = React.useMemo(
    () => validate(email, password, passwordAgain),
    [email, password, passwordAgain],
  );

  const queryParams = queryString.parse(search);
  const { hash } = queryParams;

  const setField = (dispatch: React.Dispatch<SetStateAction<string>>) => (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(e.target.value);
    setPasswordResetFailed(false);
  };

  return (
    <section className={['PasswordReset', 'PageContainer'].join(' ')}>
      {typeof hash !== 'string' ? (
        <pre>Invalid params</pre>
      ) : passwordResetDone ? (
        <div>
          Your {isActivateAccount ? 'account is now activated' : 'password is now reset'} and you can login in the{' '}
          <a href="/login">login page</a>.
        </div>
      ) : (
        <>
          <h1>{isActivateAccount ? 'Activate your ToxBuddy account' : 'Change your ToxBuddy password'}</h1>

          <form
            onSubmit={handleSubmit(email, password, hash, { setPasswordResetFailed, setPasswordResetDone, setLoading })}
          >
            <fieldset>
              <div className="grid">
                <label htmlFor="email">{labels.email}</label>
                <input autoFocus id="email" type="email" value={email} onChange={setField(setEmail)} />
                <label htmlFor="password">{labels.password}</label>
                <input id="password" type="password" value={password} onChange={setField(setPassword)} />
                <label htmlFor="passwordAgain">{labels.passwordAgain}</label>
                <input id="passwordAgain" type="password" value={passwordAgain} onChange={setField(setPasswordAgain)} />
                <div>
                  {validationErrors.map((e, i) => (
                    <div className="validationError" key={i}>
                      {e}
                    </div>
                  ))}
                </div>
                <button disabled={validationErrors.length > 0} type="submit" data-cy="pwResetSubmitButton">
                  {isActivateAccount ? labels.submitActivation : labels.submitReset}
                </button>
                {isLoading && <Loader />}
                {passwordResetFailed ? (
                  <div className="failed" data-cy="pwResetFailureMessage">
                    {isActivateAccount ? 'Account activation' : 'Password reset'} failed. Reason may be one of:
                    <br />
                    1) Email address you entered is not the one in which you received the{' '}
                    {isActivateAccount ? 'activation' : 'reset'} email and link.
                    <br />
                    2) The link you used to open this page is expired. Please use the 'Reset password' link in{' '}
                    <a href="/login" target="_blank">
                      login page
                    </a>{' '}
                    to receive a new email with valid link.
                  </div>
                ) : (
                  ''
                )}
              </div>
            </fieldset>
          </form>
        </>
      )}
    </section>
  );
};

export default withRouter(PasswordResetPage);
