import { handleJsonResponse } from './common';

function sendFeedback(message, userEmail, userName) {
  const body = { message, userEmail, userName };
  return fetch('/api/feedback', {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(handleJsonResponse);
}

export const feedbackService = {
  sendFeedback,
};
