import * as React from 'karet';
import * as R from 'ramda';
import * as U from 'karet.util';
import './ToxicityGraph.css';

const toxicityCategories = {
  NonToxic: 'Non-toxic',
  Mild: 'Mild',
  Moderate: 'Moderate',
  High: 'High toxicity',
  Lethal: 'Life-threatening',
  Unknown: 'Toxic dose unknown',
  CaseSpecific: 'Case-specific',
};

const colors = {
  Unknown: 'var(--unknown)',
  CaseSpecific: 'var(--unknown)',
  NonToxic: 'var(--non-toxic)',
  Mild: 'var(--mild)',
  Moderate: 'var(--moderate)',
  High: 'var(--high)',
  Lethal: 'var(--lethal)',
};

const getDoseToxinName = (substanceWithDose) => substanceWithDose.descriptionToxinName || substanceWithDose.toxinName;

const ToxicityCategory = ({ ingested }) => {
  return (
    <div key="category" className="Category">
      {toxicityCategories[ingested.toxicityCategory]}
    </div>
  );
};

const DoseToxinName = ({ substanceWithDose, className }) => (
  <div key="substanceWithDose" className={className}>
    {getDoseToxinName(substanceWithDose)}
  </div>
);

const DoseAmount = ({ substanceWithDose }) => {
  const amount = substanceWithDose.ingested.amountPerKg;
  const [integer, fraction] = (amount >= 1 ? amount.toFixed(2) : amount.toPrecision(2)).split('.');
  return [
    <div key="number" className="Number">
      {integer}.
    </div>,
    <div key="fractional" className="Fractional">
      {fraction}
    </div>,
    <div key="unit" className="Unit">
      {substanceWithDose.ingested.unit + '/kg'}
    </div>,
  ];
};

const getIndicatorPosition = (amountPerKg, percentages) => {
  return Math.max(1, Math.min((amountPerKg * 100) / R.last(percentages).threshold, 99));
};

const update = (substance, indicatorPos, percentages, toxicityLevels) => {
  const levels = R.isNil(substance.toxicityLevels) ? [] : substance.toxicityLevels;
  toxicityLevels.set(levels);
  const calculatedPercentages = calculatePercentages(levels);
  percentages.set(calculatedPercentages);
  indicatorPos.set(getIndicatorPosition(substance.ingested.amountPerKg, calculatedPercentages));
};

const renderIngredientDoses = (substance) => {
  const hasSingleDescriptionParentIngredient =
    substance.ingredients &&
    substance.ingredients.length === 1 &&
    substance.descriptionToxinName === substance.ingredients[0].toxinName;
  const ingredients = substance.ingredients || [];

  return hasSingleDescriptionParentIngredient
    ? []
    : ingredients.reduce(
        (acc, ingredient) =>
          acc.concat([
            <DoseToxinName key={getDoseToxinName(ingredient)} substanceWithDose={ingredient} className="SubDose" />,
            <DoseAmount key={`${getDoseToxinName(ingredient)}amount`} substanceWithDose={ingredient} />,
          ]),
        [],
      );
};

const conditionsToDescriptions = (levels) => {
  return levels
    .filter((level) => level.threshold !== 0)
    .reduce((conds, level) => {
      const startIndex = R.keys(conds).length;

      if (R.isNil(level.conditions) || level.conditions.length === 0) {
        return conds;
      } else if (level.conditions.length === 1) {
        const cond = level.conditions[0];
        return R.mergeRight(conds, R.fromPairs([[cond.name, { threshold: level.threshold, index: startIndex }]]));
      } else {
        const condName = level.conditions.map((cond) => cond.name).join(', ');
        return R.mergeRight(conds, R.fromPairs([[condName, { threshold: level.threshold, index: startIndex }]]));
      }
    }, {});
};

const levelsToDescriptions = (levels) => {
  return levels
    .filter((level) => level.threshold !== 0)
    .reduce((descriptions, level) => {
      const category = R.propOr(level.category, level.category, toxicityCategories);
      return R.mergeRight(descriptions, {
        [category]: { threshold: level.threshold, index: R.keys(descriptions).length },
      });
    }, {});
};

const getCategoryThreshold = (name, levels) => {
  const thresholds = levels.filter((level) => level.category === name).map((level) => level.threshold);
  const max = Math.max(...thresholds);
  return isNaN(max) ? 0 : max;
};

const completeLevels = (levels) => {
  const allLevels =
    levels.length === 0 || levels[0].threshold > 0 ? [{ category: 'Unknown', threshold: 0 }].concat(levels) : levels;
  return allLevels.map(R.prop('category')).map((categoryName) => {
    return {
      name: categoryName,
      threshold: getCategoryThreshold(categoryName, allLevels),
      original: levels.find((level) => level.category === categoryName) !== undefined,
    };
  });
};

const calculatePercentages = (levels) => {
  const allLevels = completeLevels(levels);
  const max = R.last(allLevels).threshold;
  allLevels.forEach((level, index) => {
    const threshold = level.threshold;
    let i = index;
    let j = index;
    while (j > 0 && allLevels[j].threshold === threshold) j--;
    while (i < allLevels.length - 1 && allLevels[i].threshold === threshold) i++;
    const fixedThreshold =
      i - j > 1 && !level.original
        ? (allLevels[i].threshold - threshold) * ((index - j) / Math.max(1.0, i - j)) + threshold
        : threshold;

    const percentage = (fixedThreshold * 100.0) / max;
    allLevels[index] = R.mergeRight(level, {
      threshold: fixedThreshold,
      percentage: isNaN(percentage) ? 100 : percentage,
    });
  });
  return allLevels;
};

const descriptionsFromLevels = (levels) => {
  const conditionDescriptions = conditionsToDescriptions(levels);
  const levelDescriptions = levelsToDescriptions(levels);

  return R.keys(conditionDescriptions).length > 0 ? conditionDescriptions : levelDescriptions;
};

export const ToxicityGraph = ({ substanceWithDose }) => {
  const indicatorPos = U.atom(0);
  const percentages = U.atom([]);
  const toxicityLevels = U.atom([]);

  substanceWithDose
    .filter((substance) => !R.isNil(substance))
    .observe((substance) => update(substance, indicatorPos, percentages, toxicityLevels));

  const gradientDescription = U.mapValue((array) => {
    if (R.isNil(array)) {
      return 'transparent';
    }
    // Special case with only one level
    if (array.length === 1 && array[0].threshold === 0) {
      const singleCategory = array[0];
      const colorString = `${colors[singleCategory.name]} 0%,${colors[singleCategory.name]} ${
        singleCategory.percentage
      }%`;
      return `linear-gradient(to right, ${colorString})`;
    }
    const colorString = array.map((category) => `${colors[category.name]} ${category.percentage}%`).join(',');
    return `linear-gradient(to right, ${colorString})`;
  }, percentages);

  const unit = substanceWithDose.map((substance) => (substance ? R.path(['ingested', 'unit'], substance) : ''));

  const conditionsWithUnit = U.combine([unit, toxicityLevels], (unit, levels) => {
    const descriptions = descriptionsFromLevels(levels);
    return R.keys(descriptions).map((condition, index) => {
      const threshold = descriptions[condition].threshold;
      const pos = getIndicatorPosition(threshold, percentages.get());
      const wrapperHeight = index === 0 ? 40 : (index + 1) * 30;
      const contentText = `${condition} ${threshold}${unit}/kg`;
      const content = (
        <div className="Condition">
          {condition} {threshold}
          {unit}/kg
        </div>
      );
      const isRightSide = pos > 50;
      const sideClassName = isRightSide ? 'RightSide' : 'LeftSide';
      const zIndex = 1000 - index;
      const contentWidth = (text) => {
        const c = document.createElement('canvas');
        const ctx = c.getContext('2d');
        ctx.font = "12px 'Source Sans Pro'";
        return ctx.measureText(text).width + 10;
      };

      const calculatedPos = isRightSide ? 'calc(' + pos + '% - ' + contentWidth(contentText) + 'px)' : `${pos}%`;
      return (
        <div
          key={contentText}
          className={`ToxicityGraphConditionWrapper ${sideClassName}`}
          style={{ left: `${calculatedPos}`, height: `${wrapperHeight}px`, zIndex: zIndex }}
        >
          {content}
        </div>
      );
    });
  });

  const infoBox = U.mapValue(
    (substance) =>
      substance ? (
        <div className={`Box ${substance.ingested.toxicityCategory}`}>
          <ToxicityCategory ingested={substance.ingested} />
          <div className="DoseContainer">
            <DoseToxinName substanceWithDose={substance} className="Dose" />
            <DoseAmount substanceWithDose={substance} />
            {renderIngredientDoses(substance)}
          </div>
          {substance.ingested.description && (
            <div key="description" className={'DoseIngestedDescription'}>
              {substance.ingested.description}
            </div>
          )}
        </div>
      ) : (
        ''
      ),
    substanceWithDose,
  );

  const content = U.combine([substanceWithDose, indicatorPos, toxicityLevels], (substance, indicator, levels) => {
    const descriptions = descriptionsFromLevels(levels);
    const marginBottom = R.keys(descriptions).length * 30;

    return substance ? (
      <div className="ToxicityGraph">
        <div className="Gradient" style={{ backgroundImage: gradientDescription, marginBottom: `${marginBottom}px` }}>
          {conditionsWithUnit}
          <div className="Indicator" style={{ left: `${indicator}%` }} />
          <div className="Dose" style={{ left: `${indicator}%` }}>
            <DoseAmount substanceWithDose={substance} isPercentage={false} />
          </div>
        </div>
        {infoBox}
      </div>
    ) : (
      <div />
    );
  });

  return <div>{content}</div>;
};

export default ToxicityGraph;
