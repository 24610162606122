import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'typeface-source-sans-pro';
import 'normalize.css';
import './index.css';

if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  document.getElementById('root')!.innerHTML =
    '<div style="background: rgb(255,254,127); padding: 20px; position: absolute; top: 0; left: 0; right: 0; width: 100%; display: block;">' +
    'You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience and security. </div>';
} else {
  require('./appLoader.tsx');
}
