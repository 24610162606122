import * as React from 'karet';
import * as U from 'karet.util';
import { EditableList } from '../../Components/EditableList';

export const EditorEditableList = ({ inheritedItems, inheritedTitle, items, className, disabled, inheritedFrom }) => {
  const showInherited = U.atom(false);
  const inheritedVisible = U.combine(
    [items, inheritedItems],
    (value, inherited) => (!value || value.length === 0) && inherited && inherited.length > 0,
  );
  const inheritedLink = U.mapValue(
    (visible) =>
      visible ? (
        <div className={'InheritedLink'} onClick={() => showInherited.set(!showInherited.get())}>
          {inheritedTitle}
        </div>
      ) : (
        ''
      ),
    inheritedVisible,
  );
  const inheritedContentList = U.mapValue(
    (items) => (items && items.length > 0 ? <div>{items.join(', ')}</div> : ''),
    inheritedItems,
  );
  const inheritedContent = U.mapValue(
    (show) => (show ? <div className={'InheritedContent'}>{inheritedContentList}</div> : ''),
    showInherited,
  );
  const inheritedFromContent = U.mapValue(
    (show) => (show ? <div className={'InheritedFrom'}>Inherited from: {inheritedFrom}</div> : ''),
    showInherited,
  );
  return (
    <div className={`${className}-wrapper`}>
      {inheritedLink}
      {inheritedContent}
      {inheritedFromContent}
      <EditableList className={className} key={className} disabled={disabled} items={items} />
    </div>
  );
};
