import React, { ChangeEvent, FC } from 'react';
import { SubscriptionPrice } from './SubscriptionPrice';
import './SignUpForm.css';
import * as pricingService from '../../../services/pricing';
import { DefaultRegionSubscriptionType, StripeCoupon, StripePrice } from './types';
import { CouponCode } from './CouponCode';

interface Props {
  onSubscriptionTypeChanged?: (t: DefaultRegionSubscriptionType) => void;
  onCouponIdChanged: (id: string) => void;
  onPriceIdChanged: (id: string) => void;
  showFreeTrialText?: boolean;
}

export const DefaultRegionSubscriptionTypeSelector: FC<Props> = ({
  onSubscriptionTypeChanged = () => undefined,
  onCouponIdChanged,
  onPriceIdChanged,
  showFreeTrialText = true,
}) => {
  const [subscriptionType, setSubscriptionType] = React.useState<DefaultRegionSubscriptionType>('individual');
  const [clinicSize, setClinicSize] = React.useState(1);
  const [price, setPrice] = React.useState<StripePrice | null>(null);
  const [loadingPrice, setLoadingPrice] = React.useState(false);
  const [coupon, setCoupon] = React.useState<StripeCoupon | null>(null);

  const subscriptionTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    const v = e.target.value as DefaultRegionSubscriptionType;
    setSubscriptionType(v);
    onSubscriptionTypeChanged(v);
  };

  const onCouponChanged = (newCoupon: StripeCoupon | null) => {
    if (newCoupon !== null) {
      setCoupon(newCoupon);
      onCouponIdChanged(newCoupon.id);
    } else {
      onCouponIdChanged('');
    }
  };

  const fetchPrice = React.useCallback(() => {
    const getPrice = () =>
      subscriptionType === 'individual'
        ? pricingService.getIndividualUserPrice()
        : pricingService.getClinicPrice(clinicSize);

    setLoadingPrice(true);

    getPrice().then((price) => {
      if (!price) {
        throw Error('Unexpected error fetching price');
      }
      setPrice(price);
      setLoadingPrice(false);
      onPriceIdChanged(price.id);
    });
  }, [clinicSize, subscriptionType, onPriceIdChanged]);

  React.useEffect(() => {
    fetchPrice();
  }, [clinicSize, subscriptionType, fetchPrice]);

  return (
    <>
      <section>
        <label className="Horizontal">
          <input
            data-cy="individualSubscriptionRadio"
            type="radio"
            name="subscriptionType"
            value="individual"
            onChange={subscriptionTypeChange}
            checked={subscriptionType === 'individual'}
          />
          Individual practitioner (Limited to 2 devices at the same time)
        </label>
        <label className="Horizontal">
          <input
            data-cy="clinicSubscriptionRadio"
            type="radio"
            name="subscriptionType"
            value="clinic"
            onChange={subscriptionTypeChange}
            checked={subscriptionType === 'clinic'}
          />
          Clinic
        </label>

        {subscriptionType === 'clinic' ? (
          <label className="ClinicSize">
            Clinic size
            <select
              data-cy="clinicSizeSelect"
              value={clinicSize}
              onChange={(e) => setClinicSize(parseInt(e.target.value))}
              disabled={loadingPrice === true}
            >
              <option key={1} value={1}>
                Clinic open Mon-Fri (daytime)
              </option>
              <option key={2} value={2}>
                Clinic open Mon-Sat or Mon-Sun (daytime)
              </option>
              <option key={3} value={3}>
                Clinic/Hospital open 24/7
              </option>
            </select>
          </label>
        ) : (
          ''
        )}

        <CouponCode onCouponChanged={onCouponChanged} coupon={coupon} />

        <div className="SubscriptionTotal">
          <SubscriptionPrice {...{ coupon, price, loadingPrice, showFreeTrialText }} />
        </div>
      </section>
    </>
  );
};
