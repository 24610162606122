export function login(email, password) {
  return fetch('/api/auth', {
    method: 'post',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw response.status;
    }
  });
}

export function getUser() {
  return fetch('/api/auth', {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw response.status;
    }
  });
}

export function logout() {
  return fetch('/api/auth', {
    method: 'delete',
    credentials: 'include',
  }).then((response) => {
    if (response.status === 200) {
      return;
    } else {
      throw response.status;
    }
  });
}
