import * as React from 'react';
import { FC, useState } from 'react';
import './AccountPage.css';
import { DefaultRegionSubscriptionTypeSelector } from '../auth/SignUpForm/DefaultRegionSubscriptionTypeSelector';
import { UsRegionSubscriptionTypeSelector } from '../auth/SignUpForm/UsRegionSubscriptionTypeSelector';
import { Config } from '../../types';
import './SubscriptionRenewalForm.css';
import { createSubscription, getSubscriptions } from '../../services/account';
import { Loader } from '../Components/Loader';
import { Stripe } from '@stripe/stripe-js';
import { ConfirmButton } from '../Components/ConfirmButton';

interface Props {
  config: Config;
  accountId: string;
  stripePromise: PromiseLike<Stripe | null>;
}

const genericErrorMessage =
  'Unexpected error creating subscription. Try again and if the problem persist, contact support.';

export const SubscriptionRenewalForm: FC<Props> = ({ config, accountId, stripePromise }) => {
  const [couponId, setCouponId] = useState('');
  const [priceId, setPriceId] = useState('');
  const [processing, setProcessing] = useState(false);
  const [createError, setCreateError] = useState('');

  const doCreateSubscription = async () => {
    setCreateError('');
    setProcessing(true);

    const stripe = await stripePromise;
    const response = await createSubscription(accountId, { stripePriceId: priceId, stripeCouponId: couponId });

    if (!response) {
      setProcessing(false);
      setCreateError(genericErrorMessage);
      return;
    }

    const waitWebhookToCreateSubscriptionToDatabase = async () => {
      let pollCount = 0;
      const pollSubscriptions = async () => {
        pollCount += 1;
        const subscriptions = await getSubscriptions(accountId);
        const hasActiveSubscription = subscriptions && subscriptions.length > 0;
        if (hasActiveSubscription) {
          setProcessing(false);
          window.location.reload();
        } else if (pollCount > 10) {
          setProcessing(false);
          setCreateError(genericErrorMessage);
        } else {
          setTimeout(pollSubscriptions, 1000);
        }
      };
      setTimeout(pollSubscriptions, 1000);
    };

    const { paymentIntent } = response;
    if (paymentIntent.status === 'requires_action' && paymentIntent.client_secret) {
      const confirmResponse = await stripe?.confirmCardPayment(paymentIntent.client_secret);
      if (confirmResponse?.error) {
        setProcessing(false);
        setCreateError(confirmResponse.error.message || genericErrorMessage);
      } else {
        await waitWebhookToCreateSubscriptionToDatabase();
      }
    } else {
      await waitWebhookToCreateSubscriptionToDatabase();
    }
  };

  return (
    <div className="SubscriptionRenewalForm">
      <hr />
      <h2>Renew subscription</h2>
      {config.region === 'default' && (
        <DefaultRegionSubscriptionTypeSelector
          onCouponIdChanged={setCouponId}
          onPriceIdChanged={setPriceId}
          showFreeTrialText={false}
        />
      )}
      {config.region === 'us' && (
        <UsRegionSubscriptionTypeSelector
          onCouponIdChanged={setCouponId}
          onPriceIdChanged={setPriceId}
          showFreeTrialText={false}
        />
      )}
      <ConfirmButton
        text="Renew subscription"
        confirmText="Click to confirm"
        className="RenewButton"
        onConfirm={doCreateSubscription}
        disabled={processing}
      />
      {processing && <Loader />}
      {createError && <div className="Error">{createError}</div>}
    </div>
  );
};
