import * as React from 'karet';
import './CheckToxinCalculatorAccess.css';

export const CheckToxinCalculatorAccess = ({ user, children }) => {
  const hasAccessToToxinCalculator = user.get().hasAccessToToxinCalculator;

  return (
    <>
      {hasAccessToToxinCalculator && children}
      {!hasAccessToToxinCalculator && (
        <div className="NoActiveSubscriptionMessageContainer">
          <div className="NoActiveSubscriptionMessage">
            No active subscription found for your account. If you want to continue your subscription, check your credit
            card validity and renew your subscription in <a href="/account">account</a> page.
          </div>
        </div>
      )}
    </>
  );
};
