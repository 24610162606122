import path from 'object-path';
import { flatten, isEqual } from 'underscore';

export function isToxinSelectable(toxin) {
  return toxin.description && toxin.description !== '';
}

export function hasSubstanceClinicalSignCategories(substance) {
  return path.get(substance, ['additionalInfo', 'clinicalSigns', 'categories'], []).length > 0;
}

export function findRootToxin(substances, name) {
  for (const index in substances) {
    if (substances[index].name === name) {
      return substances[index];
    }
    const matching = findRootToxin(substances[index].children, name);
    if (matching !== undefined) {
      return substances[index];
    }
  }
  return undefined;
}

export function flattenSubstances(substances) {
  let result = [];
  for (const key in substances) {
    const substance = substances[key];
    result = result.concat(flattenSubstances(substance.children));
    result.push(Object.assign({}, substance, { children: [] }));
  }
  return flatten(result);
}

export function isInherited(value, inheritedValue) {
  if (value === undefined || value === null) {
    return true;
  }
  return isEqual(value, inheritedValue);
}
