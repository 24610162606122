import * as R from 'ramda';
import { RegionId } from '../../../../../backend/config/types';
import { vatIdValidation } from '../../../utils/vatIdValidation';
import { SignUpFormState } from './types';
import { CountrySelection } from '../../Billing/CountrySelect';

export function isFormValid(region: RegionId, state: SignUpFormState) {
  return (
    state.firstName &&
    state.lastName &&
    state.email &&
    state.email.includes('@') &&
    state.emailVerified === true &&
    state.phone &&
    state.cardholderName &&
    state.streetAddress &&
    state.postalCode &&
    state.city &&
    state.companyName &&
    state.cardNumberComplete &&
    state.cardExpiryComplete &&
    state.cardCvcComplete &&
    state.termsAccepted &&
    (region === 'default' ? state.country?.value && state.country.value.length > 0 : true) &&
    (region === 'default' ? state.vatId : true) &&
    (region === 'default' ? state.assuredAllUsersAreVets : true) &&
    (region === 'us' ? state.state?.value && state.state.value.length > 0 : true)
  );
}

export function missingFieldNames(region: RegionId, state: SignUpFormState) {
  const missingFields = [];

  R.isEmpty(state.firstName) && missingFields.push(["'First name'"]);
  R.isEmpty(state.lastName) && missingFields.push(["'Last name'"]);
  (R.isEmpty(state.email) || !state.email.includes('@')) && missingFields.push(["'Email address'"]);
  !R.isEmpty(state.email) &&
    state.email.includes('@') &&
    state.emailVerified === false &&
    missingFields.push(["'Emails do not match'"]);
  R.isEmpty(state.phone) && missingFields.push(["'Phone'"]);
  R.isEmpty(state.companyName) && missingFields.push(["'Company name'"]);
  if (region !== 'us') {
    R.isEmpty(state.country?.value) && missingFields.push(["'Country'"]);
    R.isEmpty(state.vatId) && missingFields.push(["'VAT number'"]);
  }
  R.isEmpty(state.streetAddress) && missingFields.push(["'Street address'"]);
  R.isEmpty(state.postalCode) && missingFields.push(["'Postal code'"]);
  R.isEmpty(state.city) && missingFields.push(["'City'"]);
  if (region === 'us') {
    R.isEmpty(state.state?.value) && missingFields.push(["'State'"]);
  }
  R.isEmpty(state.cardholderName) && missingFields.push(["'Cardholder name'"]);
  R.equals(false, state.cardNumberComplete) && missingFields.push(["'Card number'"]);
  R.equals(false, state.cardExpiryComplete) && missingFields.push(["'Expiration date'"]);
  R.equals(false, state.cardCvcComplete) && missingFields.push(["'CVC'"]);
  R.equals(false, state.termsAccepted) && missingFields.push(["'Accept terms'"]);
  if (region !== 'us') {
    state.assuredAllUsersAreVets === false && missingFields.push(["'Assure Vets'"]);
  }

  return missingFields;
}

export function isVatIdValid(country: CountrySelection | null, vatId: string | null) {
  if (country && vatId && country.value in vatIdValidation) {
    const validator = vatIdValidation[country.value];
    return validator.regex.test(vatId);
  }
  return !R.isEmpty(vatId);
}
