import { partial } from 'underscore';
import { handleJsonResponse } from './common';

function getConditions() {
  return fetch('/api/conditions', {
    method: 'GET',
    credentials: 'include',
  }).then(handleJsonResponse);
}

function upsertCondition(method, name, description, clinicalSigns) {
  const body = { name, description, clinicalSigns };
  return fetch('/api/conditions', {
    method: method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(handleJsonResponse);
}

const createCondition = partial(upsertCondition, 'POST');
const updateCondition = partial(upsertCondition, 'PUT');

export const conditionService = {
  getConditions,
  createCondition,
  updateCondition,
};
