import * as React from 'karet';
import * as U from 'karet.util';
import { TiTick, TiTimes } from 'react-icons/ti';
import { CheckList as ChecklistMeta } from '../SubstanceEditor/activityeditor/ActivityMeta';
import './Checklist.css';

const renderDos = (dos) => (
  <div>
    {U.mapElems(
      (description, index) => (
        <div className="Item" key={`dos${index}`}>
          <TiTick className="Do" />
          {description}
        </div>
      ),
      dos,
    )}
  </div>
);

const renderNotes = (notes) => (
  <div className="Notes">
    {U.mapElems(
      (description, index) => (
        <div className="Item" key={`note${index}`}>
          {description}
        </div>
      ),
      notes,
    )}
  </div>
);

export const Checklist = ({ list }) => {
  const dos = ChecklistMeta.dos(list);
  const donts = ChecklistMeta.donts(list);
  const notes = ChecklistMeta.notes(list);
  const showDos = U.mapValue((dos) => dos && dos.length, dos);
  const showNotes = U.mapValue((notes) => notes && notes.length, notes);
  return (
    <div className="Checklist">
      {U.ifElse(showDos, renderDos(dos), '')}
      <div>
        {U.mapElems(
          (description, index) => (
            <div className="Item" key={`donts${index}`}>
              <TiTimes className="Donts" />
              {description}
            </div>
          ),
          donts,
        )}
      </div>
      {U.ifElse(showNotes, renderNotes(notes), '')}
    </div>
  );
};
